import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import './App.less';
import { msalConfig } from './authConfig';
import { ROUTES } from './common/constants';
import ProtectedRoutes from './components/ProtectedRoutes';
import PublicRoutes from './components/PublicRoutes';
import UserDetailsProvider from './context/UserDetailsProvider';
import Error404 from './Error404';
import EmailSent from './modules/auth/forgot-password/EmailSent';
import ForgotPassword from './modules/auth/forgot-password/ForgotPassword';
import ResetPassword from './modules/auth/forgot-password/ResetPassword';
import Login from './modules/auth/login/Login';
import Logout from './modules/auth/logout/Logout';
import Signup from './modules/auth/signup/Signup';
import Index from './modules/StaticPages/Index';
import PrivacyPolicy from './modules/StaticPages/PrivacyPolicy';
import TermsConditions from './modules/StaticPages/TermsConditions';
import NavigateSetter from './services/NavigateSetter';

const MainRoute = () => {
  const msalInstance = new PublicClientApplication(msalConfig);
  return (
    <BrowserRouter>
      <NavigateSetter />
      <UserDetailsProvider>
        <MsalProvider instance={msalInstance}>
          <Routes>
            <Route
              path={ROUTES?.LOGIN}
              element={
                <PublicRoutes>
                  <Login />
                </PublicRoutes>
              }
            />
            <Route
              path={ROUTES?.SIGNUP}
              element={
                <PublicRoutes>
                  <Signup />
                </PublicRoutes>
              }
            >
              <Route
                path=":invited_id"
                element={
                  <PublicRoutes>
                    <Signup />
                  </PublicRoutes>
                }
              />
            </Route>
            <Route
              path={ROUTES?.LOGOUT}
              element={
                <ProtectedRoutes>
                  <Logout />
                </ProtectedRoutes>
              }
            />
            <Route
              path={ROUTES?.FORGOT_PASSWORD}
              element={
                <PublicRoutes>
                  <ForgotPassword />
                </PublicRoutes>
              }
            />
            <Route
              path={ROUTES?.RESET_PASSWORD}
              element={
                <PublicRoutes>
                  <ResetPassword />
                </PublicRoutes>
              }
            />
            <Route
              path={ROUTES?.EMAIL_SENT}
              element={
                <PublicRoutes>
                  <EmailSent />
                </PublicRoutes>
              }
            />
            <Route element={<Index />}>
              <Route
                path={ROUTES?.TERMS_AND_CONDITION}
                element={
                  <PublicRoutes>
                    <TermsConditions />
                  </PublicRoutes>
                }
              />
              <Route
                path={ROUTES?.PRIVACY_POLICY}
                element={
                  <PublicRoutes>
                    <PrivacyPolicy />
                  </PublicRoutes>
                }
              />
            </Route>
            <Route
              path={`${ROUTES.MAIN}*`}
              element={
                <ProtectedRoutes>
                  <App />
                </ProtectedRoutes>
              }
            />
            <Route path="*" element={<Error404 />} />
          </Routes>
        </MsalProvider>
      </UserDetailsProvider>
    </BrowserRouter>
  );
};

export default MainRoute;
