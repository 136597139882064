import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Form, notification, Row } from 'antd';
import React from 'react';
import { CloseIcon } from '../../../assets';
import AntInput from '../../AntInput';
import AntModal from '../../AntModal';

const DeleteCompanyModal = ({ visible, onSub, companyName, onCancel }) => {
  const [form] = Form?.useForm();

  const onFinish = (values) => {
    if (values?.delete_company === `Delete ${companyName}`) {
      onSub();
    } else {
      notification?.error?.({
        message: 'Error',
        description: 'Please Enter Correct Text!'
      });
    }
  };

  return (
    <AntModal
      width={500}
      title={
        <>
          <InfoCircleOutlined />
          <span className="delete-title-text">
            Delete {companyName} Company
          </span>
        </>
      }
      className="delete-company-modal"
      visible={visible}
      onOk={form?.submit}
      okText="Yes, delete"
      onCancel={onCancel}
      footer="default"
      closeIcon={<img src={CloseIcon} alt="close" />}
      destroyOnClose
      content={
        <Form
          form={form}
          name="basic"
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          requiredMark={false}
          preserve={false}
        >
          <Row>
            <Col span={24}>
              <Form.Item>
                <div className="delete-modal-text">
                  <p>
                    <strong>
                      {`Are you sure you want to delete this ${companyName} Company?`}
                    </strong>
                  </p>
                  <p>
                    All the
                    <strong>{` Members, Departments, Objectives `}</strong>
                    and
                    <strong>{` Key-Results `}</strong>
                    of the company will delete!
                  </p>
                  <p>
                    Please Type
                    <strong>{` "Delete ${companyName}" `}</strong>
                    below to delete!
                  </p>
                </div>
              </Form.Item>
              <AntInput
                placeholder={`Enter "Delete ${companyName}"`}
                name="delete_company"
                rules={[
                  {
                    required: true,
                    message: 'Please input company name!'
                  }
                ]}
              />
            </Col>
          </Row>
        </Form>
      }
    />
  );
};

export default DeleteCompanyModal;
