import { Form, notification, Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { CloseIcon } from '../../../assets';
import { UserDetailsContext } from '../../../context/UserDetailsProvider';
import api from '../../../services/api';
import AntAvatar from '../../AntAvatar';
import AntModal from '../../AntModal';

const AddMembersInDepartment = ({
  visible,
  onCancel,
  departmentId,
  departmentName,
  onSubmit
}) => {
  const [form] = Form?.useForm();
  const [users, setUsers] = useState([]);
  const { state } = useContext(UserDetailsContext);
  const user = JSON?.parse(state?.user);

  const onFinish = (values) => {
    const data = {
      department_id: departmentId,
      members: values?.members?.toString()
    };
    api?.post('add-members', data).then(function (response) {
      notification?.success({
        message: 'Success',
        description: response?.message
      });
      onSubmit();
    });
  };

  useEffect(() => {
    /* Get all users */
    api?.get('company-members').then(function (response) {
      const getUsers = [];
      response?.data?.data?.forEach((r) => {
        if (r?.name) {
          getUsers?.push({
            name: r?.name,
            value: r?.id,
            email: r?.email,
            invited_name: r?.invited_by?.email,
            is_invited: r?.is_invited,
            image: r?.image
          });
        }
      });
      setUsers(getUsers);
    });
  }, []);

  return (
    <>
      <AntModal
        title={`Add Members in ${departmentName}`}
        visible={visible}
        onOk={form?.submit}
        okText="Add"
        onCancel={onCancel}
        destroyOnClose
        footer="default"
        closeIcon={<img src={CloseIcon} alt="close" />}
        content={
          <Form
            form={form}
            name="department_form"
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            requiredMark={false}
            preserve={false}
          >
            <Form.Item
              label="Add members"
              name="members"
              className="department-member"
              rules={[
                {
                  required: true,
                  message: 'Please select members!'
                }
              ]}
            >
              <Select
                mode="multiple"
                size="large"
                placeholder="Add members"
                optionLabelProp="label"
              >
                {users?.map((option) => {
                  return (
                    <Select.Option
                      disabled={user?.id === option?.value}
                      key={option?.name}
                      value={option?.value}
                      label={option?.name}
                    >
                      <div className="department-member-add">
                        <AntAvatar
                          imgSrc={option?.image}
                          userId={option?.value}
                          firstChar={
                            option?.name
                              ? option?.name?.charAt(0)
                              : option?.email?.charAt(0)
                          }
                        />
                        <div className="ant-list-item-meta-content">
                          <h4 className="ant-list-item-meta-title">
                            {option?.is_invited === 1 ? (
                              <div className="invited_label">Invited</div>
                            ) : (
                              option?.name
                            )}
                          </h4>
                          <div className="ant-list-item-meta-description">
                            {option?.email}
                          </div>
                        </div>
                      </div>
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Form>
        }
      />
    </>
  );
};

export default AddMembersInDepartment;
