import { includes, isEmpty } from 'lodash';
import { useContext } from 'react';
import { UserDetailsContext } from '../context/UserDetailsProvider';
import { REGEX } from './constants';

export const formValidatorRules = {
  required: {
    required: true,
    message: 'Required'
  },
  email: () => ({
    validator(rule, value) {
      if (!value || isEmpty(value)) {
        return Promise?.resolve();
      }
      if (!REGEX?.EMAIL?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('The input is not valid e-mail!');
      }
      return Promise?.resolve();
    }
  }),
  multiEmail: () => ({
    validator(rule, value) {
      const temp = [];
      // eslint-disable-next-line array-callback-return
      value?.map((item) => {
        temp?.push(!REGEX?.EMAIL?.test(item));
      });
      if (includes(temp, true)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('The input is not valid e-mail!');
      }
      if (!value || isEmpty(value)) {
        return Promise?.resolve();
      }
      return Promise?.resolve();
    }
  }),
  password: () => ({
    validator(rule, value) {
      if (!value || isEmpty(value)) {
        return Promise?.resolve();
      }
      if (!REGEX?.PASSWORD?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject(
          'Please enter password that includes one uppercase letter, one lowercase letter, one number and one special character.'
        );
      }
      return Promise?.resolve();
    }
  }),
  onlyAlphabets: () => ({
    validator(rule, value) {
      if (!value || isEmpty(value)) {
        return Promise?.resolve();
      }
      if (!REGEX?.ALPHABETS?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('The input is not valid name!');
      }
      return Promise?.resolve();
    }
  }),
  atleastOneCheckbox: () => ({
    validator(rule, value) {
      if (!value || isEmpty(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Please select atleast one checkbox');
      }
      if (value) {
        return Promise?.resolve();
      }
      return Promise?.resolve();
    }
  }),
  onlyNumber: () => ({
    validator(rule, value) {
      if (value && !REGEX?.NUMBERS?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('The input is not valid number!');
      }
      if (value > 100) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Please input number between 0 to 100');
      }
      return Promise?.resolve();
    }
  }),
  alphabetsNumberSpace: () => ({
    validator(rule, value) {
      if (!value || isEmpty(value)) {
        return Promise?.resolve();
      }
      if (!REGEX?.ALPHABETS_NUMBERS_SPACE?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('The input is not valid name!');
      }
      return Promise?.resolve();
    }
  }),
  alphabetsDotSpace: () => ({
    validator(rule, value) {
      if (!value || isEmpty(value)) {
        return Promise?.resolve();
      }
      if (!REGEX?.ALPHABETS_DOT_SPACE?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('The input is not valid name!');
      }
      return Promise?.resolve();
    }
  })
};

export const HasAccess = ({ roles = [] }) => {
  const { state } = useContext(UserDetailsContext);
  const user = JSON?.parse(state?.user);

  if (roles?.includes(user?.role)) {
    return true;
  }
  return false;
};
