import { Badge, Col, Form, notification, Row, Tag, TreeSelect } from 'antd';
import React, { useEffect, useState } from 'react';
import { CloseIcon } from '../../../assets';
import { formValidatorRules } from '../../../common/utils';
import api from '../../../services/api';
import AntModal from '../../AntModal';
import AntSelect from '../../AntSelect';

const { required, multiEmail } = formValidatorRules;
const InviteModal = ({ visible, onOk, onCancel }) => {
  const [form] = Form?.useForm();
  const [departments, setDepartments] = useState([]);

  const onFinish = (values) => {
    const arr = [];
    values?.email?.forEach(function (email) {
      arr?.push({ email });
    });
    const data = {
      ...values,
      flag: 'with_department',
      invite: arr
    };
    api?.post('invite-members', data).then(function (response) {
      notification?.success({
        message: 'Success',
        description: response?.message
      });
      onOk();
    });
  };

  useEffect(() => {
    /* Get departments data */
    api
      ?.get('departments', {
        params: { is_from_setting_page: true }
      })
      .then(function (response) {
        const data = [];
        response?.data?.forEach((r) => {
          data?.push({
            title: r?.name ?? r?.parent_department?.name,
            value: r?.id,
            key: r?.id
          });
        });
        setDepartments(data);
      });
  }, []);

  const tagRender = (props) => {
    const { label } = props;
    return (
      <Tag className="department-select-tag">
        <Badge
          status={
            label === 'Developer' || label === 'Marketing' ? 'error' : 'success'
          }
          text={label}
        />
      </Tag>
    );
  };

  return (
    <AntModal
      title="Invite new members"
      visible={visible}
      onOk={form?.submit}
      okText="Invite"
      onCancel={onCancel}
      footer="default"
      closeIcon={<img src={CloseIcon} alt="close" />}
      destroyOnClose
      content={
        <Form
          form={form}
          name="basic"
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          requiredMark={false}
          preserve={false}
        >
          <Row>
            <Col span={16}>
              <div id="invite_modal_emails">
                <AntSelect
                  className="invite_modal_emails"
                  options={[]}
                  mode="tags"
                  label="Email id(s)"
                  name="email"
                  placeholder="Enter Email id"
                  rules={[required, multiEmail]}
                />
              </div>
            </Col>
            <Col span={7} offset={1}>
              <AntSelect
                options={[
                  { name: 'Admin', value: 'ADMIN' },
                  { name: 'Manager', value: 'MANAGER' },
                  { name: 'User', value: 'USER' }
                ]}
                label="Select Role"
                name="role"
                placeholder="Eg. Software engineer"
                rules={[
                  {
                    required: true,
                    message: 'Please select role!'
                  }
                ]}
              />
            </Col>
          </Row>
          <Form.Item
            label="Add to department(s)"
            name="department_id"
            rules={[
              {
                required: true,
                message: 'Please select department!'
              }
            ]}
          >
            <TreeSelect
              size="large"
              allowClear
              tagRender={tagRender}
              treeData={departments}
              treeCheckable="true"
              placeholder="Select Department"
            />
          </Form.Item>
        </Form>
      }
    />
  );
};

export default InviteModal;
