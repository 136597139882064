import { useMsal } from '@azure/msal-react';
import {
  Col,
  Divider,
  Form,
  Input,
  notification,
  Row,
  Space,
  Typography
} from 'antd';
import React, { useContext, useState } from 'react';
import { Link as RouteLink, useNavigate } from 'react-router-dom';
import {
  GoogleIcon,
  Logo,
  OutlookIcon,
  RightArrow,
  SidebarLogo
} from '../../../assets';
import { loginRequest } from '../../../authConfig';
import { ROUTES } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import AntButton from '../../../components/AntButton';
import AntCard from '../../../components/AntCard';
import AntInput from '../../../components/AntInput';
import { UserDetailsContext } from '../../../context/UserDetailsProvider';
import Strings from '../../../design/Strings';
import api from '../../../services/api';
import { auth, provider } from '../../../services/firebase';
import { Content, SubText } from '../AntStyled';

const { email } = formValidatorRules;
const Login = () => {
  const navigate = useNavigate();
  const { dispatch } = useContext(UserDetailsContext);
  const [loading, setLoading] = useState(false);

  const { instance } = useMsal();

  const onFinish = (values, url = 'login') => {
    api
      ?.post(url ?? 'login', values)
      .then(function (response) {
        notification?.success({
          message: 'Success',
          description: response?.message
        });
        dispatch({
          type: 'LOGIN',
          payload: response?.data
        });
        navigate(ROUTES?.MAIN);
      })
      .catch(function () {});
  };

  const handleLogin = async () => {
    instance
      ?.handleRedirectPromise()
      ?.then(
        instance
          ?.loginPopup(loginRequest)
          ?.then((res) => {
            onFinish(
              {
                name: res?.account?.name,
                email: res?.account?.username,
                type: 'OUTLOOK',
                token: res?.uniqueId
              },
              'social-login'
            );
          })
          ?.catch((error) => {
            return error;
          })
      )
      ?.catch((error) => {
        return error;
      });
  };

  const SignupWithGoogle = () => {
    setLoading(true);
    auth?.signInWithPopup(provider).then((res) => {
      const user = res?.user;
      onFinish(
        {
          name: user?.displayName,
          email: user?.email,
          type: 'GOOGLE',
          token: user?.uid
        },
        'social-login'
      );
      setLoading(false);
    });
  };

  return (
    <Content>
      <div className="logo-wrapper">
        <RouteLink to={ROUTES?.LOGIN}>
          <img className="logo" src={Logo} alt="" />
        </RouteLink>
      </div>
      <Row>
        <Col className="img-col">
          <img className="sidebar-img" src={SidebarLogo} alt="sidebar-logo" />
        </Col>
        <Col flex="auto" className="form-col">
          <div className="wrapper">
            <Typography.Title className="title heading mb-0">
              {Strings?.auth?.login}
            </Typography.Title>
            <SubText>{Strings?.auth?.loginDescription}</SubText>
            <AntCard bordered padding="16px">
              <Form
                name="basic"
                onFinish={onFinish}
                autoComplete="off"
                layout="vertical"
              >
                <AntInput
                  name="email"
                  label="Email"
                  placeholder="Email"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your email.'
                    },
                    email
                  ]}
                />

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password.'
                    }
                  ]}
                  className="mb-0"
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>

                <RouteLink className="forgot-password" to="/forgot-password">
                  {Strings?.auth?.forgotPassword}
                </RouteLink>

                <AntButton
                  type="primary"
                  htmlType="submit"
                  block
                  size="large"
                  loading={loading}
                >
                  {Strings?.auth?.login}
                  <img src={RightArrow} alt="" />
                </AntButton>
              </Form>
            </AntCard>
            <Divider plain>OR</Divider>
            <Space direction="vertical" size="small" className="text-center">
              <AntButton
                size="large"
                block
                onClick={SignupWithGoogle}
                className="icon-btn"
              >
                <img src={GoogleIcon} alt="" /> &nbsp;
                {Strings?.auth?.google}
              </AntButton>
              <AntButton
                className="icon-btn"
                size="large"
                block
                onClick={handleLogin}
              >
                <img src={OutlookIcon} alt="" /> &nbsp;
                {Strings?.auth?.outlook}
              </AntButton>
              <SubText>
                {Strings?.auth?.account}
                <RouteLink to={ROUTES?.SIGNUP}>&nbsp;Sign up</RouteLink>
              </SubText>
            </Space>
          </div>
        </Col>
      </Row>
    </Content>
  );
};

export default Login;
