import { Button } from 'antd';
import React from 'react';
import styled from 'styled-components';

const CustomButton = styled(Button)`
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  &.ant-btn-link {
    &.ant-btn {
      padding: 0px;
    }
  }
  &.ant-btn-lg {
    padding: 8px 16px;
  }
  &.ant-btn {
    padding: 8px 16px;
  }
`;

const AntButton = (props) => {
  return (
    <CustomButton block size="large" {...props}>
      {
        // eslint-disable-next-line react/destructuring-assignment
        props?.children
      }
    </CustomButton>
  );
};

export default AntButton;
