import { useMsal } from '@azure/msal-react';
import {
  Avatar,
  Col,
  Divider,
  Form,
  Input,
  notification,
  Row,
  Space,
  Typography
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import {
  Link,
  Link as RouteLink,
  useNavigate,
  useParams
} from 'react-router-dom';
import {
  GoogleIcon,
  Logo,
  OutlookIcon,
  RightArrow,
  SidebarLogo
} from '../../../assets';
import { loginRequest } from '../../../authConfig';
import { ROUTES } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import AntAlert from '../../../components/AntAlert';
import AntButton from '../../../components/AntButton';
import AntCard from '../../../components/AntCard';
import AntCheckbox from '../../../components/AntCheckbox';
import AntInput from '../../../components/AntInput';
import { UserDetailsContext } from '../../../context/UserDetailsProvider';
import Strings from '../../../design/Strings';
import api from '../../../services/api';
import { auth, provider } from '../../../services/firebase';
import { BodyStyles, Content } from '../AntStyled';

const { Text } = Typography;

const { onlyAlphabets, email, alphabetsDotSpace } = formValidatorRules;
const Signup = () => {
  const { dispatch } = useContext(UserDetailsContext);
  const navigate = useNavigate();
  const { invited_id: invitedId } = useParams();
  const invitedUserId = invitedId?.substring(0, 36);
  const verifyInvite = invitedId?.substring(37);
  const [passwordCheck, setPasswordCheck] = useState(false);
  const [charNumberValid, setCharNumberValid] = useState(false);
  const [specialCharValid, setSpecialCharValid] = useState(false);
  const [lowercaseValid, setLowercaseValid] = useState(false);
  const [uppercaseValid, setUppercaseValid] = useState(false);
  const [numberValid, setNumberValid] = useState(false);
  const [company, setCompany] = useState();
  const [isEmailExists, setIsEmailExists] = useState(false);
  const [loading, setLoading] = useState(false);
  const { instance } = useMsal();
  const [form] = Form?.useForm();
  Form.useWatch([], form);

  const onFinish = (values, url = 'register') => {
    setLoading(true);
    const data = {
      ...values,
      ...(invitedUserId && { invited_id: invitedUserId }),
      ...(verifyInvite && { verify_invite: verifyInvite }),
      ...(company?.email && { email: company?.email })
    };
    api
      ?.post(url ?? 'register', data)
      .then(function (response) {
        notification?.success({
          message: 'Success',
          description: response?.message
        });
        dispatch({
          type: 'LOGIN',
          payload: response?.data
        });
        if (invitedId) {
          navigate(ROUTES?.MAIN);
        } else {
          // eslint-disable-next-line no-undef
          localStorage?.setItem('isSignUp', true);
          navigate(`${ROUTES?.ONBOARDING}/${ROUTES?.CREATE_COMPANY}`);
        }
      })
      .catch(function (error) {
        if (
          error?.response?.data?.message ===
          'The email address you have entered is already registered'
        ) {
          setIsEmailExists(true);
        }
        setLoading(false);
      });
  };

  const handleLogin = async () => {
    instance
      ?.handleRedirectPromise()
      ?.then(
        instance
          ?.loginPopup(loginRequest)
          ?.then((res) => {
            onFinish({
              name: res?.account?.name,
              email: res?.account?.username,
              type: 'OUTLOOK',
              token: res?.uniqueId
            });
          })
          ?.catch((error) => {
            return error;
          })
      )
      ?.catch((error) => {
        return error;
      });
  };
  // Check the length of the input
  const checkPasswordLength = (password) => {
    setCharNumberValid(password?.length >= 8 && password?.length <= 16);
  };

  // Check for special characters
  const checkSpecialCharacters = (password) => {
    // eslint-disable-next-line no-useless-escape
    const pattern = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
    setSpecialCharValid(pattern?.test(password));
  };

  // Check for an lowercase character
  const checkLowercase = (password) => {
    const pattern = /[a-z]/;
    setLowercaseValid(pattern?.test(password));
  };

  // Check for an uppercase character
  const checkUppercase = (password) => {
    const pattern = /[A-Z]/;
    setUppercaseValid(pattern?.test(password));
  };

  // Check for a number
  const checkNumber = (password) => {
    const pattern = /[0-9]/;
    setNumberValid(pattern?.test(password));
  };

  const handlePasswordChange = (event) => {
    setPasswordCheck(true);
    checkPasswordLength(event?.target?.value);
    checkSpecialCharacters(event?.target?.value);
    checkLowercase(event?.target?.value);
    checkUppercase(event?.target?.value);
    checkNumber(event?.target?.value);
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const sessionStorage = window?.sessionStorage;
    sessionStorage?.clear();
    if (invitedId) {
      const fetchCompanyDetail = () => {
        api?.get(`invitation-detail/${invitedId}`).then(function (response) {
          setCompany(response?.data);
        });
      };
      fetchCompanyDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const SignupWithGoogle = () => {
    auth?.signInWithPopup?.(provider)?.then((res) => {
      const user = res?.user;
      onFinish({
        name: user?.displayName,
        email: user?.email,
        type: 'GOOGLE',
        token: user?.uid
      });
    });
  };

  return (
    <Content>
      <BodyStyles />
      <div className="logo-wrapper">
        <RouteLink to="/login">
          <img className="logo" src={Logo} alt="" />
        </RouteLink>
      </div>
      <Row>
        <Col className="img-col">
          <img className="sidebar-img" src={SidebarLogo} alt="sidebar-logo" />
        </Col>
        <Col flex="auto" className="form-col-scroll">
          <div className="wrapper">
            {invitedId ? (
              <>
                <Typography.Title className="title heading mb-0">
                  {Strings?.auth?.welcome}
                </Typography.Title>
                <Text className="sub-title">
                  okrboard provides a structured way to plan,
                  <br />
                  execute and track your business objectives
                </Text>
                <Text className="sub-title mt-16 mb-8 block">
                  {Strings?.auth?.join}
                </Text>
                <div>
                  <Avatar
                    src={company?.logo}
                    shape="square"
                    size="large"
                    className="radius-8"
                  />
                  <Text className="company-name">{company?.name}</Text>
                </div>
              </>
            ) : (
              <>
                <Typography.Title className="title heading mb-0">
                  {Strings?.auth?.signUp}
                </Typography.Title>
                <Text className="sub-title">
                  {Strings?.auth?.signUpTagline}
                </Text>
              </>
            )}
            {isEmailExists === true ? (
              <AntAlert
                description={
                  <>
                    <b>
                      An account with this email id already exists. Please&nbsp;
                      <RouteLink to="/login">
                        <Text underline>Login</Text>
                      </RouteLink>
                    </b>
                  </>
                }
              />
            ) : (
              ''
            )}
            <AntCard bordered padding="16px">
              <Form
                name="basic"
                onFinish={onFinish}
                autoComplete="off"
                layout="vertical"
                form={form}
              >
                {!invitedId ? (
                  <AntInput
                    name="email"
                    label="Email"
                    placeholder="Email"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your email.'
                      },
                      email
                    ]}
                  />
                ) : (
                  ''
                )}
                <AntInput
                  label="Full name"
                  name="name"
                  maxLength="50"
                  placeholder="John Doe"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your full name.'
                    },
                    onlyAlphabets
                  ]}
                />
                <AntInput
                  id="job-title"
                  label="Job title"
                  name="job_title"
                  maxLength="100"
                  placeholder="eg. CEO"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your job title.'
                    },
                    alphabetsDotSpace
                  ]}
                />
                <Form.Item
                  label="Create a password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password.'
                    }
                  ]}
                >
                  <Input.Password
                    onChange={handlePasswordChange}
                    placeholder="Password"
                  />
                </Form.Item>
                {passwordCheck ? (
                  <div className="validation">
                    <div className="validator">
                      <AntCheckbox
                        className="mb-0"
                        checked={charNumberValid ? 'checked' : ''}
                      />
                      <p className="validation-item">8-16 characters</p>
                    </div>
                    <div className="validator">
                      <AntCheckbox
                        className="mb-0"
                        checked={specialCharValid ? 'checked' : ''}
                      />
                      <p className="validation-item">1 special character</p>
                    </div>
                    <div className="validator">
                      <AntCheckbox
                        className="mb-0"
                        checked={lowercaseValid ? 'checked' : ''}
                      />
                      <p className="validation-item">1 lowercase letter</p>
                    </div>
                    <div className="validator">
                      <AntCheckbox
                        className="mb-0"
                        checked={uppercaseValid ? 'checked' : ''}
                      />
                      <p className="validation-item">1 uppercase letter</p>
                    </div>
                    <div className="validator">
                      <AntCheckbox
                        className="mb-0"
                        checked={numberValid ? 'checked' : ''}
                      />
                      <p className="validation-item">1 number</p>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <AntCheckbox
                  className="mb-0"
                  name="remember"
                  label="Remember Me"
                  valuePropName="checked"
                />
                <Text className="signup-desc mt-8">
                  By Signing up, you agree to our&nbsp;
                  <Link to="/terms-condition">Terms</Link> and you
                  <br />
                  acknowledge having read our&nbsp;
                  <Link to="/privacy-policy">Privacy Notice</Link>
                </Text>
                <Form.Item shouldUpdate>
                  {() => (
                    <AntButton
                      type="primary"
                      htmlType="submit"
                      block
                      size="large"
                      loading={loading}
                      className="mt-16"
                      disabled={
                        form
                          ?.getFieldsError()
                          ?.filter(({ errors }) => errors?.length)?.length >
                          0 ||
                        !(
                          charNumberValid &&
                          specialCharValid &&
                          lowercaseValid &&
                          uppercaseValid &&
                          numberValid
                        )
                      }
                    >
                      {Strings?.auth?.signUp}
                      <img src={RightArrow} alt="" />
                    </AntButton>
                  )}
                </Form.Item>
              </Form>
            </AntCard>
            <Divider plain>OR</Divider>
            <Space direction="vertical" size="small" className="text-center">
              <AntButton
                size="large"
                block
                onClick={SignupWithGoogle}
                className="icon-btn"
              >
                <img src={GoogleIcon} alt="" /> &nbsp;
                {Strings?.auth?.google}
              </AntButton>
              <AntButton
                className="icon-btn"
                size="large"
                block
                onClick={handleLogin}
              >
                <img src={OutlookIcon} alt="" /> &nbsp;
                {Strings?.auth?.outlook}
              </AntButton>
              <Text className="text ">
                {Strings?.auth?.account}
                <RouteLink to="/login">&nbsp;{Strings?.auth?.login}</RouteLink>
              </Text>
            </Space>
          </div>
        </Col>
      </Row>
    </Content>
  );
};

export default Signup;
