import { Layout, Typography } from 'antd';
import styled from 'styled-components';
import Colors from '../../design/Colors';

const { Content: AntContent } = Layout;
const { Text } = Typography;

export const Content = styled(AntContent)`
  overflow: auto;
  overflow-x: hidden;
  padding: 24px 16px;
  padding: ${({ padding }) => padding || 0};
  background: ${Colors?.Background};
  height: ${({ height }) => height || 'calc(100vh - 128px)'};
  .ant-collapse-content-box {
    padding: 0px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 16px;
  }
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    margin-right: 8px;
    font-size: 22px;
  }
  .header-collapse {
    &.ant-collapse > .ant-collapse-item > .ant-collapse-header {
      padding: 12px 0px;
      display: flex;
      align-items: center;
      .ant-collapse-expand-icon {
        display: flex;
        align-items: center;
      }
    }
  }
  .big-tag {
    &.ant-tag {
      padding: 1px 6px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      border-radius: 4px;
      background-color: transparent;
      border-color: ${Colors?.Accent};
      border-radius: 4px;
      color: ${Colors?.Accent};
    }
  }

  .user-tag {
    &.ant-tag {
      border-color: ${Colors?.Stroke};
      border-radius: 100px;
      color: ${Colors?.Primary};
    }
  }
  .company-tag {
    &.ant-tag {
      display: flex;
      border-color: ${Colors?.Accent};
      border-radius: 4px;
      color: ${Colors?.Accent};
      justify-content: center;
      align-items: center;
      width: 85px;
      height: 22px;
      font-size: 12px;
    }
  }
  .department-tag {
    &.ant-tag {
      border-color: ${Colors?.Accent};
      border-radius: 4px;
      color: ${Colors?.Accent};
    }
  }
  .ant-collapse-header-text {
    width: 100%;
  }
  .text-header {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: ${Colors?.Primary};
    display: block;
  }
  .key-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: ${Colors?.Accent};
    display: block;
  }
  .text-sub {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${Colors?.Text};
    display: block;
  }
  .group-tag {
    padding: 2px 6px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    background-color: transparent;
    border: 1px solid ${Colors?.Stroke};
    border-radius: 100px;
    color: ${Colors?.Primary};
    .ant-badge-status-dot {
      width: 10px;
      height: 10px;
    }
  }
  .key-Wrapper {
    padding: 0px 24px 24px 24px;
  }
  .ant-slider-rail {
    height: 10px;
    background: #dfdfdf;
    border-radius: 20px;
  }
  .ant-slider-track {
    height: 10px;
    border-radius: 20px;
  }
  .ant-slider-step {
    height: 10px;
    border-radius: 20px;
  }
  .ant-slider-handle {
    position: absolute;
    width: 22px;
    height: 22px;
    margin-top: -6px;
    background-color: ${Colors?.WhiteColor};
    border: 1px solid ${Colors?.Stroke};
    border-radius: 50%;
  }
  .ant-slider {
    margin: 3px 16px 9px;
  }
  .title-desc {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: ${Colors?.Primary};
  }
  .desc-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${Colors?.Text};
  }
  .ant-progress-line {
    font-size: 16px;
  }
  .paragraph-text {
    max-width: 345px;
  }
  .col-1 {
    padding: 16px;
  }
  .col-2 {
    background: ${Colors?.Background};
    padding: 16px;
  }
  .ant-card {
    border-radius: 8px;
  }
  .date {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${Colors?.Text};
  }
  .btn-outline {
    &.ant-btn {
      border: 1px solid ${Colors?.Accent};
      color: ${Colors?.Accent};
    }
  }
`;

export const SubText = styled.div`
  white-space: pre-line;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: ${Colors?.Text};
  max-width: 150px;
  display: block;
  &.sub-text {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 4px;
  }
`;
export const KeyText = styled(Text)`
  white-space: pre-line;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: ${Colors?.Primary};
  max-width: 348px;
  display: block;
`;
