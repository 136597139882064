import { Col, Row, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { BackgroundPattern, Logo } from '../../../assets';
import { ROUTES } from '../../../common/constants';
import { UserDetailsContext } from '../../../context/UserDetailsProvider';
import { Content } from '../AntStyled';

const MasterOnboarding = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { state } = useContext(UserDetailsContext);
  const user = JSON?.parse(state?.user);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const isSignUp = localStorage?.getItem('isSignUp');
    if (!isSignUp && Object?.keys(user?.company)?.length !== 0) {
      navigate(ROUTES?.MAIN);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <Spin spinning={loading} size="large" className="sign-up-spinner" />
      ) : (
        <>
          <Content className="sign-up-steps">
            <div className="logo-wrapper">
              <Link to={ROUTES?.MAIN}>
                <img className="logo" src={Logo} alt="" />
              </Link>
            </div>
            <Row>
              <Col flex="auto" className="form-col">
                <Outlet />
                <img
                  className="background-pattern"
                  src={BackgroundPattern}
                  alt=""
                />
              </Col>
            </Row>
          </Content>
        </>
      )}
    </>
  );
};

export default MasterOnboarding;
