import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, notification, Row, Spin, Typography } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CloseIcon, DeleteIcon } from '../../../assets';
import { ROUTES, USERS_ROLE } from '../../../common/constants';
import { formValidatorRules, HasAccess } from '../../../common/utils';
import { UserDetailsContext } from '../../../context/UserDetailsProvider';
import api from '../../../services/api';
import AntInput from '../../AntInput';
import AntModal from '../../AntModal';
import AntSelect from '../../AntSelect';
import AntTextArea from '../../AntTextArea';
import DeleteModal from './DeleteModal';

const { Text } = Typography;
const { onlyAlphabets } = formValidatorRules;
const ObjectiveModal = ({
  visible,
  onCancel,
  objectiveId = '',
  onSub,
  isMyObjective,
  isDeleteObjectiveModal
}) => {
  const { pathname } = useLocation();
  const [form] = Form?.useForm();
  const navigate = useNavigate();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [showDes, setShowDes] = useState(false);
  const [isDepChecked, setIsDepChecked] = useState(false);
  const [addDepChecked, setAddDepChecked] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [users, setUsers] = useState([]);
  const [cycles, setCycle] = useState([]);
  const [alignObjective, setAlignObjective] = useState([]);
  const [alignObjectiveTrue, setAlignObjectiveTrue] = useState('');
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(true);
  const [IsVisible, setIsVisible] = useState(false);
  const { state } = useContext(UserDetailsContext);
  const user = JSON?.parse(state?.user);
  const objectiveNameRef = useRef(null);

  const alignTooltipContent = () => (
    <div>
      <p>
        It’ll show the relation of this goal with it’s parent goal. <br />
        <span className="link">See how it works</span>
      </p>
    </div>
  );

  useEffect(() => {
    setAlignObjectiveTrue(true);
  }, [isMyObjective]);

  const onFinish = (values) => {
    setConfirmLoading(true);
    const data = {
      ...values,
      ...(objectiveId && { id: objectiveId })
    };
    api
      ?.post('objectives', data)
      .then(function (response) {
        setIsDepChecked(false);
        setAddDepChecked(false);
        setConfirmLoading(false);
        notification?.success?.({
          message: 'Success',
          description: response?.message
        });
        onSub();
        setShowDes(false);
        if (pathname !== ROUTES?.MAIN && pathname === ROUTES?.COMPANY_SETTING) {
          navigate(ROUTES?.MAIN);
        }
      })
      .catch(function () {
        setConfirmLoading(false);
      });
  };

  const handleChange = (value) => {
    setIsDepChecked(value === 'DEPARTMENT');
    setAddDepChecked(false);
    api
      ?.get('objectives', {
        params: {
          Ismyobjective: alignObjectiveTrue
        }
      })
      .then(function (response) {
        const data = [];
        response?.data?.data?.forEach((r) => {
          if (objectiveId !== r?.id) {
            data?.push({
              name: r?.name,
              value: r?.id
            });
          }
        });
        setAlignObjective(data);
      });
  };

  const getDepartments = async () => {
    api
      ?.get('departments', {
        params: { is_from_setting_page: true }
      })
      .then(function (response) {
        const data = [];
        response?.data?.forEach((r) => {
          data?.push({
            name: r?.name ?? r?.parent_department?.name,
            value: r?.id
          });
        });
        setDepartments(data);
      });
  };

  useEffect(() => {
    if (visible === true) {
      /* Get departments data */
      getDepartments();

      /* get objective Cycle */
      api?.get('objective-cycles').then(function (response) {
        const getCycle = [];
        response?.data?.forEach((r) => {
          getCycle?.push({
            name: r?.name,
            value: r?.id
          });
        });
        setCycle(getCycle);
      });

      /* Get all users */
      api
        ?.get('company-members', { params: { per_page: 100000 } })
        .then(function (response) {
          const getUsers = [];
          response?.data?.data?.forEach((r) => {
            if (r?.name) {
              getUsers?.push({
                name: r?.name ?? r?.invited_by?.name,
                value: r?.id
              });
            }
          });
          setUsers(getUsers);
          objectiveNameRef?.current?.focus();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const getObjectives = () => {
    api?.get(`objectives/${objectiveId}`).then(function (response) {
      const obj = pick(response?.data, [
        'id',
        'name',
        'description',
        'type',
        'type_id',
        'cycle_id',
        'owner_id'
      ]);
      /* Watchers */
      obj.watchers = response?.data?.watchers?.map(function (value) {
        return value?.pivot?.user_id;
      });
      handleChange(obj?.type);
      obj.align_objectives = response?.data?.alinged_objectives?.map(function (
        value
      ) {
        return value?.id;
      });
      if (obj?.description) {
        setShowDes(true);
      }
      form?.setFieldsValue(obj);
      setIsEditLoading(false);
      objectiveNameRef?.current?.focus();
    });
  };

  useEffect(() => {
    if (visible && objectiveId) {
      setIsVisible(true);
      getObjectives();
    } else {
      setIsVisible(visible);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    if (visible === true) {
      if (user?.role === USERS_ROLE?.USER) {
        form?.setFieldsValue({ owner_id: user?.id });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, visible]);

  function pick(object, keys) {
    return keys?.reduce((obj, key) => {
      // eslint-disable-next-line no-prototype-builtins
      if (object?.hasOwnProperty(key)) {
        // eslint-disable-next-line no-param-reassign
        obj[key] = object?.[key];
      }
      return obj;
    }, {});
  }
  let objType = '';
  if (
    HasAccess({
      roles: [USERS_ROLE?.ADMIN, USERS_ROLE?.MANAGER, USERS_ROLE?.OWNER]
    })
  ) {
    objType = [
      { name: 'Company', value: 'COMPANY' },
      {
        name: 'Department',
        value: 'DEPARTMENT'
      },
      {
        name: 'Individual',
        value: 'INDIVIDUAL'
      }
    ];
  } else {
    objType = [
      {
        name: 'Individual',
        value: 'INDIVIDUAL'
      }
    ];
  }

  const objectiveDelete = () => {
    setIsDeleteVisible(true);
  };

  const handleDelete = () => {
    api?.delete(`objectives/${objectiveId}`).then(function (response) {
      notification?.success({
        message: 'Success',
        description: response?.message
      });
      isDeleteObjectiveModal();
      setIsVisible(false);
      setIsEditLoading(true);
    });
  };

  const ToggleInp = (e) => {
    if (e?.target?.value?.length > 0) {
      const names = [];
      names[0] = e?.target?.value;
      const dept = names?.[0]?.charAt(0)?.toUpperCase() + names?.[0]?.slice(1);
      const res = departments?.filter((obj) => obj?.name === dept);
      if (res?.length > 0) {
        notification?.error({
          message: 'Error',
          description: 'The Department Name already exist!'
        });
      } else {
        api?.post('departments', { names }).then(function (response) {
          notification?.success({
            message: 'Success',
            description: response?.message
          });
          getDepartments();
          setIsDepChecked(true);
          setAddDepChecked(false);
        });
      }
    }
  };

  const handleStatus = () => {
    return (
      <Button
        type="link"
        className="objective-modal-add-department"
        onClick={() => {
          setIsDepChecked(false);
          setAddDepChecked(true);
        }}
      >
        + Add a department
      </Button>
    );
  };

  return (
    <div>
      <AntModal
        className="objective-modal"
        title={objectiveId ? 'Edit Objective' : 'Create Objective'}
        confirmLoading={confirmLoading}
        visible={IsVisible}
        okText={objectiveId ? 'Update' : 'Create'}
        onOk={form?.submit}
        onCancel={() => {
          setIsVisible(false);
          onCancel();
          setIsEditLoading(true);
          setShowDes(false);
        }}
        footer={
          <div
            key="delete-modal-button-list"
            className="objective-modal-footer"
          >
            <div className="delete-objective-modal">
              {objectiveId && (
                <Button
                  key="delete-modal"
                  type="text"
                  onClick={objectiveDelete}
                >
                  <img width="18px" src={DeleteIcon} alt="icon" />
                  Delete objective
                </Button>
              )}
            </div>
            <div>
              <Button
                key="cancel-modal"
                onClick={() => {
                  setIsVisible(false);
                  onCancel();
                  setIsEditLoading(true);
                  setShowDes(false);
                }}
              >
                Cancel
              </Button>
              <Button key="submit-modal" type="primary" onClick={form?.submit}>
                {objectiveId ? 'Update' : 'Create'}
              </Button>
            </div>
          </div>
        }
        destroyOnClose
        closeIcon={<img src={CloseIcon} alt="close" />}
        content={
          <Spin direction="horizontal" spinning={objectiveId && isEditLoading}>
            <Form
              form={form}
              name="basic"
              onFinish={onFinish}
              autoComplete="off"
              layout="vertical"
              requiredMark={false}
              preserve={false}
            >
              <AntInput
                maxLength={255}
                label="Objective Name"
                placeholder="Objective Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Please input objective name!'
                  }
                ]}
                className="mb-0"
                innerRef={objectiveNameRef}
              />
              {showDes ? (
                <AntTextArea
                  maxLength={255}
                  placeholder="Description"
                  name="description"
                  className="mt-8"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                />
              ) : (
                <>
                  <Text
                    name="description"
                    onClick={() => {
                      setShowDes(!showDes);
                    }}
                    className="accent mb-24 block"
                  >
                    + Add Description
                  </Text>
                </>
              )}

              <AntSelect
                options={cycles}
                label="Objective Cycle"
                name="cycle_id"
                placeholder="Select Objective Cycle"
                rules={[
                  {
                    required: true,
                    message: 'Please select objective cycle!'
                  }
                ]}
              />

              <Row justify="space-between">
                <Col span={11}>
                  <AntSelect
                    options={objType}
                    label="Objective Type"
                    name="type"
                    placeholder="Select Objective Type"
                    onSelect={(value) => {
                      handleChange(value);
                    }}
                    rules={[
                      {
                        required: true,
                        message: 'Please select objective type!'
                      }
                    ]}
                    extra="Goal will be assigned to Owners."
                  />
                </Col>
                {/* Departments */}
                {isDepChecked ? (
                  <Col span={11}>
                    <AntSelect
                      options={departments}
                      label="Department"
                      name="type_id"
                      placeholder="Select Department"
                      rules={[
                        {
                          required: true,
                          message: 'Please select department!'
                        }
                      ]}
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          {handleStatus()}
                        </>
                      )}
                    />
                  </Col>
                ) : (
                  ''
                )}
                {addDepChecked ? (
                  <Col span={11}>
                    <AntInput
                      size="large"
                      label="Department"
                      maxLength="50"
                      name="department_name"
                      placeholder="Department Name"
                      className="objective-modal-create-department"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your department name.'
                        },
                        onlyAlphabets
                      ]}
                      onPressEnter={ToggleInp}
                      validateTrigger={['onPressEnter', 'onChange']}
                      extra="Type & hit enter to create new"
                    />
                  </Col>
                ) : (
                  ''
                )}
              </Row>

              <AntSelect
                options={users}
                label="Owner"
                name="owner_id"
                disabled={HasAccess({
                  roles: [USERS_ROLE?.USER]
                })}
                placeholder="Select Owner"
                rules={[
                  {
                    required: true,
                    message: 'Please select owner!'
                  }
                ]}
              />

              <div id="watcher_create_model">
                <AntSelect
                  mode="multiple"
                  options={users}
                  label="Watchers"
                  name="watchers"
                  placeholder="Select Watchers"
                  tooltip={{
                    title:
                      'Watchers will be notified when any activities are performed in this objective.',
                    icon: <InfoCircleOutlined />
                  }}
                />
              </div>

              <AntSelect
                allowClear="true"
                mode="multiple"
                options={alignObjective}
                label="Align it to another Goal (Optional)"
                name="align_objectives"
                placeholder="Select Parent Goal"
                className="mb-0"
                tooltip={{
                  title: alignTooltipContent,
                  icon: <InfoCircleOutlined />
                }}
              />
            </Form>
          </Spin>
        }
      />
      <DeleteModal
        visible={isDeleteVisible}
        objectiveId={objectiveId}
        onSub={() => {
          setIsDeleteVisible(false);
          handleDelete();
        }}
        onCancel={() => {
          setIsDeleteVisible(false);
        }}
      />
    </div>
  );
};

export default ObjectiveModal;
