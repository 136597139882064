import React from 'react';
import { SUPPORTEMAIL } from '../../common/constants';

function TermsConditions() {
  return (
    <div>
      <div className="bold-text">Last updated: April 2022</div>
      <br />
      <div className="title-text">1. Introduction</div>
      <div className="normal-text">
        These terms of service (&quot;Terms&quot;) as mentioned here in above
        govern the use of products and services provided by
        <span className="bold-text">
          &nbsp; Logicwind Technologies LLP&nbsp;
        </span>
        and its affiliates/ group companies. All the applications, files, links,
        texts, contents and associated information as uploaded, saved or
        downloaded through the website of
        <span className="bold-text">
          &nbsp; Logicwind Technologies LLP&nbsp;
        </span>
        are subject to the terms of service. Privacy Policy also governs your
        use of our Service and explains how we collect, safeguard and disclose
        information that results from your use of our web pages.
      </div>
      <br />
      <div className="normal-text">
        Your agreement with us includes these Terms and our Privacy Policy
        (“Agreements”). You acknowledge that you have read and understood
        Agreements, and agree to be bound of them.
      </div>
      <br />
      <div className="normal-text">
        These Terms apply to all visitors, users and others who wish to access
        or use Service.
      </div>
      <br />

      <div className="title-text">2. Communications</div>
      <div className="normal-text">
        By using our Service, you agree to subscribe to newsletters, marketing
        or promotional materials and other information we may send. However, you
        may opt out of receiving any, or all, of these communications from us by
        following the unsubscribe link or by emailing at
        <span className="email-text"> {SUPPORTEMAIL}</span>
      </div>
      <br />

      <div className="title-text">3. Contests, Sweepstakes and Promotions</div>
      <div className="normal-text">
        Any contests, sweepstakes or other promotions (collectively,
        “Promotions”) made available through Service may be governed by rules
        that are separate from these Terms of Service. If you participate in any
        Promotions, please review the applicable rules as well as our Privacy
        Policy. If the rules for a Promotion conflict with these Terms of
        Service, Promotion rules will apply.
      </div>
      <br />

      <div className="title-text">4. Content</div>
      <div className="normal-text">
        Our Service allows you to post, link, store, share and otherwise make
        available certain information, text, graphics, videos, or other material
        (“Content”). You are responsible for Content that you post on or through
        Service, including its legality, reliability, and appropriateness.
      </div>
      <br />
      <div className="normal-text">
        By posting Content on or through Service, You represent and warrant
        that: (i) Content is yours (you own it) and/or you have the right to use
        it and the right to grant us the rights and license as provided in these
        Terms, and (ii) that the posting of your Content on or through Service
        does not violate the privacy rights, publicity rights, copyrights,
        contract rights or any other rights of any person or entity. We reserve
        the right to terminate the account of anyone found to be infringing on a
        copyright.
      </div>
      <br />
      <div className="normal-text">
        You retain any and all of your rights to any Content you submit, post or
        display on or through Service and you are responsible for protecting
        those rights. We take no responsibility and assume no liability for
        Content you or any third party posts on or through Service. However, by
        posting Content using Service you grant us the right and license to use,
        modify, publicly perform, publicly display, reproduce, and distribute
        such Content on and through Service. You agree that this license
        includes the right for us to make your Content available to other users
        of Service, who may also use your Content subject to these Terms.
      </div>
      <br />
      <div className="normal-text">
        Logicwind Ventures Pvt. Ltd. has the right but not the obligation to
        monitor and edit all Content provided by users.
      </div>
      <br />
      <div className="normal-text">
        In addition, Content found on or through this Service are the property
        of Logicwind Ventures Pvt. Ltd. or used with permission. You may not
        distribute, modify, transmit, reuse, download, repost, copy, or use said
        Content, whether in whole or in part, for commercial purposes or for
        personal gain, without express advance written permission from us.
      </div>
      <br />

      <div className="title-text">5. Prohibited Uses</div>
      <div className="normal-text">
        You may use Service only for lawful purposes and in accordance with
        Terms. You agree not to use Service:
      </div>
      <br />
      <div className="normal-text">
        <span className="title-text">0.1.</span> In any way that violates any
        applicable national or international law or regulation.
      </div>
      <br />
      <div className="normal-text">
        <span className="title-text">0.2.</span> For the purpose of exploiting,
        harming, or attempting to exploit or harm minors in any way by exposing
        them to inappropriate content or otherwise.
      </div>
      <br />
      <div className="normal-text">
        <span className="title-text">0.3.</span> To transmit, or procure the
        sending of, any advertising or promotional material, including any “junk
        mail”, “chain letter,” “spam,” or any other similar solicitation.
      </div>
      <br />
      <div className="normal-text">
        <span className="title-text">0.4.</span> To impersonate or attempt to
        impersonate Company, a Company employee, another user, or any other
        person or entity.
      </div>
      <br />
      <div className="normal-text">
        <span className="title-text">0.5.</span> In any way that infringes upon
        the rights of others, or in any way is illegal, threatening, fraudulent,
        or harmful, or in connection with any unlawful, illegal, fraudulent, or
        harmful purpose or activity.
      </div>
      <br />
      <div className="normal-text">
        <span className="title-text">0.6.</span> To engage in any other conduct
        that restricts or inhibits anyone’s use or enjoyment of Service, or
        which, as determined by us, may harm or offend Company or users of
        Service or expose them to liability.
      </div>
      <br />
      <div className="normal-text">Additionally, you agree not to: </div>
      <br />
      <div className="normal-text">
        <span className="title-text">0.1.</span> Use Service in any manner that
        could disable, overburden, damage, or impair Service or interfere with
        any other party’s use of Service, including their ability to engage in
        real time activities through Service.
      </div>
      <br />
      <div className="normal-text">
        <span className="title-text">0.2.</span> Use any robot, spider, or other
        automatic device, process, or means to access Service for any purpose,
        including monitoring or copying any of the material on Service.
      </div>
      <br />
      <div className="normal-text">
        <span className="title-text">0.3.</span> Use any manual process to
        monitor or copy any of the material on Service or for any other
        unauthorized purpose without our prior written consent.
      </div>
      <br />
      <div className="normal-text">
        <span className="title-text">0.4.</span> Use any device, software, or
        routine that interferes with the proper working of Service.
      </div>
      <br />
      <div className="normal-text">
        <span className="title-text">0.5.</span> Introduce any viruses, trojan
        horses, worms, logic bombs, or other material which is malicious or
        technologically harmful.
      </div>
      <br />
      <div className="normal-text">
        <span className="title-text">0.6.</span> Attempt to gain unauthorized
        access to, interfere with, damage, or disrupt any parts of Service, the
        server on which Service is stored, or any server, computer, or database
        connected to Service.
      </div>
      <br />
      <div className="normal-text">
        <span className="title-text">0.7.</span> Attack Service via a
        denial-of-service attack or a distributed denial-of-service attack.
      </div>
      <br />
      <div className="normal-text">
        <span className="title-text">0.8.</span> Take any action that may damage
        or falsify Company rating.
      </div>
      <br />
      <div className="normal-text">
        <span className="title-text">0.9.</span> Otherwise attempt to interfere
        with the proper working of Service.
      </div>
      <br />

      <div className="title-text">6. Analytics</div>
      <div className="normal-text">
        We may use third-party Service Providers to monitor and analyze the use
        of our Service.
      </div>
      <br />

      <div className="title-text">7. No Use By Minors</div>
      <div className="normal-text">
        Service is intended only for access and use by individuals at least
        eighteen (18) years old. By accessing or using Service, you warrant and
        represent that you are at least eighteen (18) years of age and with the
        full authority, right, and capacity to enter into this agreement and
        abide by all of the terms and conditions of Terms. If you are not at
        least eighteen (18) years old, you are prohibited from both the access
        and usage of Service.
      </div>
      <br />

      <div className="title-text">8. User Accounts</div>
      <div className="normal-text">
        When you create an account with us, you guarantee that you are above the
        age of 18, and that the information you provide to us is accurate,
        complete, and current at all times. Inaccurate, incomplete, or obsolete
        information may result in the immediate termination of your account on
        Service.
      </div>
      <div className="normal-text">
        You are responsible for maintaining the confidentiality of your account
        and password, including but not limited to the restriction of access to
        your computer and/or account. You agree to accept responsibility for any
        and all activities or actions that occur under your account and/or
        password, whether your password is with our Service or a third-party
        service. You must notify us immediately upon becoming aware of any
        breach of security or unauthorized use of your account.
      </div>
      <div className="normal-text">
        You may not use as a username the name of another person or entity or
        that is not lawfully available for use, a name or trademark that is
        subject to any rights of another person or entity other than you,
        without appropriate authorization. You may not use as a username any
        name that is offensive, vulgar or obscene.
      </div>
      <div className="normal-text">
        We reserve the right to refuse service, terminate accounts, remove or
        edit content, or cancel orders at our sole discretion.
      </div>
      <br />

      <div className="title-text">9. Intellectual Property</div>
      <div className="normal-text">
        Service and its original content (excluding Content provided by users),
        features and functionality are and will remain the exclusive property of
        Logicwind Ventures Pvt. Ltd. and its licensors. Service is protected by
        copyright, trademark, and other laws of and foreign countries. Our
        trademarks may not be used in connection with any product or service
        without the prior written consent of Logicwind Ventures Pvt. Ltd. .
      </div>
      <br />

      <div className="title-text">10. Copyright Policy</div>
      <div className="normal-text">
        We respect the intellectual property rights of others. It is our policy
        to respond to any claim that Content posted on Service infringes on the
        copyright or other intellectual property rights (“Infringement”) of any
        person or entity.
      </div>

      <br />

      <div className="title-text">11. Error Reporting and Feedback</div>
      <div className="normal-text">
        You may provide us either directly at support@theappbarista.com or via
        third party sites and tools with information and feedback concerning
        errors, suggestions for improvements, ideas, problems, complaints, and
        other matters related to our Service (“Feedback”). You acknowledge and
        agree that: (i) you shall not retain, acquire or assert any intellectual
        property right or other right, title or interest in or to the Feedback;
        (ii) Company may have development ideas similar to the Feedback; (iii)
        Feedback does not contain confidential information or proprietary
        information from you or any third party; and (iv) Company is not under
        any obligation of confidentiality with respect to the Feedback. In the
        event the transfer of the ownership to the Feedback is not possible due
        to applicable mandatory laws, you grant Company and its affiliates an
        exclusive, transferable, irrevocable, free-of-charge, sub-licensable,
        unlimited and perpetual right to use (including copy, modify, create
        derivative works, publish, distribute and commercialize) Feedback in any
        manner and for any purpose.
      </div>
      <br />

      <div className="title-text">12. Links To Other Websites</div>
      <div className="normal-text">
        Our Service may contain links to third party web sites or services that
        are not owned or controlled by Logicwind Ventures Pvt. Ltd. .
      </div>
      <div className="normal-text">
        Logicwind Ventures Pvt. Ltd. has no control over, and assumes no
        responsibility for the content, privacy policies, or practices of any
        third party web sites or services. We do not warrant the offerings of
        any of these entities/individuals or their websites.
      </div>
      <div className="normal-text">
        YOU ACKNOWLEDGE AND AGREE THAT COMPANY SHALL NOT BE RESPONSIBLE OR
        LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED
        TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH
        CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD PARTY
        WEB SITES OR SERVICES.
      </div>
      <div className="normal-text">
        WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY POLICIES
        OF ANY THIRD PARTY WEB SITES OR SERVICES THAT YOU VISIT.
      </div>

      <br />

      <div className="title-text">13. Disclaimer Of Warranty</div>
      <div className="normal-text">
        THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS AVAILABLE”
        BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND,
        EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES, OR THE
        INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE
        THAT YOUR USE OF THESE SERVICES, THEIR CONTENT, AND ANY SERVICES OR
        ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.
      </div>
      <div className="normal-text">
        NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY
        WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
        RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT
        LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH
        COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES, THEIR CONTENT, OR ANY
        SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE,
        RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED,
        THAT THE SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF
        VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY SERVICES
        OR ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR
        EXPECTATIONS.
      </div>
      <div className="normal-text">
        COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
        IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY
        WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR
        PARTICULAR PURPOSE.
      </div>
      <div className="normal-text">
        THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR
        LIMITED UNDER APPLICABLE LAW.
      </div>
      <br />

      <div className="title-text">14. Limitation Of Liability</div>
      <div className="normal-text">
        EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS,
        DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE,
        SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES
        (INCLUDING ATTORNEYS’ FEES AND ALL RELATED COSTS AND EXPENSES OF
        LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR
        NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF
        CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN
        CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM
        FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND
        ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES,
        RULES, OR REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY ADVISED OF
        THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS
        LIABILITY FOUND ON THE PART OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT
        PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL
        THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE
        EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL
        DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
      </div>
      <br />

      <div className="title-text">14. Limitation Of Liability</div>
      <div className="normal-text">
        EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS,
        DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE,
        SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES
        (INCLUDING ATTORNEYS’ FEES AND ALL RELATED COSTS AND EXPENSES OF
        LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR
        NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF
        CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN
        CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM
        FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND
        ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES,
        RULES, OR REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY ADVISED OF
        THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS
        LIABILITY FOUND ON THE PART OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT
        PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL
        THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE
        EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL
        DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
      </div>

      <br />

      <div className="title-text">15. Termination</div>
      <div className="normal-text">
        We may terminate or suspend your account and bar access to Service
        immediately, without prior notice or liability, under our sole
        discretion, for any reason whatsoever and without limitation, including
        but not limited to a breach of Terms.
      </div>
      <div className="normal-text">
        If you wish to terminate your account, you may simply discontinue using
        Service.
      </div>
      <div className="normal-text">
        All provisions of Terms which by their nature should survive termination
        shall survive termination, including, without limitation, ownership
        provisions, warranty disclaimers, indemnity and limitations of
        liability.
      </div>
      <br />

      <div className="title-text">16. Governing Law</div>
      <div className="normal-text">
        These Terms shall be governed and construed in accordance with the laws
        of India, which governing law applies to agreement without regard to its
        conflict of law provisions.
      </div>
      <div className="normal-text">
        Our failure to enforce any right or provision of these Terms will not be
        considered a waiver of those rights. If any provision of these Terms is
        held to be invalid or unenforceable by a court, the remaining provisions
        of these Terms will remain in effect. These Terms constitute the entire
        agreement between us regarding our Service and supersede and replace any
        prior agreements we might have had between us regarding Service.
      </div>
      <br />

      <div className="title-text">17. Changes To Service</div>
      <div className="normal-text">
        We reserve the right to withdraw or amend our Service, and any service
        or material we provide via Service, in our sole discretion without
        notice. We will not be liable if for any reason all or any part of
        Service is unavailable at any time or for any period. From time to time,
        we may restrict access to some parts of Service, or the entire Service,
        to users, including registered users.
      </div>
      <br />

      <div className="title-text">18. Amendments To Terms</div>
      <div className="normal-text">
        We may amend Terms at any time by posting the amended terms on this
        site. It is your responsibility to review these Terms periodically.
      </div>
      <div className="normal-text">
        Your continued use of the Platform following the posting of revised
        Terms means that you accept and agree to the changes. You are expected
        to check this page frequently so you are aware of any changes, as they
        are binding on you.
      </div>
      <div className="normal-text">
        By continuing to access or use our Service after any revisions become
        effective, you agree to be bound by the revised terms. If you do not
        agree to the new terms, you are no longer authorized to use Service.
      </div>
      <br />

      <div className="title-text">19. Waiver And Severability</div>
      <div className="normal-text">
        No waiver by Company of any term or condition set forth in Terms shall
        be deemed a further or continuing waiver of such term or condition or a
        waiver of any other term or condition, and any failure of Company to
        assert a right or provision under Terms shall not constitute a waiver of
        such right or provision.
      </div>
      <div className="normal-text">
        If any provision of Terms is held by a court or other tribunal of
        competent jurisdiction to be invalid, illegal or unenforceable for any
        reason, such provision shall be eliminated or limited to the minimum
        extent such that the remaining provisions of Terms will continue in full
        force and effect.
      </div>
      <br />

      <div className="title-text">20. Acknowledgement</div>
      <div className="normal-text">
        BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT
        YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM..
      </div>
      <br />

      <div className="title-text">21. Contact Us</div>
      <div className="normal-text">
        Please send your feedback, comments, requests for technical support by
        email:
        <span className="email-text"> {SUPPORTEMAIL}</span>
      </div>
    </div>
  );
}

export default TermsConditions;
