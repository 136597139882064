import {
  BranchesOutlined,
  EditOutlined,
  LeftOutlined,
  NodeExpandOutlined,
  PlusOutlined
} from '@ant-design/icons';
import {
  Avatar,
  Badge,
  Button,
  Col,
  Divider,
  Dropdown,
  List,
  Menu,
  notification,
  Popover,
  Progress,
  Row,
  Select,
  Slider,
  Spin,
  Tag,
  Tooltip,
  Typography
} from 'antd';
import { isEmpty } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { DeleteIcon, EarthIcon, EditIcon, MoreIcon } from '../../assets';
import { ROUTES, USERS_ROLE } from '../../common/constants';
import AntAvatar from '../../components/AntAvatar';
import AntButton from '../../components/AntButton';
import AntCard from '../../components/AntCard';
import AntPopOver from '../../components/AntPopOver';
import DeleteModal from '../../components/Shared/modals/DeleteModal';
import KeyResultModal from '../../components/Shared/modals/KeyResultModal';
import ObjectiveModal from '../../components/Shared/modals/ObjectiveModal';
import { UserDetailsContext } from '../../context/UserDetailsProvider';
import api from '../../services/api';
import { Content, SubText } from './AntStyled';

const { Text, Title, Paragraph } = Typography;

const { Option } = Select;

const ObjectiveDetails = () => {
  const { id } = useParams();
  const [objective, setObjective] = useState();
  const [keyResultId, setKeyResultId] = useState();
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [isDeleteKeyResult, setIsDeleteKeyResult] = useState(false);
  const [keyResultVisible, setKeyResultVisible] = useState(3);
  const [decodeArrDelete, setDecodeArrDelete] = useState([]);
  const [isResultVisible, setIsResultVisible] = useState(false);
  const [isObjectiveVisible, setIsObjectiveVisible] = useState(false);
  const [alignObjectives, setAlignObjectives] = useState([]);
  const [loading, setLoading] = useState(false);
  const { state } = useContext(UserDetailsContext);
  const user = JSON?.parse(state?.user);
  const navigate = useNavigate();

  /* Get all users */
  const getObjectives = () => {
    setLoading(true);
    api
      ?.get(`objectives/${id}`)
      .then(function (response) {
        const selectedObj = [];
        response?.data?.alinged_objectives?.forEach((row) => {
          selectedObj?.push(row?.id);
        });
        response.data.selectedObj = selectedObj;
        const update = [response.data];
        update?.forEach((data, index) => {
          data?.key_results?.forEach((keys, indexValue) => {
            update[index].key_results[indexValue] = {
              ...keys,
              temp_val: keys?.check_in?.current_progress
            };
          });
        });
        setObjective(...update);
        setLoading(false);
      })
      .catch(function () {
        navigate(ROUTES?.MAIN);
      });
  };

  /* Get all users */
  const getObjectivesForAlignment = () => {
    api
      ?.get(`align-objectives`, {
        params: {
          objectiveId: id
        }
      })
      .then(function (response) {
        const data = [];
        response?.data?.data?.forEach((r) => {
          data?.push(<Option key={r?.id}>{r?.name}</Option>);
        });

        setAlignObjectives(data);
      })
      .catch(function () {});
  };

  useEffect(() => {
    getObjectives();
    getObjectivesForAlignment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const keyResultChange = (val, keyResultIndex) => {
    setObjective((prevState) => {
      const updatedKeyResults = [...prevState.key_results];
      const keyResult = updatedKeyResults?.[keyResultIndex];
      updatedKeyResults[keyResultIndex] = {
        ...keyResult,
        temp_val: val,
        isVisible: false
      };
      updatedKeyResults[keyResultIndex] = {
        ...keyResult,
        temp_val: val,
        isVisible: true
      };
      return {
        ...prevState,
        key_results: updatedKeyResults
      };
    });
  };

  const setIsPopUpVisible = (keyResultIndex) => {
    setObjective((prevState) => {
      const updatedKeyResults = [...prevState.key_results];
      const keyResult = updatedKeyResults?.[keyResultIndex];
      updatedKeyResults[keyResultIndex] = {
        ...keyResult,
        isVisible: false
      };
      return {
        ...prevState,
        key_results: updatedKeyResults
      };
    });
  };

  function handleMenuClick(e) {
    const decodeArr = e?.key?.split('/');
    setDecodeArrDelete(decodeArr);
    setKeyResultId(decodeArr?.[2]);
    if (decodeArr?.[0] === 'Delete') {
      setIsDeleteKeyResult(true);
      setIsDeleteVisible(true);
    } else {
      setIsResultVisible(true);
    }
  }

  const handleDelete = () => {
    api
      ?.delete(`${'key-results/'}${decodeArrDelete?.[3]}`)
      .then(function (response) {
        getObjectives();
        notification?.success({
          message: 'Success',
          description: response?.message
        });
        setDecodeArrDelete([]);
      });
  };

  const menu = (menuId, keyResultCreatedBy, keyResultOwnerId) => {
    const deleteButton = {
      label: 'Delete',
      key: `Delete/${menuId}`,
      icon: <img width="14px" src={DeleteIcon} alt="icon" />
    };
    return (
      <Menu
        onClick={(e) => {
          handleMenuClick(e);
        }}
        items={[
          {
            label: <Text>Edit</Text>,
            key: menuId,
            icon: <img width="14px" src={EditIcon} alt="icon" />
          },
          keyResultCreatedBy === keyResultOwnerId ||
          user?.role === USERS_ROLE?.OWNER
            ? deleteButton
            : false
        ]}
      />
    );
  };

  const addKeyResult = () => {
    setKeyResultId('');
    setIsResultVisible(true);
  };

  const renderSwitch = (type) => {
    switch (type) {
      case 'ON_TRACK':
        return '#3BB665';
      case 'AT_RISK':
        return '#E64E2C';
      case 'BEHIND':
        return '#EABF27';
      default:
        return 'grey';
    }
  };

  const handleChange = (value) => {
    api
      ?.post(`align-objectives`, {
        objective_id: id,
        align_objectives: value?.toString()
      })
      .then(function (response) {
        getObjectives();
        notification?.success?.({
          message: 'Success',
          description: response?.message
        });
      })
      .catch(function () {});
  };

  const alignObjContent = (
    <div>
      <Select
        mode="multiple"
        allowClear
        placeholder="Search..."
        onChange={handleChange}
        className="align_select_inp"
        overlayclassname="dropdown_dep"
        defaultValue={objective?.selectedObj}
      >
        {alignObjectives}
      </Select>
    </div>
  );

  // eslint-disable-next-line no-shadow
  const renderObjectiveTag = (objective) => {
    if (objective?.type === 'COMPANY') {
      return (
        <Tag className="company-tag big-tag mr-32">
          <img src={EarthIcon} alt="" />
          &nbsp; Company
        </Tag>
      );
    }
    if (objective?.type === 'DEPARTMENT') {
      const departmentName =
        objective?.department?.name ??
        objective?.department?.parent_department?.name;
      const isMarketingOrDeveloper =
        departmentName?.includes('Marketing') ||
        departmentName?.includes('Developer');
      const badgeStatus = isMarketingOrDeveloper ? 'error' : 'success';

      return (
        <Badge
          status={badgeStatus}
          text={departmentName}
          className="mr-32 group-tag"
        />
      );
    }
    return (
      <Tag className="mr-32 heading user-tag">{objective?.owner?.name}</Tag>
    );
  };

  const getKeyResultValue = (keyResult) => {
    if (keyResult?.temp_val) {
      return keyResult?.temp_val;
    }
    if (keyResult?.check_in?.current_progress) {
      return keyResult?.check_in?.current_progress;
    }
    return keyResult?.start;
  };

  // eslint-disable-next-line no-shadow
  const renderMoreKeyResultsButton = (objective) => {
    if (objective?.key_results?.length === keyResultVisible) {
      return '';
    }
    if (objective?.key_results?.length > 3) {
      const moreCount = objective?.key_results?.length - keyResultVisible;
      return (
        <Button
          type="link"
          className="click-to-more"
          onClick={() => setKeyResultVisible(keyResultVisible + moreCount)}
        >
          {`+${moreCount} more`}
        </Button>
      );
    }
    return '';
  };

  return (
    <Spin spinning={loading} tip="Please wait...">
      <Content height="calc(100vh - 64px)">
        <KeyResultModal
          visible={isResultVisible}
          objectiveId={id}
          ketResultId={keyResultId}
          onSub={() => {
            getObjectives();
            setIsResultVisible(false);
          }}
          onCancel={() => {
            setIsResultVisible(false);
          }}
        />
        <ObjectiveModal
          visible={isObjectiveVisible}
          objectiveId={id}
          isDeleteObjectiveModal={() => {
            navigate(ROUTES?.MAIN);
            setIsObjectiveVisible(false);
          }}
          onSub={() => {
            getObjectives();
            setIsObjectiveVisible(false);
          }}
          onCancel={() => {
            setIsObjectiveVisible(false);
          }}
        />
        <DeleteModal
          visible={isDeleteVisible}
          objectiveId={id}
          ketResultId={keyResultId}
          isDeleteKeyResult={isDeleteKeyResult}
          onSub={() => {
            setIsDeleteVisible(false);
            handleDelete();
          }}
          onCancel={() => {
            setIsDeleteVisible(false);
            setTimeout(() => {
              setIsDeleteKeyResult(false);
            }, 1000);
          }}
        />
        <div className="objective-details-main-content">
          <Row className="height-100">
            <Col span={16} className="col-1 objective_detail">
              <Row align="middle" gutter={16} className="mb-16">
                <Col className="mr-16">
                  <RouterLink to="/">
                    <LeftOutlined />
                    Back
                  </RouterLink>
                </Col>
                <Col>
                  <Popover
                    content={alignObjContent}
                    trigger="click"
                    title="Align objective(s)"
                    overlayClassName="align_objectives"
                    placement="bottom"
                  >
                    <AntButton className="btn-outline" block={false}>
                      <BranchesOutlined />
                      Click to Align
                    </AntButton>
                  </Popover>
                </Col>
                <Col>
                  {(user?.role === USERS_ROLE?.ADMIN ||
                    user?.role === USERS_ROLE?.MANAGER ||
                    user?.role === USERS_ROLE?.OWNER ||
                    (USERS_ROLE?.USER === user?.role &&
                      objective?.type === 'INDIVIDUAL')) && (
                    <AntButton
                      block={false}
                      onClick={() => setIsObjectiveVisible(true)}
                    >
                      <EditOutlined />
                      Edit
                    </AntButton>
                  )}
                </Col>
              </Row>
              <h5 className="desc-text mb-0">Objective</h5>
              <h3 className="heading title-desc mb-0">{objective?.name}</h3>
              <p className="desc-text mb-0">
                Description: {objective?.description}
              </p>
              <div className="p-4 mt-16 mb-16 mr-16">
                <Progress percent={objective?.percentage} strokeWidth={10} />
              </div>
              <Row align="middle" justify="space-between">
                <Col>
                  <SubText className="sub-text">Owner</SubText>

                  <AntAvatar
                    className="mr-8 ow_name"
                    imgSrc={objective?.owner?.image}
                    userId={objective?.owner?.id}
                    firstChar={objective?.owner?.name?.charAt(0)}
                  />
                  {objective?.owner?.name}
                </Col>
                <Col>
                  <SubText className="sub-text">Objective type</SubText>
                  {renderObjectiveTag(objective)}
                </Col>
                <Col>
                  <SubText className="sub-text">Objective Cycle</SubText>
                  {objective?.cycle?.name}
                </Col>
                <Col>
                  <SubText className="sub-text">Watchers</SubText>
                  <Avatar.Group maxCount={2}>
                    {objective?.watchers?.map((watcher) => {
                      return (
                        <Tooltip
                          overlayClassName="watcher-tooltip"
                          title={watcher?.name}
                          placement="top"
                          key={watcher?.name}
                        >
                          <AntAvatar
                            imgSrc={watcher?.image}
                            userId={watcher?.pivot?.user_id}
                            firstChar={watcher?.name?.charAt(0)}
                          />
                        </Tooltip>
                      );
                    })}
                  </Avatar.Group>
                </Col>
              </Row>
              <Divider />
              <Row justify="space-between">
                <Col>
                  <Title className="text-header">
                    <NodeExpandOutlined />
                    &nbsp; Key Results
                  </Title>
                </Col>
                <Col>
                  <Button
                    type="link"
                    className="key-text"
                    onClick={() => addKeyResult(objective?.id)}
                  >
                    <PlusOutlined />
                    &nbsp; Add key result
                  </Button>
                </Col>
              </Row>
              <AntCard padding="0px 16">
                <div>
                  {objective?.key_results
                    ?.slice(0, keyResultVisible)
                    ?.map((keyResult, index) => {
                      return (
                        <Row
                          justify="space-between"
                          align="middle"
                          className="kr-item"
                          key={keyResult?.name}
                        >
                          <Col className="objective-detail-key-result">
                            <Text strong="true" className="desc-text">
                              {keyResult?.name}
                            </Text>
                            <Paragraph
                              ellipsis={{
                                expandable: true,
                                symbol: 'read more'
                              }}
                              className="paragraph-text"
                            >
                              {keyResult?.description}
                            </Paragraph>
                          </Col>
                          <Col className="center">
                            <AntAvatar
                              size={28}
                              className="mr-16 avatar_icon"
                              imgSrc={keyResult?.owner?.image}
                              userId={keyResult?.owner?.id}
                              firstChar={keyResult?.owner?.name?.charAt(0)}
                            />
                            <AntPopOver
                              isVisible={keyResult?.isVisible}
                              onClose={() => {
                                setIsPopUpVisible(index);
                                getObjectives();
                              }}
                              onSubmit={() => {
                                setIsPopUpVisible(index);
                                getObjectives();
                              }}
                              keyResultRow={keyResult}
                            >
                              <Slider
                                disabled={
                                  user?.id === keyResult?.owner?.id
                                    ? ''
                                    : 'disabled'
                                }
                                min={keyResult?.start ?? 0}
                                max={keyResult?.end ?? 100}
                                trackStyle={{
                                  backgroundColor: renderSwitch(
                                    keyResult?.check_in?.status
                                  )
                                }}
                                value={keyResult?.temp_val}
                                className="slider_keyresult"
                                onChange={(value) => {
                                  keyResultChange(value, index);
                                }}
                              />
                            </AntPopOver>
                            <span className="key_result_val">
                              {getKeyResultValue(keyResult)}
                              {keyResult?.custom_unit_name
                                ? ` ${keyResult?.custom_unit_name}`
                                : '%'}
                            </span>
                            <AntButton
                              disabled={
                                user?.id === keyResult?.owner?.id
                                  ? ''
                                  : 'disabled'
                              }
                              size="default"
                              block={false}
                              type="default"
                              className="ml-32"
                              onClick={() => {
                                keyResultChange(
                                  keyResult?.check_in?.current_progress,
                                  index
                                );
                              }}
                            >
                              Check In
                            </AntButton>
                            {user?.role === USERS_ROLE?.OWNER ||
                            keyResult?.owner?.id === user?.id ? (
                              <Dropdown
                                overlay={menu(
                                  `${objective?.id}/key-result/${keyResult?.id}`,
                                  keyResult?.created_by,
                                  keyResult?.owner?.id
                                )}
                                trigger={['click']}
                                arrow
                                placement="bottomRight"
                              >
                                <div className="key-result-dropdown">
                                  <img
                                    src={MoreIcon}
                                    alt=""
                                    className="ml-12"
                                  />
                                </div>
                              </Dropdown>
                            ) : (
                              <div className="box-size-24 ml-12" />
                            )}
                          </Col>
                        </Row>
                      );
                    })}
                  {renderMoreKeyResultsButton(objective)}
                </div>
              </AntCard>
              {!isEmpty(objective?.alinged_objectives) ? (
                <>
                  <Title className="text-header mt-24">
                    Objectives alinged to this goal:
                  </Title>
                  <ul>
                    {objective?.alinged_objectives?.map((item) => {
                      return (
                        <li key={item?.id}>
                          <Text
                            className="desc-text"
                            underline
                            onClick={() => {
                              navigate(
                                `${ROUTES?.OBJECTIVE_DETAIL}/${item?.id}`
                              );
                            }}
                          >
                            {item?.name}
                          </Text>
                        </li>
                      );
                    })}
                  </ul>
                </>
              ) : null}
            </Col>
            <Col span={8} className="col-2 notification-activities">
              <Title className="text-header">Activities</Title>
              <List
                dataSource={objective?.activities}
                renderItem={(item) => (
                  <>
                    <List.Item key={item?.id} className="activities">
                      <List.Item.Meta
                        avatar={
                          <Avatar
                            src={
                              item?.model_data?.user?.image ??
                              'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y'
                            }
                            size={22}
                          />
                        }
                        title={item?.model_data?.activity}
                        description={<span>{item?.model_data?.name}</span>}
                      />
                      <div className="activities-date">
                        {item?.model_data?.date}
                      </div>
                    </List.Item>
                    <Divider />
                  </>
                )}
              />
            </Col>
          </Row>
        </div>
      </Content>
    </Spin>
  );
};

export default ObjectiveDetails;
