import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { Logo } from '../../assets';
import { ROUTES } from '../../common/constants';

function Index() {
  return (
    <div id="static_page">
      <div id="header">
        <Link to={ROUTES?.MAIN}>
          <img className="logo" src={Logo} alt="" />
        </Link>
      </div>
      <div id="content">
        <Outlet />
      </div>
    </div>
  );
}

export default Index;
