/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  LOGIN: '/login',
  SIGNUP: '/signup',
  LOGOUT: '/logout',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  EMAIL_SENT: '/email-sent',
  TERMS_AND_CONDITION: '/terms-condition',
  PRIVACY_POLICY: '/privacy-policy',
  OBJECTIVE_DETAIL: '/objective-details',
  COMPANY_SETTING: '/setting',
  ONBOARDING: '/onboarding',
  CREATE_COMPANY: 'create-company',
  CREATE_DEPARTMENT: 'create-departments',
  INVITE_MEMBERS: 'invite-members'
};

/* USERS ROLES */
export const USERS_ROLE = {
  OWNER: 'OWNER',
  ADMIN: 'ADMIN',
  MANAGER: 'MANAGER',
  USER: 'USER'
};

/* SUPPORT EMAIL */
export const SUPPORTEMAIL = 'support@okrboard.com';

export const REGEX = {
  EMAIL: /^(([^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+(\.[^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  ALPHABETS: /^[a-zA-Z ]*$/,
  NUMBERS: /^[0-9]*$/,
  ALPHABETS_NUMBERS_SPACE: /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/,
  ALPHABETS_DOT_SPACE: /^[a-zA-Z .]+$/,
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
};

export const MSAL_SCOPES =
  'email profile offline_access group.read.all user.read user.read.all';

export const PROGRESS_TYPE = [
  // commenting for future use
  // {
  //   name: 'Custom Percentage',
  //   value: 'CUSTOM_PERCENTAGE'
  // },
  {
    name: 'Checkbox',
    value: 'CHECKBOX'
  },
  {
    name: 'Custom unit',
    value: 'CUSTOM_UNIT'
  },
  {
    name: 'Default progress',
    value: 'DEFAULT_PROGRESS'
  }
];

export const PROGRESS_TYPE_LIST = {
  CHECKBOX: 'CHECKBOX',
  CUSTOM_UNIT: 'CUSTOM_UNIT',
  DEFAULT_PROGRESS: 'DEFAULT_PROGRESS'
};
