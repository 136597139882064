const Strings = {
  auth: {
    login: 'Login',
    signUp: 'Sign up',
    forgotPassword: 'Forgot Password',
    createCompany: 'Create Company',
    loginDescription: 'Meet Goals and Inspire Your Team Using OKR',
    google: 'Continue with Google',
    outlook: 'Continue with Outlook',
    account: 'Don’t have an account?',
    welcome: 'Welcome to okrboard',
    welcomeDescription:
      'okrboard provides a structured way to plan, execute and track your business objectives', // New line not allowed
    join: 'Join the company',
    signUpTagline: 'Create an account to start using okrboard',
    PasswordDescription:
      'Lost your password ? Please enter your email \naddress. You will receive a link to create a new \npassword via email.',
    accountError: 'An account with this email id already exists. Please',
    signUpDescription:
      ' By Signing up, you agree to our Terms and you acknowledge having read our Privacy Notice',
    companyLogo: 'Company Logo',
    departments: 'Departments',
    departmentsDescription: 'Select existing department or Create new one',
    invite: 'Invite Members',
    inviteDescription:
      'Invite members to your company. You’ll be able to add more members later.',
    emailSent: 'Email Sent',
    emailSentDescription: 'We’ve emailed a password recovery link to',
    EmailLink: 'Click the link in the mail to reset your password.'
  }
};

export default Strings;
