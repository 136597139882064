import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Form, Row } from 'antd';
import React, { useContext } from 'react';
import { CloseIcon } from '../../../assets';
import { UserDetailsContext } from '../../../context/UserDetailsProvider';
import AntModal from '../../AntModal';

const DeleteMemberModal = ({ visible, onSub, onCancel, deleteMemberName }) => {
  const { state } = useContext(UserDetailsContext);
  const user = JSON?.parse(state?.user);
  const companyName = user?.company?.name;
  const [form] = Form?.useForm();

  const onFinish = () => {
    onSub();
  };

  return (
    <>
      <AntModal
        width={420}
        title={
          <>
            <InfoCircleOutlined />
            <span className="delete-title-text">Remove Member</span>
          </>
        }
        className="delete-modal delete-member-modal"
        visible={visible}
        onOk={form?.submit}
        okText="Yes, remove"
        onCancel={onCancel}
        footer="default"
        closeIcon={<img src={CloseIcon} alt="close" />}
        destroyOnClose
        content={
          <Form
            form={form}
            name="basic"
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            requiredMark={false}
            preserve={false}
          >
            <Row>
              <Col span={24}>
                <Form.Item>
                  <div className="delete-member-modal-text">
                    <>
                      <p>
                        {'Are you sure you want to remove '}
                        <span>
                          {deleteMemberName !== 'undefined'
                            ? deleteMemberName
                            : 'Invited User'}
                        </span>
                        {' from '}
                        <span>{companyName}</span> company ?
                      </p>
                    </>
                  </div>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        }
      />
    </>
  );
};

export default DeleteMemberModal;
