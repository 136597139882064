import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Form, Row } from 'antd';
import React from 'react';
import { CloseIcon } from '../../../assets';
import AntModal from '../../AntModal';

const DeleteModal = ({ visible, onSub, onCancel, isDeleteKeyResult }) => {
  const [form] = Form?.useForm();

  const onFinish = () => {
    onSub();
  };

  return (
    <>
      <AntModal
        width={420}
        title={
          <>
            <InfoCircleOutlined />
            <span className="delete-title-text">
              {isDeleteKeyResult ? 'Delete Key Result' : 'Delete Objective'}
            </span>
          </>
        }
        className="delete-modal"
        visible={visible}
        onOk={form?.submit}
        okText="Yes, delete"
        onCancel={onCancel}
        footer="default"
        closeIcon={<img src={CloseIcon} alt="close" />}
        destroyOnClose
        content={
          <Form
            form={form}
            name="basic"
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            requiredMark={false}
            preserve={false}
          >
            <Row>
              <Col span={24}>
                <Form.Item>
                  <div className="delete-modal-text">
                    {isDeleteKeyResult ? (
                      <>
                        <p>
                          <strong>
                            Are you sure you want to delete this key result?
                          </strong>
                        </p>
                        <p>
                          You won&apos;t be able to check-in progress for this
                          Key Result.
                        </p>
                      </>
                    ) : (
                      <>
                        <p>
                          <strong>
                            Are you sure you want to delete this objective?
                          </strong>
                        </p>
                        <p>
                          All the Key results inside this objective will be
                          deleted.
                        </p>
                      </>
                    )}
                  </div>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        }
      />
    </>
  );
};

export default DeleteModal;
