import {
  CloseCircleOutlined,
  LogoutOutlined,
  UserOutlined
} from '@ant-design/icons';
import {
  Avatar,
  Badge,
  Button,
  Checkbox,
  Col,
  Drawer,
  Dropdown,
  Form,
  List,
  Menu,
  Row,
  Select,
  Space,
  Spin,
  Tabs,
  TreeSelect
} from 'antd';
import Search from 'antd/lib/transfer/search';
import { debounce, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  ArrowClose,
  ArrowIcon,
  ArrowRightDark,
  CloseIcon,
  HeaderLogo,
  NotificationIcon,
  SearchIcon,
  SelectedTick,
  SubMenuEXpand
} from '../../assets';
import { ROUTES } from '../../common/constants';
import { UserDetailsContext } from '../../context/UserDetailsProvider';
import api from '../../services/api';
import AntAvatar from '../AntAvatar';
import AntButton from '../AntButton';
import AntInput from '../AntInput';
import { Body, Content } from './AntStyled';
import ObjectiveModal from './modals/ObjectiveModal';
import ProfileModal from './modals/ProfileModal';

const { Option } = Select;
const { TabPane } = Tabs;

const DashboardMaster = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState();
  const [isMyObjective, setIsMyObjective] = useState('true');
  const [objectiveType, setObjectiveType] = useState();
  const [objectiveTypeId, setObjectiveTypeId] = useState();
  const [objectiveMemberId, setObjectiveMemberId] = useState();
  const [statusSelect, setStatusSelect] = useState();
  const [status, setStatus] = useState();
  const [objectiveCycle, setObjectiveCycle] = useState();
  const [visible, setVisible] = useState(false);
  const [isProfileModal, SetIsProfileModal] = useState(false);
  const [notificationDrawer, SetNotificationDrawer] = useState(false);
  const [cycles, setCycle] = useState([]);
  const [notificationData, setNotificationData] = useState([]);
  const [newObjectiveAdded, setNewObjectiveAdded] = useState(0);
  const [departments, setDepartments] = useState([]);
  const [departmentLoading, setDepartmentLoading] = useState(false);
  const [memberLoading, setMemberLoading] = useState(false);
  const [members, setMembers] = useState([]);
  const [dropdownVisibleType, setDropdownVisibleType] = useState(false);
  const [dropdownVisibleStatus, setDropdownVisibleStatus] = useState(false);
  const [dropdownVisibleCycle, setDropdownVisibleCycle] = useState(false);
  const { state, dispatch } = useContext(UserDetailsContext);
  const user = JSON?.parse(state?.user);
  const location = useLocation();
  const [path, setPath] = useState();
  const [open, setOpen] = useState(false);
  const [departmentCheckbox, setDepartmentCheckbox] = useState([]);
  const [memberCheckbox, setMemberCheckbox] = useState([]);
  const [typeKey, setTypekey] = useState();

  useLayoutEffect(() => {
    // eslint-disable-next-line no-undef
    setPath(window?.location?.pathname);
  }, [location]);

  useEffect(() => {
    /* get objective Cycle */
    api?.get('objective-cycles').then(function (response) {
      const getCycle = [];
      response?.data?.forEach((r) => {
        getCycle?.push({
          name: r?.name,
          value: r?.id
        });
      });
      setCycle(getCycle);
    });

    api?.get('user').then(function (response) {
      dispatch({
        type: 'UPDATE_USER',
        payload: response?.data
      });
      if (Object?.keys(response?.data?.company)?.length === 0) {
        navigate(`${ROUTES?.ONBOARDING}/${ROUTES?.CREATE_COMPANY}`);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!notificationDrawer) return;
    api?.get('notifications').then((res) => {
      setNotificationData(res?.data?.data);
    });
  }, [notificationDrawer]);

  useEffect(() => {
    setDropdownVisibleType(open);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const getDepartments = async (value) => {
    setDepartmentLoading(true);
    api
      ?.get('departments', {
        params: {
          is_from_setting_page: true,
          search: value
        }
      })
      .then(function (response) {
        const data = [];
        response?.data?.forEach((r) => {
          data?.push({
            name: r?.name ?? r?.parent_department?.name,
            value: r?.id
          });
        });
        setDepartments(data);
        setDepartmentLoading(false);
      });
  };

  const getMembers = async (value) => {
    setMemberLoading(true);
    api
      ?.get('company-members', {
        params: {
          search: value
        }
      })
      .then(function (response) {
        const data = [];
        response?.data?.data?.forEach((r) => {
          if (r?.name) {
            data?.push({
              value: r?.id,
              name: r?.name,
              image: r?.image
            });
          }
        });
        setMembers(data);
        setMemberLoading(false);
      });
  };

  const showProfileModal = (e) => {
    if (e?.key === 'edit') {
      SetIsProfileModal(true);
    } else {
      dispatch({ type: 'LOGOUT' });
      navigate(ROUTES?.LOGIN);
    }
  };

  const doSearch = (evt) => {
    if (evt?.target?.value) {
      setSearch(evt?.target?.value);
    } else {
      setSearch();
    }
  };

  const objectiveSearch = debounce(doSearch, 500);

  const menu = (
    <Menu
      onClick={showProfileModal}
      items={[
        {
          key: 'edit',
          label: <span>Edit Profile</span>,
          icon: <UserOutlined />
        },
        {
          key: 'logout',
          label: <span>Logout</span>,
          icon: <LogoutOutlined />
        }
      ]}
    />
  );

  const handleStatus = () => {
    return (
      <Button
        type="link"
        className="apply-status"
        onClick={() => {
          setStatus(statusSelect);
          setDropdownVisibleStatus(false);
        }}
      >
        Apply
      </Button>
    );
  };

  const treeData = [
    {
      title: (
        <div>
          <Badge status="success" /> On Track
        </div>
      ),
      value: 'ON_TRACK',
      key: 'ON_TRACK'
    },
    {
      title: (
        <div>
          <Badge status="error" /> At Risk
        </div>
      ),
      value: 'AT_RISK',
      key: 'AT_RISK'
    },
    {
      title: (
        <div>
          <Badge status="warning" /> Behind
        </div>
      ),
      value: 'BEHIND',
      key: 'BEHIND'
    },
    {
      title: (
        <div>
          <Badge status="default" /> Not Started
        </div>
      ),
      value: 'NOT_STARTED',
      key: 'NOT_STARTED'
    }
  ];

  const handleOpenChange = (flag) => {
    setOpen(flag);
    setDropdownVisibleType(flag);
  };

  const handleObjectiveTypeDeptSearch = (e) => {
    const value = e?.target?.value;
    getDepartments(value);
  };

  const objectiveTypeDeptDebounce = debounce(
    handleObjectiveTypeDeptSearch,
    500
  );

  const handleObjectiveTypeMemberSearch = (e) => {
    const value = e?.target?.value;
    getMembers(value);
  };

  const objectiveTypeMemberDebounce = debounce(
    handleObjectiveTypeMemberSearch,
    500
  );

  const submenuSelectDept = (e) => {
    if (departmentCheckbox?.includes(e?.key)) {
      const departmentUncheck = [...departmentCheckbox];
      departmentUncheck?.splice(departmentUncheck?.indexOf(e?.key), 1);
      setDepartmentCheckbox([...departmentUncheck]);
    } else {
      setDepartmentCheckbox([...departmentCheckbox, e?.key]);
    }
  };

  const submenuSelectMembers = (e) => {
    if (memberCheckbox?.includes(e?.key)) {
      const memberUncheck = [...memberCheckbox];
      memberUncheck?.splice(memberUncheck?.indexOf(e?.key), 1);
      setMemberCheckbox([...memberUncheck]);
    } else {
      setMemberCheckbox([...memberCheckbox, e?.key]);
    }
  };

  const departmentTypes = () => {
    return (
      <>
        <Search placeholder="Search" onChange={objectiveTypeDeptDebounce} />
        <Spin direction="horizontal" spinning={departmentLoading} size="small">
          <Menu
            onSelect={submenuSelectDept}
            className="department-type-submenu"
          >
            {departments?.map((data) => {
              return (
                <Menu.Item key={data?.value}>
                  <Checkbox
                    value={data?.value}
                    defaultChecked={departmentCheckbox?.includes(data?.value)}
                  >
                    {data?.name}
                  </Checkbox>
                </Menu.Item>
              );
            })}
          </Menu>
        </Spin>
        <Button
          className="department-apply-button"
          type="link"
          onClick={() => {
            setOpen(false);
            setDropdownVisibleType(false);
            setTypekey('DEPARTMENT');
            setObjectiveType(['DEPARTMENT']);
            setObjectiveTypeId(departmentCheckbox);
          }}
        >
          Apply
        </Button>
      </>
    );
  };

  const membersTypes = () => {
    return (
      <>
        <Search placeholder="Search" onChange={objectiveTypeMemberDebounce} />
        <Spin direction="horizontal" spinning={memberLoading} size="small">
          <Menu
            onSelect={submenuSelectMembers}
            className="department-type-submenu owner-multi-submenu"
          >
            {members?.map((data) => {
              return (
                <Menu.Item key={data?.value}>
                  <Checkbox
                    value={data?.value}
                    defaultChecked={memberCheckbox?.includes(data?.value)}
                  >
                    <AntAvatar
                      imgSrc={data?.image}
                      firstChar={data?.name?.charAt(0)}
                    />
                    {data?.name}
                  </Checkbox>
                </Menu.Item>
              );
            })}
          </Menu>
        </Spin>
        <Button
          className="department-apply-button"
          type="link"
          onClick={() => {
            setOpen(false);
            setDropdownVisibleType(false);
            setTypekey('INDIVIDUAL');
            setObjectiveType(['INDIVIDUAL']);
            setObjectiveMemberId(memberCheckbox);
          }}
        >
          Apply
        </Button>
      </>
    );
  };

  const handleMenuSelect = ({ key }) => {
    setTypekey(key);
    if (key === 'ALL') {
      setObjectiveType();
    } else {
      setObjectiveType([key]);
    }
    setObjectiveTypeId([]);
    setMemberCheckbox([]);
    setDepartmentCheckbox([]);
    setObjectiveMemberId([]);
    setOpen(false);
  };

  const menuType = (
    <Menu
      triggerSubMenuAction="click"
      onSelect={handleMenuSelect}
      selectedKeys={typeKey}
      selectable
      className="department-type-dropdown"
      expandIcon={<img height="13px" src={SubMenuEXpand} alt="s-icon" />}
    >
      <Menu.Item key="ALL" value="ALL">
        All
      </Menu.Item>
      <Menu.Item key="COMPANY" value="COMPANY">
        Company
      </Menu.Item>
      {open && (
        <Menu.SubMenu
          key="DEPARTMENT"
          value="DEPARTMENT"
          title="Department"
          onTitleClick={() => {
            getDepartments();
          }}
        >
          {departmentTypes()}
        </Menu.SubMenu>
      )}
      {isMyObjective === 'true' ? (
        <Menu.Item key="INDIVIDUAL" value="INDIVIDUAL">
          Individual
        </Menu.Item>
      ) : (
        <>
          {open && (
            <Menu.SubMenu
              key="INDIVIDUAL"
              value="INDIVIDUAL"
              title="Individual"
              onTitleClick={() => {
                getMembers();
              }}
            >
              {membersTypes()}
            </Menu.SubMenu>
          )}
        </>
      )}
    </Menu>
  );

  const ObjectiveTypeClear = () => {
    setObjectiveType();
    setTypekey();
    setObjectiveTypeId([]);
    setMemberCheckbox([]);
    setDepartmentCheckbox([]);
    setObjectiveMemberId([]);
  };

  return (
    <>
      {!isEmpty(user?.company) ? (
        <Content>
          <ProfileModal
            visible={isProfileModal}
            onCancel={() => {
              SetIsProfileModal(false);
            }}
          />
          <Row justify="space-between" className="header" align="middle">
            <Col>
              <Avatar
                shape="square"
                className="logo"
                src={HeaderLogo}
                onClick={() => navigate(ROUTES?.MAIN)}
              />
              <Tabs
                defaultActiveKey="true"
                display="b"
                onTabClick={() => {
                  navigate(ROUTES?.MAIN);
                }}
                onChange={(value) => {
                  setIsMyObjective(`${value === 'myobjective'}`);
                }}
                className="header-tab"
              >
                <TabPane tab="My Objectives" key="myobjective" />
                <TabPane tab="Explore Objectives" key="exploreobjective" />
              </Tabs>
            </Col>
            <Row align="middle" gutter={24}>
              <Col>
                <AntButton
                  type="primary"
                  size="large"
                  block={false}
                  onClick={() => setVisible(true)}
                >
                  + Create Objectives
                </AntButton>
              </Col>
              <ObjectiveModal
                visible={visible}
                onSub={() => {
                  setVisible(false);
                  setNewObjectiveAdded(newObjectiveAdded + 1);
                }}
                onCancel={() => setVisible(false)}
              />
              <Col className="profile-dropdown">
                <Dropdown
                  overlay={menu}
                  trigger={['click']}
                  placement="bottomRight"
                >
                  <Row gutter={12} align="middle">
                    <Col>
                      <Avatar
                        src={
                          user?.image ??
                          'https://www.gravatar.com/avatar?d=mp&f=y'
                        }
                      />
                    </Col>
                    <Col className="owner-title">{user?.name}</Col>
                  </Row>
                </Dropdown>
              </Col>
              <div className="v-border" />
              <Col
                className="pointer"
                onClick={() => {
                  SetNotificationDrawer(true);
                }}
              >
                <img src={NotificationIcon} alt="icon" width={24} />
              </Col>
              <Col>
                <Avatar
                  shape="square"
                  onClick={() => {
                    navigate(ROUTES?.COMPANY_SETTING);
                  }}
                  className="setting_icon"
                  src={user?.company?.logo}
                />
              </Col>
              <Drawer
                title="Notifications"
                placement="right"
                onClose={() => {
                  SetNotificationDrawer(false);
                }}
                visible={notificationDrawer}
                closeIcon={
                  <CloseCircleOutlined className="notification-close" />
                }
              >
                <List
                  dataSource={notificationData}
                  renderItem={(item) => (
                    <List.Item key={item?.id} className="notifications">
                      <List.Item.Meta
                        avatar={<Badge color="#3452EC" />}
                        title={<span>{item?.model_data?.name}</span>}
                        description={item?.model_data?.activity}
                      />
                      <div className="date">
                        {moment(item?.updated_at)?.format('LT')}
                        <img width="14px" src={ArrowRightDark} alt="arrow" />
                      </div>
                    </List.Item>
                  )}
                />
              </Drawer>
            </Row>
          </Row>
          {path === '/' ? (
            <Form>
              <Row justify="space-between" className="header" align="middle">
                <Col>
                  <AntInput
                    name="search"
                    placeholder="Search"
                    prefix={<img width="18px" src={SearchIcon} alt="s-icon" />}
                    allowClear
                    autoComplete="off"
                    onChange={objectiveSearch}
                    className="mb-0 filters"
                  />
                </Col>
                <Col>
                  <Row>
                    <div
                      className="select-filter-sel department-dropdown"
                      id="type-header"
                    >
                      <b> Objective Type:</b>
                      <Dropdown
                        overlay={menuType}
                        placement="bottomRight"
                        trigger={['click']}
                        getPopupContainer={() =>
                          // eslint-disable-next-line no-undef
                          document?.getElementById('type-header')
                        }
                        onVisibleChange={handleOpenChange}
                        visible={open}
                        arrow
                        suffixIcon={
                          <img
                            width="16px"
                            src={dropdownVisibleType ? ArrowClose : ArrowIcon}
                            alt="s-icon"
                          />
                        }
                      >
                        <Space>
                          {typeKey ? (
                            <Button>{typeKey}</Button>
                          ) : (
                            <Button className="placeholder">Select type</Button>
                          )}
                          <img
                            width="16px"
                            src={dropdownVisibleType ? ArrowClose : ArrowIcon}
                            alt="s-icon"
                          />
                        </Space>
                      </Dropdown>
                      {objectiveType || typeKey ? (
                        <Space onClick={ObjectiveTypeClear}>
                          <img width="16px" src={CloseIcon} alt="s-icon" />
                        </Space>
                      ) : (
                        false
                      )}
                    </div>
                    <div className="select-filter-sel" id="status-header">
                      <b> Status: </b>
                      <TreeSelect
                        onDropdownVisibleChange={(e) => {
                          setDropdownVisibleStatus(e);
                        }}
                        open={dropdownVisibleStatus}
                        dropdownMatchSelectWidth={false}
                        allowClear
                        treeData={treeData}
                        className="ant-select-borderless"
                        treeCheckable="true"
                        placement="bottomRight"
                        getPopupContainer={() =>
                          // eslint-disable-next-line no-undef
                          document?.getElementById('status-header')
                        }
                        onChange={(val) => {
                          setStatusSelect(val);
                          if (!dropdownVisibleStatus) {
                            setStatus(val);
                          }
                        }}
                        onClear={() => {
                          setStatus();
                        }}
                        showArrow
                        suffixIcon={
                          <img
                            width="16px"
                            src={dropdownVisibleStatus ? ArrowClose : ArrowIcon}
                            alt="s-icon"
                          />
                        }
                        placeholder="Select status"
                        maxTagCount={1}
                        maxTagPlaceholder={(value) => {
                          return `+${value?.length}`;
                        }}
                        dropdownRender={(menuComp) => (
                          <>
                            {menuComp}
                            {handleStatus()}
                          </>
                        )}
                      />
                    </div>
                    <div className="select-filter-sel" id="cycle-header">
                      <b> Objective Cycle: </b>
                      <Select
                        onDropdownVisibleChange={(e) => {
                          setDropdownVisibleCycle(e);
                        }}
                        allowClear
                        placement="bottomRight"
                        placeholder="Select cycle"
                        className="select-cycle-header"
                        bordered={false}
                        onChange={(val) => {
                          setObjectiveCycle(val ? [val] : null);
                        }}
                        getPopupContainer={() =>
                          // eslint-disable-next-line no-undef
                          document?.getElementById('cycle-header')
                        }
                        menuItemSelectedIcon={
                          <img width="16px" src={SelectedTick} alt="s-icon" />
                        }
                        dropdownMatchSelectWidth={false}
                        suffixIcon={
                          <img
                            width="16px"
                            src={dropdownVisibleCycle ? ArrowClose : ArrowIcon}
                            alt="s-icon"
                          />
                        }
                        showArrow
                      >
                        {cycles?.map((cycle) => {
                          return (
                            <Option key={cycle?.value}>{cycle?.name}</Option>
                          );
                        })}
                      </Select>
                    </div>
                  </Row>
                </Col>
              </Row>
            </Form>
          ) : (
            ''
          )}
          <Body>
            <Outlet
              context={[
                search,
                objectiveType,
                objectiveTypeId,
                status,
                objectiveCycle,
                isMyObjective,
                objectiveMemberId,
                newObjectiveAdded
              ]}
            />
          </Body>
        </Content>
      ) : (
        <Spin
          className="main-header-spin"
          size="large"
          direction="horizontal"
          spinning
        />
      )}
    </>
  );
};

export default DashboardMaster;
