import { notification } from 'antd';
import axios from 'axios';
import { ROUTES } from '../common/constants';
import History from './History';

const api = axios?.create({
  baseURL: process.env.REACT_APP_SERVER_URL
});

api?.interceptors?.request?.use((config) => {
  // eslint-disable-next-line no-undef
  const token = localStorage?.getItem('token');
  if (token) {
    // eslint-disable-next-line no-param-reassign, no-undef
    config.headers.Authorization = `Bearer ${localStorage?.getItem('token')}`;
  }
  return config;
});

api?.interceptors?.response?.use(
  (response) => {
    return response?.data;
  },
  (err) => {
    if (err?.response?.status === 401) {
      if (
        err?.response?.data?.message === 'Unauthenticated' &&
        err?.response?.data?.status_code === 401
      ) {
        notification?.error({
          message: 'Error',
          description:
            'Unauthorized: Your session has expired. Please login again.'
        });
        History?.navigate(ROUTES?.LOGOUT);
      }
      // eslint-disable-next-line no-undef
      localStorage?.clear();
      return {
        isAuthenticated: false,
        user: null
      };
    }
    notification?.error({
      message: 'Error',
      description:
        err?.response?.data?.message?.length > 0
          ? err?.response?.data?.message
          : 'Something went wrong, Please try again'
    });
    return Promise?.reject(err);
  }
);
export default api;
