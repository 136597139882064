import { Col, Form, Input, notification, Row, Typography } from 'antd';
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BackgroundPattern, Logo } from '../../../assets';
import { ROUTES } from '../../../common/constants';
import AntButton from '../../../components/AntButton';
import AntCard from '../../../components/AntCard';
import AntCheckbox from '../../../components/AntCheckbox';
import api from '../../../services/api';
import { Content } from '../AntStyled';

const { Text } = Typography;

const ResetPassword = () => {
  const navigate = useNavigate();
  const [passwordCheck, setPasswordCheck] = useState(false);

  const { search } = useLocation();
  const token = new URLSearchParams(search)?.get('token');
  const email = new URLSearchParams(search)?.get('email');

  const [charNumberValid, setCharNumberValid] = useState(false);
  const [specialCharValid, setSpecialCharValid] = useState(false);
  const [lowercaseValid, setLowercaseValid] = useState(false);
  const [uppercaseValid, setUppercaseValid] = useState(false);
  const [numberValid, setNumberValid] = useState(false);

  // Check the length of the input
  const checkPasswordLength = (password) => {
    setCharNumberValid(password?.length >= 8 && password?.length <= 16);
  };

  // Check for special characters
  const checkSpecialCharacters = (password) => {
    // eslint-disable-next-line no-useless-escape
    const pattern = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
    setSpecialCharValid(pattern?.test(password));
  };

  // Check for an lowercase character
  const checkLowercase = (password) => {
    const pattern = /[a-z]/;
    setLowercaseValid(pattern?.test(password));
  };

  // Check for an uppercase character
  const checkUppercase = (password) => {
    const pattern = /[A-Z]/;
    setUppercaseValid(pattern?.test(password));
  };

  // Check for a number
  const checkNumber = (password) => {
    const pattern = /[0-9]/;
    setNumberValid(pattern?.test(password));
  };

  const handlePasswordChange = (event) => {
    setPasswordCheck(true);
    checkPasswordLength(event?.target?.value);
    checkSpecialCharacters(event?.target?.value);
    checkLowercase(event?.target?.value);
    checkUppercase(event?.target?.value);
    checkNumber(event?.target?.value);
  };

  const onFinish = (values) => {
    const data = { ...values, token, email };
    api
      ?.post('reset-password', data)
      .then(function (response) {
        notification?.success({
          message: 'Success',
          description: response?.message
        });
        navigate(ROUTES?.LOGIN);
      })
      .catch(function () {});
  };

  return (
    <Content className="sign-up-steps">
      <div className="logo-wrapper">
        <Link to="/">
          <img className="logo" src={Logo} alt="" />
        </Link>
      </div>
      <Row>
        <Col flex="auto" className="form-col">
          <Content>
            <div className="wrapper">
              <Typography.Title className="title heading mb-0">
                Set Password
              </Typography.Title>
              <Text className="sub-title">
                Please enter to create a new password!
              </Text>
              <AntCard bordered padding={16}>
                <Form
                  name="basic"
                  onFinish={onFinish}
                  autoComplete="off"
                  layout="vertical"
                  requiredMark={false}
                >
                  <Form.Item
                    label="Create Password"
                    name="password"
                    placeholder="Password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password.'
                      }
                    ]}
                  >
                    <Input.Password
                      onChange={(event) => handlePasswordChange(event)}
                      size="large"
                      placeholder="Password"
                      className="radius-8"
                    />
                  </Form.Item>

                  <Form.Item
                    label="Confirm Password"
                    name="password_confirmation"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password!'
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise?.resolve();
                          }
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise?.reject(
                            'The two passwords that you entered do not match!'
                          );
                        }
                      })
                    ]}
                  >
                    <Input.Password
                      size="large"
                      placeholder="Confirm Password"
                      className="radius-8"
                    />
                  </Form.Item>
                  {passwordCheck && (
                    <div className="validation">
                      <div className="validator">
                        <AntCheckbox
                          className="mb-0"
                          checked={charNumberValid ? 'checked' : ''}
                        />
                        <p className="validation-item">8-16 characters</p>
                      </div>
                      <div className="validator">
                        <AntCheckbox
                          className="mb-0"
                          checked={specialCharValid ? 'checked' : ''}
                        />
                        <p className="validation-item">1 special character</p>
                      </div>
                      <div className="validator">
                        <AntCheckbox
                          className="mb-0"
                          checked={lowercaseValid ? 'checked' : ''}
                        />
                        <p className="validation-item">1 lowercase letter</p>
                      </div>
                      <div className="validator">
                        <AntCheckbox
                          className="mb-0"
                          checked={uppercaseValid ? 'checked' : ''}
                        />
                        <p className="validation-item">1 uppercase letter</p>
                      </div>
                      <div className="validator">
                        <AntCheckbox
                          className="mb-0"
                          checked={numberValid ? 'checked' : ''}
                        />
                        <p className="validation-item">1 number</p>
                      </div>
                    </div>
                  )}
                  <AntButton
                    type="primary"
                    htmlType="submit"
                    block
                    size="large"
                    disabled={
                      charNumberValid &&
                      specialCharValid &&
                      lowercaseValid &&
                      uppercaseValid &&
                      numberValid
                        ? false
                        : 'disabled'
                    }
                  >
                    Update password
                  </AntButton>
                </Form>
              </AntCard>
            </div>
          </Content>
          <img className="background-pattern" src={BackgroundPattern} alt="" />
        </Col>
      </Row>
    </Content>
  );
};

export default ResetPassword;
