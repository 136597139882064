import { MSAL_SCOPES } from './common/constants';

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MICROSOFT_ID,
    authority: process.env.REACT_APP_MICROSOFT_AUTHORITY,
    redirectUri: process.env.REACT_APP_MICROSOFT_REDIRECT_URI
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  }
};

export const loginRequest = {
  scopes: [MSAL_SCOPES],
  prompt: 'select_account'
};
