import {
  Badge,
  Col,
  Form,
  notification,
  Row,
  Spin,
  Tag,
  TreeSelect
} from 'antd';
import React, { useEffect, useState } from 'react';
import { CloseIcon } from '../../../assets';
import api from '../../../services/api';
import AntInput from '../../AntInput';
import AntModal from '../../AntModal';
import AntSelect from '../../AntSelect';

const EditMemberModal = ({ visible, onOk, onCancel, memberId = '' }) => {
  const [form] = Form?.useForm();
  const [departments, setDepartments] = useState([]);
  const [loadingEdit, setLoadingEdit] = useState(true);

  const onFinish = (values) => {
    const data = {
      ...values,
      department_id: values?.department_id?.toString(),
      member_id: memberId
    };
    api?.post('edit-member', data).then(function (response) {
      notification?.success({
        message: 'Success',
        description: response?.message
      });
      onOk();
    });
  };

  useEffect(() => {
    /* Get departments data */
    api
      ?.get('departments', {
        params: { is_from_setting_page: true }
      })
      .then(function (response) {
        const data = [];
        response?.data?.forEach((r) => {
          data?.push({
            title: r?.name ?? r?.parent_department?.name,
            value: r?.id,
            key: r?.id
          });
        });
        setDepartments(data);
      });
  }, []);

  useEffect(() => {
    api
      ?.get('user', { params: { user_id: memberId } })
      .then(function (response) {
        const selectedDep = response?.data?.user_departments?.map(function (
          value
        ) {
          return value?.id;
        });
        form?.setFieldsValue({
          name: response?.data?.name,
          email: response?.data?.email,
          job_title: response?.data?.job_title,
          role: response?.data?.role,
          department_id: selectedDep
        });
        setLoadingEdit(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tagRender = (props) => {
    const { label } = props;
    return (
      <Tag className="department-select-tag">
        <Badge
          status={
            label === 'Developer' || label === 'Marketing' ? 'error' : 'success'
          }
          text={label}
        />
      </Tag>
    );
  };

  return (
    <>
      <AntModal
        title="Edit Member"
        visible={visible}
        onOk={form?.submit}
        okText="Save"
        onCancel={() => {
          onCancel();
        }}
        footer="default"
        closeIcon={<img src={CloseIcon} alt="close" />}
        destroyOnClose
        content={
          <Spin direction="horizontal" spinning={loadingEdit}>
            <Form
              form={form}
              name="basic"
              onFinish={onFinish}
              autoComplete="off"
              layout="vertical"
              requiredMark={false}
              preserve={false}
            >
              <Row>
                <Col span={16}>
                  <AntInput label="Name" placeholder="Joe rio" name="name" />
                </Col>
                <Col span={7} offset={1}>
                  <AntInput
                    label="Job title"
                    placeholder="Software engineer"
                    name="job_title"
                  />
                </Col>
              </Row>
              <Row>
                <Col span={16}>
                  <div id="invite_modal_emails">
                    <AntInput
                      className="invite_modal_emails"
                      label="Email id(s)"
                      disabled
                      name="email"
                      placeholder="Enter Email id"
                      rules={[
                        {
                          required: true,
                          type: 'email',
                          message: 'Please input email!'
                        }
                      ]}
                    />
                  </div>
                </Col>
                <Col span={7} offset={1}>
                  <AntSelect
                    options={[
                      { name: 'Admin', value: 'ADMIN' },
                      { name: 'Manager', value: 'MANAGER' },
                      { name: 'User', value: 'USER' }
                    ]}
                    label="Select Role"
                    name="role"
                    placeholder="Eg. Software engineer"
                    rules={[
                      {
                        required: true,
                        message: 'Please input job title!'
                      }
                    ]}
                  />
                </Col>
              </Row>
              <Form.Item
                label="Department"
                name="department_id"
                rules={[
                  {
                    required: true,
                    message: 'Please select department!'
                  }
                ]}
              >
                <TreeSelect
                  size="large"
                  allowClear
                  tagRender={tagRender}
                  treeData={departments}
                  treeCheckable="true"
                  placeholder="Select Department"
                />
              </Form.Item>
            </Form>
          </Spin>
        }
      />
    </>
  );
};

export default EditMemberModal;
