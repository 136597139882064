import { PlusOutlined } from '@ant-design/icons';
import { Col, Form, notification, Row, Space, Typography, Upload } from 'antd';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Edit, RightArrow } from '../../../assets';
import { ROUTES } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import AntButton from '../../../components/AntButton';
import AntCard from '../../../components/AntCard';
import AntInput from '../../../components/AntInput';
import { UserDetailsContext } from '../../../context/UserDetailsProvider';
import api from '../../../services/api';
import { EditIcon } from '../AntStyled';

const { Text } = Typography;

const { alphabetsNumberSpace } = formValidatorRules;
const CreateCompany = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [imageUrl, setImageUrl] = useState('');
  const [ImageSrc, setImageSrc] = useState('');
  const [loading, setLoading] = useState(false);
  const { state, dispatch } = useContext(UserDetailsContext);
  const user = JSON?.parse(state?.user);

  const onFinish = (values) => {
    setLoading(true);
    // eslint-disable-next-line no-undef
    const formData = new FormData();
    if (values?.avatar) {
      formData?.append('logo', ImageSrc);
    }
    formData?.append('name', values?.name);
    api
      ?.post('companies', formData)
      .then(function (response) {
        notification?.success({
          message: 'Success',
          description: response?.message
        });
        const userData = user;
        userData.company = response?.data;
        dispatch({
          type: 'UPDATE_USER',
          payload: userData
        });
        setLoading(false);
        navigate(`${ROUTES?.ONBOARDING}/${ROUTES?.CREATE_DEPARTMENT}`);
      })
      .catch(function () {
        setLoading(false);
      });
  };

  function getBase64(img, callback) {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader?.addEventListener('load', () => callback(reader.result));
    reader?.readAsDataURL(img);
  }

  const handleChange = (info) => {
    const isLt1M = info?.file?.size / 1024 / 1024 < 1;
    if (!isLt1M) {
      notification.error({
        message: 'Error',
        description: 'Image must be less than 1MB!'
      });
      return false;
    }
    getBase64(info?.file, (base64String) => {
      setImageUrl(base64String);
      setImageSrc(info?.file);
    });
    return true;
  };

  const uploadButton = (
    <div>
      <PlusOutlined color="red" />
    </div>
  );

  return (
    <div className="wrapper">
      <Typography.Title className="title heading mb-0">
        Create Company
      </Typography.Title>
      <Text className="sub-title">
        Create a company on OKRboard to invite <br />
        members and track your company’s progress.
      </Text>
      <AntCard bordered padding={16}>
        <Form
          form={form}
          name="basic"
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          requiredMark={false}
        >
          <Row align="middle">
            <Col>
              <Form.Item
                name="avatar"
                className="mb-0"
                rules={[
                  {
                    required: true,
                    message: 'Please select company logo!'
                  }
                ]}
              >
                <div id="company_img">
                  <Space align="center" className="company-logo-upload">
                    <Upload
                      listType="picture-card"
                      className="radius-8 company-logo"
                      showUploadList={false}
                      onChange={handleChange}
                      beforeUpload={() => {
                        return false;
                      }}
                    >
                      {imageUrl ? (
                        <>
                          <img
                            src={imageUrl}
                            alt="avatar"
                            className="width-100 radius-8"
                          />
                          <EditIcon>
                            <img
                              src={Edit}
                              alt="avatar"
                              className="edit-icon"
                            />
                          </EditIcon>
                        </>
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                    <div>Company Logo</div>
                  </Space>
                </div>
              </Form.Item>
            </Col>
          </Row>
          <br />
          <AntInput
            label="Unique Company Name"
            name="name"
            placeholder="Company Name"
            rules={[
              {
                required: true,
                message: 'Please input your company name.'
              },
              alphabetsNumberSpace
            ]}
          />

          <AntButton type="primary" htmlType="submit" block loading={loading}>
            Continue <img src={RightArrow} alt="" />
          </AntButton>
        </Form>
      </AntCard>
    </div>
  );
};

export default CreateCompany;
