import { Layout, Typography } from 'antd';
import styled from 'styled-components';
import Colors from '../../design/Colors';

const { Content: AntContent } = Layout;
const { Text } = Typography;

export const Content = styled(AntContent)`
  .header-tab {
    display: inline-flex;
    margin-left: 24px;
    .ant-tabs-nav {
      margin-bottom: 0px;
    }
    .ant-tabs {
      display: inline-flex;
      margin-left: 24px;
    }
    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0 0 0 8px;
    }
    .ant-tabs-tab {
      padding: 8px 16px;
    }
    .ant-tabs-tab.ant-tabs-tab-active {
      padding: 8px 16px;
      font-size: 14px;
      background: ${Colors?.BackgroundLight};
      border-radius: 8px;
    }
    .ant-tabs-ink-bar {
      display: none;
    }
  }
  .logo {
    color: #f56a00;
    cursor: pointer;
    width: auto;
    padding: 4px 24px 4px 0px;
    border-right: 1px solid ${Colors?.Stroke};
    height: 40px;
  }
  .header {
    border-bottom: 1px solid ${Colors?.Stroke};
    padding: 0px 16px;
    height: 64px;
  }
  .v-border {
    border-left: 1px solid ${Colors?.Stroke};
    height: 40px;
    margin: 0px 12px;
  }
`;
export const Body = styled(AntContent)`
  overflow: hidden;
  background: ${Colors?.Background};
`;
export const SubText = styled(Text)`
  white-space: pre-line;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${Colors?.Text};
  max-width: 348px;
  display: block;
  .sub-text {
    color: ${Colors?.Accent};
  }
`;
