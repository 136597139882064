import React, { createContext, useReducer } from 'react';

// create a context, with createContext api
export const UserDetailsContext = createContext();

const initialState = {
  // eslint-disable-next-line no-undef
  isAuthenticated: !!localStorage?.getItem('user'),
  // eslint-disable-next-line no-undef
  user: localStorage?.getItem('user') || null,
  // eslint-disable-next-line no-undef
  token: localStorage?.getItem('token') || null
};
const reducer = (state, action) => {
  switch (action?.type) {
    case 'LOGIN':
      // eslint-disable-next-line no-undef
      localStorage?.setItem('user', JSON?.stringify(action?.payload?.user));
      // eslint-disable-next-line no-undef
      localStorage?.setItem('token', action?.payload?.token);
      return {
        ...state,
        isAuthenticated: true,
        user: JSON?.stringify(action?.payload?.user),
        token: action?.payload?.token
      };
    case 'UPDATE_USER':
      // eslint-disable-next-line no-undef
      localStorage?.setItem('user', JSON?.stringify(action?.payload));
      return {
        ...state,
        isAuthenticated: true,
        user: JSON?.stringify(action?.payload)
      };
    case 'LOGOUT':
      // eslint-disable-next-line no-undef
      localStorage?.clear();
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    default:
      return state;
  }
};

const UserDetailsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    // this is the provider providing state
    <UserDetailsContext.Provider
      value={{
        state,
        dispatch
      }}
    >
      {
        // eslint-disable-next-line react/destructuring-assignment
        children
      }
    </UserDetailsContext.Provider>
  );
};

export default UserDetailsProvider;
