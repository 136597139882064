import { Modal as AntModal } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Colors from '../design/Colors';

const Modal = styled(AntModal)`
  .ant-modal-body {
    padding-bottom: 0px;
  }
  .ant-modal-header {
    border-radius: 8px 8px 0px 0px;
    padding: 16px 16px;
    border-bottom: 1px solid ${Colors?.Stroke};
  }
  .ant-modal-footer {
    padding: 24px;
    border-top: 0px;
    .ant-btn {
      padding: 8px 16px;
      height: unset;
    }
  }
  .ant-modal-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: ${Colors?.Primary};
  }
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-form-item-label > label {
    color: ${Colors?.Primary};
  }

  &.profile-modal {
    .ant-modal-body {
      padding: 0px;
    }
    .profile-bg {
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 8px 8px 0px 0px;
    }
    .ant-modal-close-x {
      visibility: hidden;
    }
    .placeholder-img {
      border: 4px solid ${Colors?.WhiteColor};
      width: 80px;
      height: 80px;
      border-radius: 50%;
      object-fit: cover;
    }
    .bg-img {
      position: relative;
      height: 216px;
    }
    .upload-bg {
      position: absolute;
      display: flex;
      align-items: flex-end;
      left: 0;
      bottom: 0;
      z-index: 10;
      padding-left: 32px;
      gap: 16px;
      .name-title {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: ${Colors?.Primary};
      }
      .name-des {
        font-size: 12px;
        line-height: 18px;
        color: ${Colors?.Text};
      }
    }
  }
  .delete-modal {
    width: 420px;
    .ant-modal-content {
      padding: 12px;
    }
  }
`;

// eslint-disable-next-line react/display-name
export default function (props) {
  const {
    closeModal,
    closable = true,
    content,
    footer,
    height,
    maxHeight,
    title,
    width,
    onOk,
    className,
    bordered,
    visible = true,
    ...restProps
  } = props;
  const [open, setOpen] = useState(visible);

  useEffect(() => {
    if (visible) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [visible]);

  const handleCancel = () => {
    setOpen(false);
    setTimeout(() => {
      closeModal();
    }, 1000);
  };

  return (
    <Modal
      destroyOnClose
      className={className}
      title={title}
      visible={open}
      onCancel={closable && handleCancel}
      closable={closable}
      onOk={onOk}
      width={width}
      height={height}
      maxHeight={maxHeight}
      bordered={bordered}
      footerPadding={!!footer}
      footer={footer === 'default' ? undefined : [footer]}
      {...restProps}
    >
      {open ? content : null}
    </Modal>
  );
}
