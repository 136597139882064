import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import { UserDetailsContext } from '../../../context/UserDetailsProvider';

const Logout = () => {
  const { dispatch } = useContext(UserDetailsContext);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: 'LOGOUT' });
    navigate(ROUTES?.LOGIN);
    // eslint-disable-next-line
  }, []);

  return <div />;
};

export default Logout;
