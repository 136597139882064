import {
  Col,
  Form,
  List,
  notification,
  Pagination,
  Popconfirm,
  Row,
  Table
} from 'antd';
import { debounce, isEmpty } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { DeleteIcon, SearchIcon } from '../../assets';
import AccessRole from '../../common/AccessRole';
import { USERS_ROLE } from '../../common/constants';
import { HasAccess } from '../../common/utils';
import AntAvatar from '../../components/AntAvatar';
import AntButton from '../../components/AntButton';
import AntCard from '../../components/AntCard';
import AntInput from '../../components/AntInput';
import AntSelect from '../../components/AntSelect';
import AddDepartment from '../../components/Shared/modals/AddDepartment';
import AddMembersInDepartment from '../../components/Shared/modals/AddMembersInDepartment';
import DeleteDepartmentModal from '../../components/Shared/modals/DeleteDepartment';
import { UserDetailsContext } from '../../context/UserDetailsProvider';
import api from '../../services/api';

function DepartmentsSettings() {
  const [isDepModelVisible, setIsDepModelVisible] = useState(false);
  const [departmentId, setDepartmentId] = useState('');
  const [departments, setDepartments] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [members, setMembers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [dropdownDepartmentId, setDropdownDepartmentId] = useState('');
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [form] = Form?.useForm();
  const [addMemberInDepartment, setAddMemberInDepartment] = useState(false);
  const { state } = useContext(UserDetailsContext);
  const user = JSON?.parse(state?.user);

  const companyMembers = (deptId, pageN = '', searchValue) => {
    form?.setFieldsValue({ department_name: deptId });
    setLoading(true);
    if (deptId) {
      api
        ?.get('company-members', {
          params: {
            search: searchValue,
            per_page: 5,
            page: pageN ?? currentPage,
            department_id: deptId
          }
        })
        .then(function (response) {
          const data = [];
          setTotalPages(response?.data?.last_page);
          response?.data?.data?.forEach((r) => {
            data?.push({
              key: r?.id,
              name: r?.name,
              email: r?.email,
              invited_name: r?.invited_by?.email,
              job_title: r?.job_title,
              role: r?.role,
              is_invited: r?.is_invited,
              image: r?.image
            });
          });
          setMembers(data);
          setLoading(false);
        })
        .catch(function () {});
    } else {
      setMembers([]);
      setLoading(false);
    }
  };

  const departmentList = (value) => {
    /* Get departments data */
    api
      ?.get('departments', {
        params: { is_from_setting_page: true, search: searchTerm }
      })
      .then(function (response) {
        const data = [];
        response?.data?.forEach((r) => {
          data?.push({
            value: r?.id,
            name: r?.name ?? r?.parent_department?.name
          });
          if (r?.name === value) {
            setDropdownDepartmentId(r?.id);
            companyMembers(r?.id);
          }
        });
        setDepartments(data);
        if (!value) {
          setDropdownDepartmentId(data?.[0]?.value);
          companyMembers(data?.[0]?.value);
        }
      });
  };

  const onChangeDepartment = (value) => {
    setDropdownDepartmentId(value);
    companyMembers(value);
  };

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    companyMembers(dropdownDepartmentId, pageNumber);
  };

  useEffect(() => {
    departmentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMemberDeleteClick = (event) => {
    api
      ?.post('remove-members', {
        department_id: dropdownDepartmentId,
        members: event?.key
      })
      .then(function (response) {
        setCurrentPage(1);
        companyMembers(dropdownDepartmentId);
        notification?.success?.({
          message: 'Success',
          description: response?.message
        });
      });
  };

  const columns = [
    {
      title: 'Members',
      dataIndex: 'email',
      key: 'email',
      render: (text, record) => (
        <div>
          <List.Item>
            <List.Item.Meta
              avatar={
                <AntAvatar
                  imgSrc={record?.image}
                  userId={record?.key}
                  firstChar={
                    record?.name
                      ? record?.name?.charAt(0)
                      : record?.email?.charAt(0)
                  }
                />
              }
              title={
                record?.is_invited === 1 ? (
                  <div className="invited_label">Invited</div>
                ) : (
                  record?.name
                )
              }
              description={text}
            />
          </List.Item>
        </div>
      )
    },
    {
      title: 'Job Title',
      dataIndex: 'job_title',
      key: 'job_title'
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role'
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => {
        if (
          user?.id !== record?.key &&
          record?.role !== USERS_ROLE?.OWNER &&
          isEmpty(record?.invited_name)
        ) {
          return (
            <Popconfirm
              title="Are you sure to delete member from department?"
              onConfirm={() => handleMemberDeleteClick(record)}
              okText="Yes"
              cancelText="No"
            >
              <img width="18px" src={DeleteIcon} alt="icon" />
            </Popconfirm>
          );
        }
      }
    }
  ]?.filter((item) => {
    if (
      HasAccess({ roles: [USERS_ROLE?.USER] }) &&
      item?.dataIndex === 'action'
    ) {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const handleSearch = (e) => {
    const value = e?.target?.value;
    setSearchTerm(value);
    companyMembers(currentPage, value);
  };

  const searchDebounce = debounce(handleSearch, 1000);

  const getSelectedName = () => {
    const row = departments?.find((rowRecord) => {
      return rowRecord?.value === dropdownDepartmentId;
    });
    return row?.name;
  };

  const deleteDepartment = () => {
    setIsDeleteVisible(true);
  };

  const handleDeleteDepartment = () => {
    api
      ?.delete(`departments/${dropdownDepartmentId}`)
      .then(function (response) {
        notification?.success?.({
          message: 'Success',
          description: response?.message
        });
        departmentList();
      });
  };

  return (
    <>
      <Form form={form} layout="vertical">
        <Row justify="space-between" align="middle" gutter={18}>
          <Col span={6}>
            <AntSelect
              form={form}
              label="Select department"
              name="department_name"
              options={departments}
              placeholder="Select Department"
              className="mb-0 company-setting-department-select"
              onChange={onChangeDepartment}
              loading={loading}
            />
          </Col>
          <AccessRole
            allowedRoles={[
              USERS_ROLE?.OWNER,
              USERS_ROLE?.ADMIN,
              USERS_ROLE?.MANAGER
            ]}
          >
            <Col className="mt-16">
              <AntButton
                onClick={() => {
                  setDepartmentId('');
                  setIsDepModelVisible(true);
                }}
                type="primary"
              >
                + Create Department
              </AntButton>
              {isDepModelVisible ? (
                <AddDepartment
                  visible={isDepModelVisible}
                  departmentId={departmentId}
                  onCancel={() => {
                    setIsDepModelVisible(false);
                  }}
                  onSubmit={(value) => {
                    setIsDepModelVisible(false);
                    departmentList(value);
                  }}
                />
              ) : (
                ''
              )}
            </Col>
          </AccessRole>
        </Row>
      </Form>
      <AntCard
        title={
          <Form>
            <Row justify="space-between" align="middle">
              <Col className="flex-center">
                <span className="header-text">
                  {getSelectedName()?.length > 20
                    ? `${getSelectedName()?.substring(0, 20)}...`
                    : getSelectedName()}
                </span>
                <AntInput
                  name="search"
                  placeholder="Search members"
                  prefix={<img width="18px" src={SearchIcon} alt="S_icon" />}
                  autoComplete="off"
                  allowClear
                  className="mb-0"
                  onChange={searchDebounce}
                />
              </Col>
              <AccessRole
                allowedRoles={[
                  USERS_ROLE?.OWNER,
                  USERS_ROLE?.ADMIN,
                  USERS_ROLE?.MANAGER
                ]}
              >
                {dropdownDepartmentId && getSelectedName() ? (
                  <Col>
                    <AntButton
                      className="btn-outline"
                      onClick={() => {
                        setAddMemberInDepartment(true);
                      }}
                    >
                      + Add members
                    </AntButton>
                  </Col>
                ) : (
                  ''
                )}
              </AccessRole>
            </Row>
          </Form>
        }
      >
        <Table
          className="department-members-list"
          pagination={false}
          columns={columns}
          dataSource={members}
          loading={loading}
        />
      </AntCard>
      <Pagination
        className="mt-16"
        onChange={onPageChange}
        current={currentPage}
        defaultCurrent={1}
        defaultPageSize={5}
        total={totalPages * 5}
      />
      <AccessRole
        allowedRoles={[
          USERS_ROLE?.OWNER,
          USERS_ROLE?.ADMIN,
          USERS_ROLE?.MANAGER
        ]}
      >
        {dropdownDepartmentId && getSelectedName() ? (
          <Row className="text-warning mt-16" align="middle">
            <Col>
              <Popconfirm
                title={`Are you sure you want to delete ${getSelectedName()} department?`}
                onConfirm={deleteDepartment}
                okText="Yes"
                cancelText="No"
              >
                <div className="flex-center">
                  <img src={DeleteIcon} alt="" /> Delete&nbsp;
                  {getSelectedName()?.length > 20
                    ? `${getSelectedName()?.substring(0, 20)}...`
                    : getSelectedName()}
                  &nbsp;Department
                </div>
              </Popconfirm>
            </Col>
          </Row>
        ) : (
          ''
        )}
      </AccessRole>
      {addMemberInDepartment && (
        <AddMembersInDepartment
          visible={addMemberInDepartment}
          onCancel={() => {
            setAddMemberInDepartment(false);
          }}
          departmentId={dropdownDepartmentId}
          departmentName={getSelectedName()}
          onSubmit={() => {
            setAddMemberInDepartment(false);
            companyMembers(dropdownDepartmentId);
          }}
        />
      )}
      <DeleteDepartmentModal
        visible={isDeleteVisible}
        departmentName={getSelectedName()}
        onSub={() => {
          setIsDeleteVisible(false);
          handleDeleteDepartment();
        }}
        onCancel={() => {
          setIsDeleteVisible(false);
        }}
      />
    </>
  );
}

export default DepartmentsSettings;
