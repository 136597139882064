import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Form, Row } from 'antd';
import React from 'react';
import { CloseIcon } from '../../../assets';
import AntModal from '../../AntModal';

const DeleteDepartmentModal = ({
  visible,
  onSub,
  departmentName,
  onCancel
}) => {
  const [form] = Form?.useForm();

  const onFinish = () => {
    onSub();
  };

  return (
    <AntModal
      width={500}
      title={
        <>
          <InfoCircleOutlined />
          <span className="delete-title-text">
            {`Delete
            ${
              departmentName?.length > 15
                ? `${departmentName?.substring(0, 15)}...`
                : departmentName
            }
            Department`}
          </span>
        </>
      }
      className="delete-company-modal delete-modal"
      visible={visible}
      onOk={form?.submit}
      okText="Yes, delete"
      onCancel={onCancel}
      footer="default"
      closeIcon={<img src={CloseIcon} alt="close" />}
      destroyOnClose
      content={
        <Form
          form={form}
          name="basic"
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          requiredMark={false}
          preserve={false}
        >
          <Row>
            <Col span={24}>
              <Form.Item>
                <div className="delete-modal-text">
                  <p>
                    {'Are you sure you want to delete this '}
                    <strong>{departmentName} </strong>
                    Department?
                  </p>
                  <p>
                    {
                      'And with that, all the Objective(s) also be deleted from '
                    }
                    <strong>{departmentName} </strong>
                    department.
                  </p>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      }
    />
  );
};

export default DeleteDepartmentModal;
