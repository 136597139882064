import React, { useContext } from 'react';
import { UserDetailsContext } from '../context/UserDetailsProvider';

const AccessRole = ({ children, allowedRoles }) => {
  const { state } = useContext(UserDetailsContext);
  const user = JSON?.parse(state?.user);

  if (allowedRoles?.includes(user?.role)) {
    return <div>{children}</div>;
  }
};

export default AccessRole;
