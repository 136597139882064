const Colors = {
  Primary: '#08011C',
  Accent: '#3452EC',
  Text: '#514F55',
  Background: '#F2F4F4',
  BackgroundLight: '#F0F1F9',
  Stroke: '#D2D2E1',
  WhiteColor: '#fff',
  // other colors
  Warning: '#EABF27',
  Success: '#3BB665',
  Danger: '#E64E2C'
};

export default Colors;
