import { Col, Form, notification, Row, Typography } from 'antd';
import { isEmpty } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { CloseIcon } from '../../../assets';
import { PROGRESS_TYPE, USERS_ROLE } from '../../../common/constants';
import { formValidatorRules, HasAccess } from '../../../common/utils';
import { UserDetailsContext } from '../../../context/UserDetailsProvider';
import api from '../../../services/api';
import AntInput from '../../AntInput';
import AntModal from '../../AntModal';
import AntSelect from '../../AntSelect';
import AntTextArea from '../../AntTextArea';

const { Text } = Typography;
const { onlyNumber } = formValidatorRules;
const KeyResultModal = ({
  visible,
  onCancel,
  ketResultId = '',
  objectiveId = '',
  onSub
}) => {
  const [form] = Form?.useForm();
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [showDes, setShowDes] = useState(false);
  const [isCustomUnit, setIsCustomUnit] = useState(false);
  const [isDefaultPer, setIsDefaultPer] = useState(true);
  const [isCheckbox, setIsCheckbox] = useState(false);
  const [IsVisible, setIsVisible] = useState(false);
  const [users, setUsers] = useState([]);
  const { state } = useContext(UserDetailsContext);
  const user = JSON?.parse(state?.user);
  const customUnit = Form?.useWatch('custom_unit_name', form);

  const handleChange = (value) => {
    setIsDefaultPer(value === 'DEFAULT_PROGRESS');
    setIsCustomUnit(value === 'CUSTOM_UNIT');
    setIsCheckbox(value === 'CHECKBOX');
  };

  const onFinish = (values) => {
    const data = {
      ...values,
      objective_id: objectiveId,
      start: values?.start ?? 0,
      end: values?.end ?? 100,
      ...(ketResultId && { id: ketResultId })
    };
    setConfirmLoading(true);
    if (
      !(Number(data?.start) < Number(data?.end)) &&
      !(Number(data?.end) > Number(data?.start))
    ) {
      notification?.error?.({
        message: 'Error',
        description: 'Please enter correct value!'
      });
      setConfirmLoading(false);
    } else {
      api
        ?.post('key-results', data)
        .then(function (response) {
          notification?.success({
            message: 'Success',
            description: response?.message
          });
          onSub();
          setConfirmLoading(false);
        })
        .catch(function () {
          setConfirmLoading(false);
        });
    }
  };

  const getKeyResult = () => {
    api?.get(`key-results/${ketResultId}`).then(function (response) {
      const obj = pick(response?.data, [
        'id',
        'name',
        'description',
        'owner_id',
        'progress_type',
        'custom_unit_name',
        'start',
        'end'
      ]);
      handleChange(obj?.progress_type);
      form?.setFieldsValue(obj);
      if (obj?.description) setShowDes(true);
      setIsVisible(true);
    });
  };

  useEffect(() => {
    if (visible && ketResultId) {
      getKeyResult();
    } else {
      setIsVisible(visible);
      setShowDes(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  function pick(object, keys) {
    return keys?.reduce((obj, key) => {
      // eslint-disable-next-line no-prototype-builtins
      if (object?.hasOwnProperty(key)) {
        // eslint-disable-next-line no-param-reassign
        obj[key] = object?.[key];
      }
      return obj;
    }, {});
  }

  useEffect(() => {
    if (visible === true) {
      /* Get all users */
      api?.get('users').then(function (response) {
        const getUsers = [];
        response?.data?.data?.forEach((r) => {
          getUsers?.push({
            name: r?.name,
            value: r?.id
          });
        });
        setUsers(getUsers);
      });
    }
    if (isEmpty(ketResultId)) {
      setIsCustomUnit(false);
      setIsDefaultPer(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    if (visible === true) {
      if (
        user?.role === USERS_ROLE?.USER ||
        user?.role === USERS_ROLE?.ADMIN ||
        user?.role === USERS_ROLE?.MANAGER
      ) {
        form?.setFieldsValue({ owner_id: user?.id });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, visible]);

  const isOnCancel = () => {
    setIsVisible(false);
    onCancel();
  };

  return (
    <AntModal
      title={ketResultId ? 'Edit Key Result' : 'Create Key Result'}
      visible={IsVisible}
      onOk={form?.submit}
      confirmLoading={confirmLoading}
      okText={ketResultId ? 'Update' : 'Create'}
      onCancel={isOnCancel}
      destroyOnClose
      footer="default"
      closeIcon={<img src={CloseIcon} alt="close" />}
      content={
        <Form
          form={form}
          name="keyresult-form"
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          requiredMark={false}
          preserve={false}
        >
          <AntInput
            label="What do you want to achieve?"
            placeholder="Key Result"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input key result name!'
              }
            ]}
            className="mb-0 mb-4"
          />

          {showDes ? (
            <AntTextArea
              placeholder="Description"
              name="description"
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          ) : (
            <Text
              name="description"
              onClick={() => {
                setShowDes(!showDes);
              }}
              className="accent mb-24 block"
            >
              + Add Description
            </Text>
          )}
          <AntSelect
            options={users}
            disabled={HasAccess({
              roles: [USERS_ROLE?.USER, USERS_ROLE?.ADMIN, USERS_ROLE?.MANAGER]
            })}
            label="Owner"
            name="owner_id"
            placeholder="Select Owner"
            rules={[{ required: true, message: 'Please select owner' }]}
            className="mb-0"
          />
          <Text className="text-small mb-24 block">
            Owner will be able to check-in with the progress in Key Results
            assigned to them.
          </Text>

          <Row justify="space-between">
            <Col className={isCustomUnit ? 'width-45' : 'width-100'}>
              <AntSelect
                options={PROGRESS_TYPE}
                label="Progress"
                placeholder="Select Objective Type"
                onChange={handleChange}
                name="progress_type"
                rules={[
                  {
                    required: true,
                    message: 'Please select progress type'
                  }
                ]}
                className="mb-0"
              />
            </Col>
            {isCustomUnit && (
              <Col className="width-45">
                <AntInput
                  label="Unit"
                  maxLength="10"
                  placeholder="Enter custom unit (eg. hours, $)"
                  name="custom_unit_name"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter unit!'
                    }
                  ]}
                />
              </Col>
            )}
          </Row>

          {!isDefaultPer && !isCheckbox ? (
            <Row justify="space-between">
              <Col className="width-45">
                <AntInput
                  label="Start"
                  placeholder="0"
                  name="start"
                  maxLength="3"
                  dependencies={['end']}
                  rules={[
                    {
                      required: true,
                      message: 'Please Input Number!'
                    },
                    onlyNumber,
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (Number(value) < Number(getFieldValue(['end']))) {
                          return Promise?.resolve();
                        }
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise?.reject(
                          'Start value should be less than end value!'
                        );
                      }
                    })
                  ]}
                  suffix={
                    <Text type="secondary">
                      {isCustomUnit ? customUnit : '%'}
                    </Text>
                  }
                />
              </Col>
              <Col className="width-45">
                <AntInput
                  label="End"
                  placeholder="100"
                  name="end"
                  maxLength="3"
                  dependencies={['start']}
                  rules={[
                    {
                      required: true,
                      message: 'Please Input Number!'
                    },
                    onlyNumber,
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (Number(value) > Number(getFieldValue(['start']))) {
                          return Promise?.resolve();
                        }
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise?.reject(
                          'End value should be greater than start value!'
                        );
                      }
                    })
                  ]}
                  suffix={
                    <Text type="secondary">
                      {isCustomUnit ? customUnit : '%'}
                    </Text>
                  }
                />
              </Col>
            </Row>
          ) : (
            !isCheckbox && (
              <Text className="text-small">
                Default progress is from 0 to 100%.
              </Text>
            )
          )}
        </Form>
      }
    />
  );
};

export default KeyResultModal;
