import { Col, Form, notification, Popover, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import api from '../services/api';
import AntButton from './AntButton';
import AntInput from './AntInput';
import AntSelect from './AntSelect';
import AntTextArea from './AntTextArea';

const { Text } = Typography;

const CustomPopOver = styled(Popover)``;

const GlobalStyle = createGlobalStyle`
  	border-radius: 8px;
    margin-bottom: 16px;
	.ant-popover-inner{
		   border-radius: 8px;
	}
	.ant-popover-inner-content{
		padding: 24px 16px
	}
`;

const AntFooterRow = styled(Row)`
  margin-top: 8px;
  gap: 20px;
`;

const AntPopOver = ({
  keyResultRow,
  isVisible = false,
  children,
  onSubmit,
  onClose,
  className,
  ...rest
}) => {
  const [keyResult, setKeyResult] = useState({});
  const [form] = Form?.useForm();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setKeyResult(keyResultRow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyResultRow]);

  useEffect(() => {
    setVisible(isVisible);
  }, [isVisible]);

  const hide = () => {
    setKeyResult({});
    onClose();
    setVisible(false);
  };

  const onFinish = (values) => {
    const data = {
      ...values,
      key_result_id: keyResult.id,
      objective_id: keyResult.objective_id
    };
    api?.post(`/check-in`, data).then(function (response) {
      onSubmit();
      notification?.success?.({
        message: 'Success',
        description: response?.message
      });
    });
  };

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
    onClose();
  };

  const initialValues = {
    current_progress: keyResult?.temp_val,
    status: keyResult?.check_in?.status
  };

  useEffect(() => {
    if (initialValues) {
      form?.setFieldsValue({
        current_progress: initialValues?.current_progress,
        status: initialValues?.status
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const content = (
    <Form
      form={form}
      layout="vertical"
      name="basic"
      onFinish={onFinish}
      initialValues={initialValues}
      requiredMark={false}
    >
      <Row justify="space-between" align="middle">
        <Col span={9}>
          <div>
            <AntInput
              max="100"
              label="Current progress %"
              placeholder="%"
              name="current_progress"
              rules={[
                {
                  required: true,
                  message: 'Progress not be empty'
                }
              ]}
            />
          </div>
        </Col>
        <Col span={6}>
          <div className="units">
            {`of ${
              keyResult?.custom_unit_name
                ? `${keyResult?.end} ${keyResult?.custom_unit_name}`
                : `${keyResult?.end}%`
            }`}
          </div>
        </Col>
        <Col span={9}>
          <AntSelect
            options={[
              {
                name: 'On track',
                value: 'ON_TRACK',
                icon: 'success'
              },
              {
                name: 'At risk',
                value: 'AT_RISK',
                icon: 'error'
              },
              {
                name: 'Behind',
                value: 'BEHIND',
                icon: 'warning'
              }
            ]}
            label="Status"
            name="status"
            placeholder="Select status"
            rules={[
              {
                required: true,
                message: 'Please select progress type '
              }
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <AntTextArea
            name="note"
            placeholder="Check in note"
            label="Note (optional)"
            rows={5}
            className="mt-8"
          />
        </Col>
      </Row>
      <AntFooterRow align="middle" justify="end">
        <Col onClick={hide}>
          <Text size="large">Cancel</Text>
        </Col>
        <Col>
          <AntButton type="primary" htmlType="submit" block size="large">
            Check in
          </AntButton>
        </Col>
      </AntFooterRow>
    </Form>
  );

  return (
    <Form form={form} className="pop contents">
      <GlobalStyle />
      <CustomPopOver
        destroyTooltipOnHide
        trigger="click"
        content={content}
        title=""
        visible={visible}
        onVisibleChange={handleVisibleChange}
        className={className}
        overlayClassName="check-in-popover"
        {...rest}
      >
        {children}
      </CustomPopOver>
    </Form>
  );
};

export default AntPopOver;
