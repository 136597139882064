import { ArrowLeftOutlined } from '@ant-design/icons';
import {
  Checkbox,
  Col,
  Form,
  Row,
  Skeleton,
  Space,
  Typography,
  notification
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { EnterIcon, RightArrow } from '../../../assets';
import { ROUTES } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import AntButton from '../../../components/AntButton';
import AntCard from '../../../components/AntCard';
import AntInput from '../../../components/AntInput';
import api from '../../../services/api';

const { Text } = Typography;

const { onlyAlphabets, atleastOneCheckbox } = formValidatorRules;
const CreateDepartments = () => {
  const navigate = useNavigate();
  const [isInputVisible, setIsInputVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [checkedDep, setCheckedDep] = useState([]);

  useEffect(() => {
    api?.get('departments').then(function (response) {
      setDepartments(response?.data);
    });
  }, []);

  const [form] = Form?.useForm();

  const ToggleInp = (e) => {
    const dept =
      e?.target?.value?.charAt(0)?.toUpperCase() + e?.target?.value?.slice(1);
    const res = departments?.filter((obj) => obj?.name === dept);
    if (res?.length > 0) {
      notification?.error({
        message: 'Error',
        description: 'The Department Name already exist!'
      });
    } else {
      form?.setFieldsValue({ department_name: '' });
      setIsInputVisible(false);
      if (e?.target?.value) {
        setDepartments([
          ...departments,
          {
            id: '',
            name: e?.target?.value
          }
        ]);
      }
    }
  };

  const onFinish = () => {
    setLoading(true);
    const departmentIds = [];
    const names = [];
    checkedDep?.forEach((item) => {
      if (item?.id) {
        departmentIds?.push(item?.id);
      } else {
        names?.push(item?.name);
      }
    });
    api
      ?.post('departments', {
        department_ids: departmentIds,
        names
      })
      .then(function (response) {
        notification?.success({
          message: 'Success',
          description: response?.message
        });
        setLoading(false);
        navigate(`${ROUTES?.ONBOARDING}/${ROUTES?.INVITE_MEMBERS}`);
      })
      .catch(function () {
        setLoading(false);
      });
  };

  const setChecked = (item) => {
    if (checkedDep?.includes(item)) {
      setCheckedDep(checkedDep?.filter((tool) => tool?.name !== item?.name));
    } else {
      setCheckedDep([...checkedDep, item]);
    }
  };

  return (
    <div className="wrapper form-wrapper">
      <Typography.Title className="title heading mb-0 form-wrapper">
        <Space className="btn-wrapper">
          <AntButton
            icon={<ArrowLeftOutlined />}
            shape="circle"
            size="small"
            className="btn-back"
            onClick={() =>
              navigate(`${ROUTES?.ONBOARDING}/${ROUTES?.CREATE_COMPANY}`)
            }
          />
          Departments
        </Space>
      </Typography.Title>
      <Text className="sub-title">
        Select existing department or Create new one
      </Text>
      <Form
        form={form}
        name="basic"
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
      >
        <AntCard bordered padding={16}>
          <Form.Item
            name="department-checkboxes"
            className="department-checkbox"
            rules={[atleastOneCheckbox]}
          >
            <Checkbox.Group>
              {departments?.length > 0 ? (
                departments?.map((item) => {
                  return (
                    <Checkbox
                      key={item?.id || item?.name}
                      onChange={() => setChecked(item)}
                      className="mb-8"
                      value={item?.id || item?.name}
                    >
                      {item?.name}
                    </Checkbox>
                  );
                })
              ) : (
                <Skeleton />
              )}
            </Checkbox.Group>
          </Form.Item>
          {isInputVisible ? (
            <>
              <AntInput
                name="department_name"
                placeholder="Department Name"
                maxLength="50"
                rules={[
                  {
                    required: true,
                    message: 'Please input your department name.'
                  },
                  onlyAlphabets
                ]}
                onPressEnter={ToggleInp}
              />
              <Row justify="end" align="top">
                <Col className="enter-text">
                  <Text type="secondary">
                    Hit
                    <img src={EnterIcon} alt="" className="inline" />
                    &nbsp; enter to add
                  </Text>
                </Col>
              </Row>
            </>
          ) : (
            <AntButton
              block
              size="large"
              onClick={() => setIsInputVisible(true)}
              className="mt-16"
            >
              + Add Department
            </AntButton>
          )}
        </AntCard>
        <Row justify="space-between" align="middle" className="mt-24">
          <Col>
            <Link to="/onboarding/invite-members" className="skip_onboarding">
              Skip
            </Link>
          </Col>
          <Col>
            <AntButton htmlType="submit" type="primary" loading={loading}>
              Continue <img src={RightArrow} alt="" />
            </AntButton>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default CreateDepartments;
