import { Avatar } from 'antd';
import React from 'react';
import styled from 'styled-components';

const CustomAvatar = styled(Avatar)`
  width: ${({ width }) => width ?? 28}px;
  height: ${({ height }) => height ?? 28}px;
  background: ${({ color }) => color};
  line-height: 28px;
`;
function AntAvatar({ imgSrc = '', firstChar = '', userId = '', ...rest }) {
  // eslint-disable-next-line radix
  const generatedUserId = parseInt(userId)?.toString()?.charAt(0) ?? 0;
  let color;
  switch (generatedUserId) {
    case '0':
      color = '#173F5F';
      break;
    case '1':
      color = '#1F639C';
      break;
    case '2':
      color = '#4DA480';
      break;
    case '3':
      color = '#CB543F';
      break;
    case '4':
      color = '#7046A6';
      break;
    case '5':
      color = '#AB567F';
      break;
    case '6':
      color = '#173F5F';
      break;
    case '7':
      color = '#1F639C';
      break;
    case '8':
      color = '#4DA480';
      break;
    case '9':
      color = '#CB543F';
      break;

    default:
      break;
  }
  return (
    <CustomAvatar src={imgSrc} color={color} {...rest}>
      {firstChar?.toUpperCase()}
    </CustomAvatar>
  );
}

export default AntAvatar;
