import { Layout } from 'antd';
import styled from 'styled-components';
import Colors from '../../design/Colors';

const { Content: AntContent } = Layout;

const Content = styled(AntContent)`
  overflow: auto;
  padding: 24px 16px;
  padding: ${({ padding }) => padding || 0}px;
  background: ${Colors?.Background};
  height: ${({ height }) => height || 'calc(100vh - 128px)'};
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  .card {
    &.ant-card {
      height: calc(100vh - 140px);
      position: relative;
      min-height: 500px;
    }
  }

  .ant-card {
    height: fit-content;
    box-sizing: border-box;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: ${Colors?.WhiteColor};
    }

    &::-webkit-scrollbar-thumb {
      background: #e5e8ed;
      border-radius: 24px;
    }
  }
  .profile-bg {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .placeholder-img {
    border: 4px solid ${Colors?.WhiteColor};
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
  }
  .bg-img {
    position: relative;
    width: 800px;
    height: 250px;
    background: ${Colors?.WhiteColor};
  }
  .upload-bg {
    position: relative;
    top: 0;
    left: 0;
    padding-left: 32px;
    padding-top: 130px;
    .company_logo_setting {
      display: flex;
      align-items: center;
    }
    .name-title {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: ${Colors?.Primary};
    }
  }
  .ant-upload {
    border-radius: 50%;
    img {
      border-radius: 50%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .ant-upload.ant-upload-select-picture-card {
    border: 1px solid ${Colors?.Background};
  }
  .ant-tabs-tab {
    font-size: 16px;
  }
  .tag-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${Colors?.Primary};
    margin-left: 6px;
    display: inline-block;
  }
  .header-text {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: ${Colors?.Primary};
    margin-right: 16px;
  }

  .footer-card {
    position: absolute;
    bottom: 0;
    width: 92%;
    padding: 32px 0px;
  }
  .tab-div {
    background: ${Colors?.WhiteColor};
  }
  .ant-table {
    border-radius: 8px;
  }
  .ant-table-thead {
    .ant-table-cell {
      padding: 8px;
    }
  }
  .ant-table-tbody > tr > td {
    padding: 8px;
    border-bottom: 1px solid ${Colors?.Stroke};
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 8px;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 8px;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }
  .ant-table-thead > tr > th {
    border-bottom: 1px solid ${Colors?.Stroke};
  }
  .ant-table-wrapper tbody > tr:last-child > td {
    border-bottom: none;
  }
  && tbody > tr:hover > td {
    background: ${Colors?.WhiteColor};
    &:first-child {
      background: transparent;
    }
    &:last-child {
      background: transparent;
    }
  }
  .ant-card-head {
    margin-bottom: 0px;
    border-bottom: 1px solid ${Colors?.Stroke};
  }
  .btn-outline {
    &.ant-btn {
      border: 1px solid ${Colors?.Accent};
      color: ${Colors?.Accent};
    }
  }
  .invited_label {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3px 12px;
    gap: 10px;

    width: 75px;
    height: 30px;

    background: #f4f0fd;
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${Colors?.Text};
  }
`;

export default Content;
