import { Col, Row, Typography } from 'antd';
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeft, BackgroundPattern, EmailIcon, Logo } from '../../../assets';
import { ROUTES } from '../../../common/constants';
import AntButton from '../../../components/AntButton';
import AntCard from '../../../components/AntCard';
import Strings from '../../../design/Strings';
import { Content, SubText } from '../AntStyled';

const EmailSent = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const redirectBack = () => {
    navigate(ROUTES?.FORGOT_PASSWORD);
  };

  return (
    <Content className="sign-up-steps">
      <div className="logo-wrapper">
        <Link to={ROUTES?.MAIN}>
          <img className="logo" src={Logo} alt="" />
        </Link>
      </div>
      <Row>
        <Col flex="auto" className="form-col">
          <Content>
            <div className="wrapper">
              <AntButton
                className="text-btn"
                type="link"
                block={false}
                onClick={redirectBack}
              >
                <img src={ArrowLeft} alt="" /> &nbsp; Back
              </AntButton>
              <AntCard bordered padding="16px">
                <img src={EmailIcon} alt="" />
                <Typography.Title className="title heading mb-0 mt-16">
                  {Strings?.auth?.emailSent}
                </Typography.Title>
                <SubText className="mt-8">
                  {Strings?.auth?.emailSentDescription} <br />
                  <span className="sub-text">{state?.email}</span>
                  <br />
                </SubText>
                <SubText className="mt-8">{Strings?.auth?.EmailLink}</SubText>
              </AntCard>
            </div>
          </Content>
          <img className="background-pattern" src={BackgroundPattern} alt="" />
        </Col>
      </Row>
    </Content>
  );
};

export default EmailSent;
