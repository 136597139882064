import { Form, Input } from 'antd';
import React from 'react';
import styled from 'styled-components';
import Colors from '../design/Colors';

const FormLabel = styled(Form.Item)`
  margin-bottom: 16px;
`;
const StyledInput = styled(Input)`
  padding: 7px 16px;
  border-radius: 8px;
  &.ant-input {
    font-size: ${({ large }) => (large ? '16px' : '14px')};
  }
  &.ant-input-affix-wrapper > input.ant-input {
    font-size: ${({ large }) => (large ? '16px' : '14px')};
    padding-left: 10px;
    line-height: 24px;
  }
  &.ant-input-affix-wrapper {
    border: 1px solid ${Colors?.Stroke};
  }
`;
const AntInput = ({
  label,
  name,
  type,
  placeholder,
  className,
  rules = [],
  help,
  extra,
  size,
  validateTrigger,
  dependencies,
  onFocus,
  innerRef,
  ...rest
}) => {
  return (
    <>
      <FormLabel
        className={className}
        label={label}
        name={name}
        rules={rules}
        help={help}
        extra={extra}
        validateTrigger={validateTrigger}
        dependencies={dependencies}
      >
        <StyledInput
          id={name}
          name={name}
          type={type}
          size={size}
          placeholder={placeholder}
          className={className}
          ref={innerRef}
          {...rest}
        />
      </FormLabel>
    </>
  );
};

export default AntInput;
