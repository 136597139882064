import { ArrowLeftOutlined } from '@ant-design/icons';
import { Col, Form, notification, Row, Space, Typography } from 'antd';
import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DeleteIcon } from '../../../assets';
import { ROUTES } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import AntButton from '../../../components/AntButton';
import AntCard from '../../../components/AntCard';
import AntInput from '../../../components/AntInput';
import AntSelect from '../../../components/AntSelect';
import api from '../../../services/api';

const { Text } = Typography;

const { email } = formValidatorRules;
const InviteMembers = () => {
  const navigate = useNavigate();
  const [form] = Form?.useForm();
  const [loading, setLoading] = useState(false);
  const emailRef = useRef(null);

  const onFinish = (values) => {
    setLoading(true);
    const filteredArr = values?.invite?.map((item) => ({
      email: item?.email,
      role: item?.role
    }));
    if (filteredArr?.length > 0) {
      api
        ?.post('invite-members', {
          invite: filteredArr
        })
        .then(function (response) {
          notification?.success({
            message: 'Success',
            description: response?.message
          });
          navigate(ROUTES?.MAIN);
          setLoading(false);
          // eslint-disable-next-line no-undef
          localStorage?.removeItem('isSignUp');
        })
        .catch(function () {
          setLoading(false);
        });
    } else {
      notification?.error?.({
        message: 'Error',
        description: 'To invite member please fill the input box!'
      });
      setLoading(false);
    }
  };

  return (
    <div className="wrapper-big form-wrapper">
      <Typography.Title className="title heading mb-0">
        <Space className="btn-wrapper">
          <AntButton
            icon={<ArrowLeftOutlined />}
            shape="circle"
            size="small"
            className="btn-back"
            onClick={() =>
              navigate(`${ROUTES?.ONBOARDING}/${ROUTES?.CREATE_DEPARTMENT}`)
            }
          />
          Invite Members
        </Space>
      </Typography.Title>
      <Text className="sub-title">
        Invite members to your company. You’ll be able
        <br /> to add more members later.
      </Text>
      <Form
        form={form}
        name="basic"
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
      >
        <AntCard bordered padding="16">
          <Form.List name="invite" initialValue={[{}]}>
            {(fields, { add, remove }) => (
              <>
                {fields?.map((field) => (
                  <Row gutter={12} key={field?.key}>
                    <Col span={fields.length > 1 ? 16 : 18}>
                      <Form.Item
                        {...field}
                        label="Email id"
                        name={[field?.name, 'email']}
                        rules={[
                          {
                            required: true,
                            message: 'Please input email!'
                          },
                          email
                        ]}
                      >
                        <AntInput
                          type="email"
                          name="department_name"
                          placeholder="Email id"
                          innerRef={emailRef}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={fields.length > 1 ? 5 : 6}>
                      <Form.Item
                        {...field}
                        label="Role"
                        name={[field?.name, 'role']}
                        rules={[
                          {
                            required: true,
                            message: 'Please select role!'
                          }
                        ]}
                      >
                        <AntSelect
                          options={[
                            {
                              name: 'Admin',
                              value: 'ADMIN'
                            },
                            {
                              name: 'Manager',
                              value: 'MANAGER'
                            },
                            {
                              name: 'User',
                              value: 'USER'
                            }
                          ]}
                          name={[field?.name, 'role']}
                          placeholder="role"
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    {fields?.length > 1 && (
                      <Col span={fields?.length > 1 ? 3 : false}>
                        <Form.Item label="Delete">
                          <AntButton
                            type="default"
                            onClick={() => remove(field?.name)}
                          >
                            <img width="14px" src={DeleteIcon} alt="icon" />
                          </AntButton>
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                ))}
                <Form.Item>
                  <AntButton type="default" onClick={add}>
                    + Add more Members
                  </AntButton>
                </Form.Item>
              </>
            )}
          </Form.List>
        </AntCard>
        <Row justify="space-between" align="middle" className="mt-16">
          <Col>
            <Link
              to="/"
              className="skip_onboarding"
              // eslint-disable-next-line no-undef
              onClick={() => localStorage?.removeItem('isSignUp')}
            >
              Skip
            </Link>
          </Col>
          <Col>
            <AntButton
              type="primary"
              htmlType="submit"
              loading={loading}
              onClick={() => {
                emailRef?.current?.focus();
              }}
            >
              Invite
            </AntButton>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default InviteMembers;
