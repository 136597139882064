import React from 'react';
import { SUPPORTEMAIL } from '../../common/constants';

function PrivacyPolicy() {
  return (
    <div>
      <div className="bold-text">Effective date: April 2022</div>
      <br />

      <div className="title-text">1. Introduction</div>
      <div className="normal-text">
        Logicwind Ventures Pvt. Ltd. (“us”, “we”, or “our”) operates
        <span className="email-text"> https://okrboard.com/</span>
        (hereinafter referred to as “Service”).
      </div>
      <br />
      <div className="normal-text">
        Our Privacy Policy governs your visit to
        <span className="email-text"> https://okrboard.com/ </span>
        and explains how we collect, safeguard and disclose information that
        results from your use of our Service.
      </div>
      <br />
      <div className="normal-text">
        We use your data to provide and improve Service. By using Service, you
        agree to the collection and use of information in accordance with this
        policy. Unless otherwise defined in this Privacy Policy, the terms used
        in this Privacy Policy have the same meanings as in our Terms and
        Conditions.
      </div>
      <br />
      <div className="normal-text">
        Our Terms and Conditions (“
        <span className="bold-text">Terms</span>”) govern all use of our Service
        and together with the Privacy Policy constitutes your agreement with us
        (“
        <span className="bold-text">agreement</span>”).
      </div>
      <br />

      <div className="title-text">2. Definitions</div>
      <div className="normal-text">
        <span className="bold-text">SERVICE</span> means the
        https://theappbarista.com/ website operated by Logicwind Ventures Pvt.
        Ltd. .
      </div>
      <br />
      <div className="normal-text">
        <span className="bold-text">PERSONAL DATA </span> means data about a
        living individual who can be identified from those data (or from those
        and other information either in our possession or likely to come into
        our possession).
      </div>
      <br />
      <div className="normal-text">
        <span className="bold-text">DATA PROCESSORS </span> (OR SERVICE
        PROVIDERS) means any natural or legal person who processes the data on
        behalf of the Data Controller. We may use the services of various
        Service Providers in order to process your data more effectively.
      </div>
      <br />
      <div className="normal-text">
        <span className="bold-text">DATA SUBJECT </span> is any living
        individual who is the subject of Personal Data.
      </div>
      <br />
      <div className="normal-text">
        <span className="bold-text">THE USER </span> is the individual using our
        Service. The User corresponds to the Data Subject, who is the subject of
        Personal Data.
      </div>
      <br />

      <div className="title-text">3. Information Collection and Use</div>
      <div className="normal-text">
        We collect several different types of information for various purposes
        to provide and improve our Service to you.
      </div>
      <br />

      <div className="title-text">4. Types of Data Collected</div>
      <div className="bold-text">Personal Data</div>
      <div className="normal-text">
        While using our Service, we may ask you to provide us with certain
        personally identifiable information that can be used to contact or
        identify you (“Personal Data”). Personally identifiable information may
        include, but is not limited to:
      </div>
      <br />
      <div className="normal-text">
        <span className="bold-text">0.1. </span>Email address
      </div>
      <br />
      <div className="normal-text">
        <span className="bold-text">0.2. </span>First name and last name
      </div>
      <br />
      <div className="normal-text">
        <span className="bold-text">0.3. </span>Phone number
      </div>
      <br />
      <div className="normal-text">
        <span className="bold-text">0.4. </span>Address, Country, State,
        Province, ZIP/Postal code, City
      </div>
      <br />
      <div className="normal-text">
        <span className="bold-text">0.5. </span>Cookies and Usage Data
      </div>
      <br />
      <div className="normal-text">
        We may use your Personal Data to contact you with newsletters, marketing
        or promotional materials and other information that may be of interest
        to you. You may opt out of receiving any, or all, of these
        communications from us by following the unsubscribe link.
      </div>
      <br />
      <div className="bold-text">Usage Data</div>
      <div className="normal-text">
        We may also collect information that your browser sends whenever you
        visit our Service or when you access Service by or through any device
        (“Usage Data”).
      </div>
      <br />
      <div className="normal-text">
        This Usage Data may include information such as your computer’s Internet
        Protocol address (e.g. IP address), browser type, browser version, the
        pages of our Service that you visit, the time and date of your visit,
        the time spent on those pages, unique device identifiers and other
        diagnostic data.
      </div>
      <br />
      <div className="normal-text">
        When you access Service with a device, this Usage Data may include
        information such as the type of device you use, your device unique ID,
        the IP address of your device, your device operating system, the type of
        Internet browser you use, unique device identifiers and other diagnostic
        data.
      </div>
      <br />

      <div className="bold-text">Tracking Cookies Data</div>
      <div className="normal-text">
        We use cookies and similar tracking technologies to track the activity
        on our Service and we hold certain information.
      </div>
      <br />
      <div className="normal-text">
        Cookies are files with a small amount of data which may include an
        anonymous unique identifier. Cookies are sent to your browser from a
        website and stored on your device. Other tracking technologies are also
        used such as beacons, tags and scripts to collect and track information
        and to improve and analyze our Service.
      </div>
      <br />
      <div className="normal-text">
        You can instruct your browser to refuse all cookies or to indicate when
        a cookie is being sent. However, if you do not accept cookies, you may
        not be able to use some portions of our Service.
      </div>
      <br />
      <div className="normal-text">Examples of Cookies we use:</div>
      <br />
      <div className="normal-text">
        <span className="bold-text">0.1. Session Cookies: </span>We use Session
        Cookies to operate our Service.
      </div>
      <br />
      <div className="normal-text">
        <span className="bold-text">0.2. Preference Cookies: </span>
        We use Preference Cookies to remember your preferences and various
        settings.
      </div>
      <br />
      <div className="normal-text">
        <span className="bold-text">0.3. Session Cookies: </span>We use Security
        Cookies for security purposes.
      </div>
      <br />
      <div className="normal-text">
        <span className="bold-text">0.4. Session Cookies: </span>
        Advertising Cookies are used to serve you with advertisements that may
        be relevant to you and your interests.
      </div>
      <br />
      <div className="bold-text">Other Data</div>
      <div className="normal-text">
        While using our Service, we may also collect the following information:
        sex, age, date of birth, registration at place of residence and actual
        address, telephone number (work, mobile), information on marital status
        and other related data.
      </div>
      <br />
      <div className="title-text">5. Use of Data</div>
      <div className="normal-text">
        We uses the collected data for various purposes:
      </div>
      <br />
      <div className="normal-text">
        <span className="bold-text">0.1. </span>
        to provide and maintain our Service;
      </div>
      <br />
      <div className="normal-text">
        <span className="bold-text">0.2. </span>
        to notify you about changes to our Service;
      </div>
      <br />
      <div className="normal-text">
        <span className="bold-text">0.3. </span>
        to allow you to participate in interactive features of our Service when
        you choose to do so;
      </div>
      <br />
      <div className="normal-text">
        <span className="bold-text">0.4. </span>
        to provide customer support;
      </div>
      <br />
      <div className="normal-text">
        <span className="bold-text">0.5. </span>
        to gather analysis or valuable information so that we can improve our
        Service;
      </div>
      <br />
      <div className="normal-text">
        <span className="bold-text">0.6. </span>
        to monitor the usage of our Service;
      </div>
      <br />
      <div className="normal-text">
        <span className="bold-text">0.7. </span>
        to detect, prevent and address technical issues;
      </div>
      <br />
      <div className="normal-text">
        <span className="bold-text">0.8. </span>
        to fulfill any other purpose for which you provide it;
      </div>
      <br />
      <div className="normal-text">
        <span className="bold-text">0.9. </span>
        to carry out our obligations and enforce our rights arising from any
        contracts entered into between you and us, including for billing and
        collection;
      </div>
      <br />
      <div className="normal-text">
        <span className="bold-text">0.10. </span>
        to provide you with notices about your account and/or subscription,
        including expiration and renewal notices, email-instructions, etc.;
      </div>
      <br />
      <div className="normal-text">
        <span className="bold-text">0.11. </span>
        to provide you with news, special offers and general information about
        other goods, services and events which we offer that are similar to
        those that you have already purchased or enquired about unless you have
        opted not to receive such information;
      </div>
      <br />
      <div className="normal-text">
        <span className="bold-text">0.12. </span>
        in any other way we may describe when you provide the information;
      </div>
      <br />
      <div className="normal-text">
        <span className="bold-text">0.13. </span>
        for any other purpose with your consent.
      </div>

      <br />
      <div className="title-text">6. Retention of Data</div>
      <div className="normal-text">
        We will retain your Personal Data only for as long as is necessary for
        the purposes set out in this Privacy Policy. We will retain and use your
        Personal Data to the extent necessary to comply with our legal
        obligations (for example, if we are required to retain your data to
        comply with applicable laws), resolve disputes, and enforce our legal
        agreements and policies.
      </div>
      <br />
      <div className="normal-text">
        We will also retain Usage Data for internal analysis purposes. Usage
        Data is generally retained for a shorter period, except when this data
        is used to strengthen the security or to improve the functionality of
        our Service, or we are legally obligated to retain this data for longer
        time periods.
      </div>
      <br />

      <div className="title-text">7. Transfer of Data</div>
      <div className="normal-text">
        Your information, including Personal Data, may be transferred to – and
        maintained on – computers located outside of your state, province,
        country or other governmental jurisdiction where the data protection
        laws may differ from those of your jurisdiction.
      </div>
      <br />
      <div className="normal-text">
        If you are located outside India and choose to provide information to
        us, please note that we transfer the data, including Personal Data, to
        India and process it there.
      </div>
      <br />
      <div className="normal-text">
        Your consent to this Privacy Policy followed by your submission of such
        information represents your agreement to that transfer.
      </div>
      <br />
      <div className="normal-text">
        We will take all the steps reasonably necessary to ensure that your data
        is treated securely and in accordance with this Privacy Policy and no
        transfer of your Personal Data will take place to an organization or a
        country unless there are adequate controls in place including the
        security of your data and other personal information.
      </div>
      <br />

      <div className="title-text">8. Disclosure of Data</div>
      <div className="normal-text">
        We may disclose personal information that we collect, or you provide:
      </div>
      <br />

      <div className="bold-text">0.1. Disclosure for Law Enforcement.</div>
      <br />
      <div className="normal-text">
        Under certain circumstances, we may be required to disclose your
        Personal Data if required to do so by law or in response to valid
        requests by public authorities.
      </div>
      <br />
      <div className="bold-text">0.2. Business Transaction.</div>
      <br />
      <div className="normal-text">
        If we or our subsidiaries are involved in a merger, acquisition or asset
        sale, your Personal Data may be transferred.
      </div>
      <br />

      <div className="bold-text">
        0.3. Other cases. We may disclose your information also:
      </div>
      <br />
      <div className="normal-text">
        <span className="bold-text">0.3.1. </span>
        to our subsidiaries and affiliates;
      </div>
      <br />
      <div className="normal-text">
        <span className="bold-text">0.3.2. </span>
        to contractors, service providers, and other third parties we use to
        support our business;
      </div>
      <br />
      <div className="normal-text">
        <span className="bold-text">0.3.3. </span>
        to fulfill the purpose for which you provide it;
      </div>
      <br />
      <div className="normal-text">
        <span className="bold-text">0.3.4. </span>
        for the purpose of including your company’s logo on our website;
      </div>
      <br />
      <div className="normal-text">
        <span className="bold-text">0.3.5. </span>
        for any other purpose disclosed by us when you provide the information;
      </div>
      <br />
      <div className="normal-text">
        <span className="bold-text">0.3.6. </span>
        with your consent in any other cases;
      </div>
      <br />
      <div className="title-text">9. Security of Data</div>
      <div className="normal-text">
        The security of your data is important to us. While we strive to use
        commercially acceptable means to protect your Personal Data, we cannot
        guarantee its absolute security.
      </div>
      <br />

      <div className="title-text">
        10. Data Protection Rights Under General Data Protection Regulation
        (GDPR)
      </div>
      <div className="normal-text">
        If you are a resident of the European Union (EU) and European Economic
        Area (EEA), you have certain data protection rights, covered by GDPR.
      </div>
      <br />
      <div className="normal-text">
        We aim to take reasonable steps to allow you to correct, amend, delete,
        or limit the use of your Personal Data.
      </div>
      <br />
      <div className="normal-text">
        If you wish to be informed what Personal Data we hold about you and if
        you want it to be removed from our systems, please email us at{' '}
        <span className="email-text"> {SUPPORTEMAIL}</span>
      </div>
      <br />

      <div className="title-text">
        11. Data Protection Rights under the California Privacy Protection Act
        (CalOPPA)
      </div>
      <div className="normal-text">
        According to CalOPPA we agree to the following:
      </div>
      <br />
      <div className="normal-text">
        <span className="bold-text">0.1. </span>
        users can visit our site anonymously;
      </div>
      <br />
      <div className="normal-text">
        <span className="bold-text">0.2. </span>
        our Privacy Policy link includes the word “Privacy”, and can easily be
        found on the home page of our website;
      </div>
      <br />
      <div className="normal-text">
        <span className="bold-text">0.3. </span>
        users will be notified of any privacy policy changes on our Privacy
        Policy Page;
      </div>
      <br />
      <div className="normal-text">
        <span className="bold-text">0.4. </span>
        users are able to change their personal information by emailing us at{' '}
        <span className="email-text"> {SUPPORTEMAIL}</span>
      </div>
      <br />
      <div className="normal-text">Our Policy on “Do Not Track” Signals:</div>
      <br />
      <div className="normal-text">
        We honor Do Not Track signals and do not track, plant cookies, or use
        advertising when a Do Not Track browser mechanism is in place. Do Not
        Track is a preference you can set in your web browser to inform websites
        that you do not want to be tracked.
      </div>
      <br />
      <div className="normal-text">
        You can enable or disable Do Not Track by visiting the Preferences or
        Settings page of your web browser.
      </div>
      <br />
      <div className="normal-text">
        If you are a California resident, you are entitled to learn what data we
        collect about you, ask to delete your data and not to sell (share) it.
        We aim to take reasonable steps to allow you to correct, amend, delete,
        or limit the use of your Personal Data.
      </div>
      <br />
      <div className="normal-text">
        If you wish to be informed what Personal Data we hold about you and if
        you want it to be removed from our systems, please email us at
        <span className="email-text"> devtools@logicwind.com</span>
      </div>
      <br />
      <div className="title-text">Delete your personal information:</div>
      <div className="normal-text">
        If you make this request, we will delete the personal information we
        hold about you as of the date of your request from our records and
        direct any service providers to do the same. In some cases, deletion may
        be accomplished through de-identification of the information. If you
        choose to delete your personal information, you may not be able to use
        certain functions that require your personal information to operate.
      </div>
      <br />
      <div className="title-text">Stop selling your personal information:</div>
      <div className="normal-text">
        We don’t sell or rent your personal information to any third parties for
        any purpose. We do not sell your personal information for monetary
        consideration. However, under some circumstances, a transfer of personal
        information to a third party, or within our family of companies, without
        monetary consideration may be considered a “sale” under California law.
        You are the only owner of your Personal Data and can request disclosure
        or deletion at any time.
      </div>
      <br />
      <div className="normal-text">
        If you submit a request to stop selling your personal information, we
        will stop making such transfers.
      </div>
      <br />
      <div className="normal-text">
        Please note, if you ask us to delete or stop selling your data, it may
        impact your experience with us, and you may not be able to participate
        in certain programs or membership services which require the usage of
        your personal information to function. But in no circumstances, we will
        discriminate against you for exercising your rights.
      </div>

      <br />
      <div className="title-text">12. Service Providers</div>
      <div className="normal-text">
        We may employ third party companies and individuals to facilitate our
        Service (“Service Providers”), provide Service on our behalf, perform
        Service-related services or assist us in analysing how our Service is
        used.
      </div>
      <div className="normal-text">
        These third parties have access to your Personal Data only to perform
        these tasks on our behalf and are obligated not to disclose or use it
        for any other purpose.
      </div>

      <br />
      <div className="title-text">13. Analytics</div>
      <div className="normal-text">
        We may use third-party Service Providers to monitor and analyze the use
        of our Service.
      </div>

      <br />
      <div className="title-text">14. CI/CD tools</div>
      <div className="normal-text">
        We may use third-party Service Providers to automate the development
        process of our Service.
      </div>

      <br />
      <div className="title-text">15. Behavioral Remarketing</div>
      <div className="normal-text">
        We may use remarketing services to advertise on third party websites to
        you after you visited our Service. We and our third-party vendors use
        cookies to inform, optimize and serve ads based on your past visits to
        our Service.
      </div>

      <br />
      <div className="title-text">16. Links to Other Sites</div>
      <div className="normal-text">
        Our Service may contain links to other sites that are not operated by
        us. If you click a third party link, you will be directed to that third
        party’s site. We strongly advise you to review the Privacy Policy of
        every site you visit.
      </div>
      <br />
      <div className="normal-text">
        We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.
      </div>

      <br />
      <div className="title-text">17. Children’s Privacy</div>
      <div className="normal-text">
        Our Services are not intended for use by children under the age of 18
        (“Child” or “Children”).
      </div>
      <div className="normal-text">
        We do not knowingly collect personally identifiable information from
        Children under 18. If you become aware that a Child has provided us with
        Personal Data, please contact us. If we become aware that we have
        collected Personal Data from Children without verification of parental
        consent, we take steps to remove that information from our servers.
      </div>

      <br />
      <div className="title-text">18. Changes to This Privacy Policy</div>
      <div className="normal-text">
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page.
      </div>
      <div className="normal-text">
        We will let you know via email and/or a prominent notice on our Service,
        prior to the change becoming effective and update “effective date” at
        the top of this Privacy Policy.
      </div>
      <div className="normal-text">
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </div>

      <br />
      <div className="title-text">19. Contact Us</div>
      <div className="normal-text">
        If you have any questions about this Privacy Policy, please contact us
        by email:
        <span className="email-text"> {SUPPORTEMAIL}</span>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
