import React from 'react';
import ReactDOM from 'react-dom/client';
import Routes from './Routes';

const VERSION = require('../package.json')?.version;

// eslint-disable-next-line no-console
console?.log('APP_VERSION ', VERSION);

// eslint-disable-next-line no-undef
const root = ReactDOM?.createRoot(document?.getElementById('root'));
root?.render(<Routes />);
