import { Form, notification, Tabs, Upload } from 'antd';
import React, { useContext, useState } from 'react';
import { settingImg } from '../../assets';
import { USERS_ROLE } from '../../common/constants';
import { HasAccess } from '../../common/utils';
import AntCard from '../../components/AntCard';
import { UserDetailsContext } from '../../context/UserDetailsProvider';
import api from '../../services/api';
import Content from './AntStyled';
import CompanySettings from './CompanySettings';
import DepartmentsSettings from './DepartmentsSettings';
import MembersSetting from './MembersSetting';

const { TabPane } = Tabs;

const Setting = () => {
  const onChange = () => {};
  const { state, dispatch } = useContext(UserDetailsContext);
  const user = JSON?.parse(state?.user);
  const [imageUrl, setImageUrl] = useState(user?.company?.logo);
  const [imageSrc, setImageSrc] = useState('');

  const onFinish = (values) => {
    // eslint-disable-next-line no-undef
    const formData = new FormData();
    if (values?.avatar) {
      formData?.append('logo', values?.avatar);
    }
    formData?.append('id', user?.company?.id);
    formData?.append('name', values?.name);
    api
      ?.post('company', formData)
      .then(function (response) {
        notification?.success({
          message: 'Success',
          description: response?.data?.message
        });
        const userData = user;
        userData.company = response?.data?.data;
        dispatch({
          type: 'UPDATE_USER',
          payload: userData
        });
      })
      .catch(function (error) {
        notification.error({
          message: 'Error',
          description: error?.response?.data?.message
        });
      });
  };

  function getBase64(img, callback) {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader?.addEventListener('load', () => callback(reader?.result));
    reader?.readAsDataURL(img);
  }

  const handleChange = (info) => {
    const isLt1M = info?.file?.size / 1024 / 1024 < 1;
    if (!isLt1M) {
      notification.error({
        message: 'Error',
        description: 'Image must be less than 2MB!'
      });
      return false;
    }
    getBase64(info?.file, (base64String) => {
      setImageUrl(base64String);
      setImageSrc(info?.file);
    });
    return true;
  };

  const uploadButton = (
    <div>
      <div>+ Upload</div>
    </div>
  );

  return (
    <Content padding="16px" height="calc(100vh - 64px)">
      <AntCard className="card" bordered={false}>
        <div className="bg-img">
          <img className="profile-bg" src={settingImg} alt="icon" />
          <div className="upload-bg">
            <Form
              name="basic"
              onFinish={onFinish}
              layout="vertical"
              requiredMark={false}
              initialValues={{ name: user?.company?.name }}
              className="company_logo_setting"
            >
              <Form.Item
                name="avatar"
                getValueFromEvent={({ fileList }) => fileList}
                valuePropName="fileList"
              >
                <Upload
                  name="avatar"
                  disabled={HasAccess({
                    roles: [
                      USERS_ROLE?.ADMIN,
                      USERS_ROLE?.MANAGER,
                      USERS_ROLE?.USER
                    ]
                  })}
                  listType="picture-card"
                  className="avatar-uploader img-upload"
                  showUploadList={false}
                  onChange={handleChange}
                  beforeUpload={() => {
                    return false;
                  }}
                >
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      className="placeholder-img"
                      alt="avatar"
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Form.Item>
              <div className="name-title">{user?.company?.name}</div>
            </Form>
          </div>
        </div>
        <div className="p-32 pt-0 tab-div">
          <Tabs defaultActiveKey="1" onChange={onChange}>
            <TabPane tab="Company Settings" key="1">
              <CompanySettings imageSrc={imageSrc} />
            </TabPane>
            <TabPane tab="Members" key="2">
              <MembersSetting />
            </TabPane>
            <TabPane tab="Departments" key="3">
              <DepartmentsSettings />
            </TabPane>
          </Tabs>
        </div>
      </AntCard>
    </Content>
  );
};

export default Setting;
