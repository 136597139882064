import { Checkbox, Form } from 'antd';
import React from 'react';
import styled from 'styled-components';
import Colors from '../design/Colors';

const CustomCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    border-radius: 4px;
  }
  .ant-checkbox + span {
    color: ${Colors?.Text};
  }
`;

const AntCheckbox = ({ name, valuePropName, label, className, ...rest }) => {
  return (
    <Form.Item className={className} name={name} valuePropName={valuePropName}>
      <CustomCheckbox name={name} {...rest}>
        {label}
      </CustomCheckbox>
    </Form.Item>
  );
};

export default AntCheckbox;
