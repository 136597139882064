import {
  Col,
  Dropdown,
  Form,
  List,
  Menu,
  notification,
  Pagination,
  Row,
  Table,
  Typography
} from 'antd';
import { debounce } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { DeleteIcon, EditIcon, MoreIcon } from '../../assets';
import AccessRole from '../../common/AccessRole';
import { USERS_ROLE } from '../../common/constants';
import { HasAccess } from '../../common/utils';
import AntAvatar from '../../components/AntAvatar';
import AntButton from '../../components/AntButton';
import AntCard from '../../components/AntCard';
import AntInput from '../../components/AntInput';
import DeleteMemberModal from '../../components/Shared/modals/DeleteMemberModal';
import EditMemberModal from '../../components/Shared/modals/EditMemberModal';
import InviteModal from '../../components/Shared/modals/InviteModal';
import { UserDetailsContext } from '../../context/UserDetailsProvider';
import api from '../../services/api';

const { Text } = Typography;

function MembersSetting() {
  const [isInviteMember, setIsInviteMember] = useState(false);
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [members, setMembers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [memberId, setMemberId] = useState();
  const [deleteMemberId, setDeleteMemberId] = useState();
  const [deleteMemberName, setDeleteMemberName] = useState();
  const [deleteMemberType, setDeleteMemberType] = useState();
  const [isEditMemberModal, setIsEditMemberModal] = useState(false);
  const { state } = useContext(UserDetailsContext);
  const user = JSON?.parse(state?.user);

  const companyMembers = (pageN = '', searchValue) => {
    setLoading(true);
    api
      ?.get('company-members', {
        params: {
          search: searchValue,
          per_page: 5,
          page: pageN ?? currentPage
        }
      })
      .then(function (response) {
        const data = [];
        setTotalPages(response?.data?.last_page);
        response?.data?.data?.forEach((r) => {
          data?.push({
            key: r?.id,
            name: r?.name,
            email: r?.email,
            invited_name: r?.invited_by?.email,
            job_title: r?.job_title,
            role: r?.role,
            is_invited: r?.is_invited,
            image: r?.image
          });
        });
        setMembers(data);
        setLoading(false);
      })
      .catch(function () {});
  };

  useEffect(() => {
    companyMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    companyMembers(pageNumber);
  };

  const handleMenuClick = (event) => {
    const decodeArr = event?.key?.split('/');
    if (decodeArr?.[0] === 'Delete') {
      setIsDeleteVisible(true);
      setDeleteMemberId(decodeArr?.[1]);
      if (decodeArr?.[2] === '1') {
        setDeleteMemberType('INVITATION');
      } else {
        setDeleteMemberType('MEMBER');
      }
      setDeleteMemberName(decodeArr?.[3]);
    } else {
      setMemberId(event?.key);
      setIsEditMemberModal(true);
    }
  };

  const menu = (record) => {
    const editButton = {
      label: <Text>Edit</Text>,
      key: record?.key,
      icon: <img width="14px" src={EditIcon} alt="icon" />
    };
    return (
      <Menu
        onClick={handleMenuClick}
        items={[
          record?.is_invited ? '' : editButton,
          {
            label: 'Delete',
            key: `Delete/${record?.key}/${record?.is_invited}/${record?.name}`,
            icon: <img width="14px" src={DeleteIcon} alt="icon" />
          }
        ]}
      />
    );
  };

  const columns = [
    {
      title: 'Members',
      dataIndex: 'email',
      key: 'email',
      width: '35%',
      render: (text, record) => (
        <List.Item>
          <List.Item.Meta
            avatar={
              <AntAvatar
                imgSrc={record?.image}
                userId={record?.key}
                firstChar={
                  record?.name
                    ? record?.name?.charAt(0)
                    : record?.email?.charAt(0)
                }
              />
            }
            title={
              record?.is_invited === 1 ? (
                <div className="invited_label">Invited</div>
              ) : (
                record?.name
              )
            }
            description={text}
          />
        </List.Item>
      )
    },
    {
      title: 'Job Title',
      dataIndex: 'job_title',
      key: 'job_title',
      width: '35%'
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      width: '20%'
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: '10%',
      render: (_, record) => {
        if (user?.id !== record?.key && record?.role !== USERS_ROLE?.OWNER) {
          return (
            <Dropdown overlay={menu(record)} trigger={['click']} arrow>
              <img src={MoreIcon} alt="" className="ml-12" />
            </Dropdown>
          );
        }
      }
    }
  ]?.filter((item) => {
    if (
      HasAccess({ roles: [USERS_ROLE?.USER] }) &&
      item?.dataIndex === 'action'
    ) {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const handleDeleteMember = () => {
    api
      ?.post('delete-member', {
        id: deleteMemberId,
        type: deleteMemberType
      })
      .then(function (response) {
        companyMembers();
        notification?.success?.({
          message: 'Success',
          description: response?.message
        });
      });
    setDeleteMemberId();
    setDeleteMemberName();
    setDeleteMemberType();
  };

  const handleSearch = (e) => {
    const value = e?.target?.value;
    companyMembers(currentPage, value);
  };

  const searchDebounce = debounce(handleSearch, 1000);

  return (
    <>
      <Form>
        <Row justify="space-between">
          <Col span={HasAccess({ roles: [USERS_ROLE?.USER] }) ? 24 : 18}>
            <AntInput
              type="text"
              name="search"
              allowClear
              placeholder="Search here..."
              onChange={searchDebounce}
            />
          </Col>
          <AccessRole
            allowedRoles={[
              USERS_ROLE?.OWNER,
              USERS_ROLE?.ADMIN,
              USERS_ROLE?.MANAGER
            ]}
          >
            <Col>
              <AntButton
                onClick={() => {
                  setIsInviteMember(true);
                }}
                type="primary"
              >
                + Invite Members
              </AntButton>
              <InviteModal
                visible={isInviteMember}
                onCancel={() => {
                  setIsInviteMember(false);
                }}
                onOk={() => {
                  setIsInviteMember(false);
                  companyMembers();
                }}
              />
            </Col>
          </AccessRole>
        </Row>
      </Form>
      <AntCard>
        <Table
          pagination={false}
          columns={columns}
          dataSource={members}
          loading={loading}
          className="members-setting"
        />
      </AntCard>
      <Pagination
        className="mt-16"
        onChange={onPageChange}
        defaultCurrent={1}
        defaultPageSize={5}
        total={totalPages * 5}
      />
      {isEditMemberModal && (
        <EditMemberModal
          visible={isEditMemberModal}
          memberId={memberId}
          onOk={() => {
            setIsEditMemberModal(false);
            companyMembers();
          }}
          onCancel={() => {
            setIsEditMemberModal(false);
          }}
        />
      )}
      {isDeleteVisible && (
        <DeleteMemberModal
          visible={isDeleteVisible}
          deleteMemberId={deleteMemberId}
          deleteMemberName={deleteMemberName}
          onSub={() => {
            setIsDeleteVisible(false);
            handleDeleteMember();
          }}
          onCancel={() => {
            setIsDeleteVisible(false);
            setDeleteMemberId();
            setDeleteMemberName();
            setDeleteMemberType();
          }}
        />
      )}
    </>
  );
}

export default MembersSetting;
