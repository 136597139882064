import { Alert } from 'antd';
import React from 'react';
import styled from 'styled-components';
import Colors from '../design/Colors';

const CustomAlert = styled(Alert)`
  border-radius: 8px;
  margin-top: 24px;
  margin-bottom: -8px;
  background-color: rgba(230, 78, 44, 0.1);
  color: ${Colors?.Danger};
  a {
    color: rgba(8, 1, 28, 1);
  }
`;
function AntAlert({ message, description, ...rest }) {
  return (
    <CustomAlert
      message={message}
      description={description}
      type="error"
      showIcon
      {...rest}
    />
  );
}

export default AntAlert;
