import { Col, Form, notification, Popconfirm, Row, Upload } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeleteIcon, ProfileBg } from '../../../assets';
import { ROUTES } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import { UserDetailsContext } from '../../../context/UserDetailsProvider';
import api from '../../../services/api';
import AntButton from '../../AntButton';
import AntInput from '../../AntInput';
import AntModal from '../../AntModal';

const { onlyAlphabets, alphabetsDotSpace } = formValidatorRules;
const ProfileModal = ({ visible, onCancel }) => {
  const navigate = useNavigate();
  const [form] = Form?.useForm();

  const { state, dispatch } = React.useContext(UserDetailsContext);
  const user = JSON?.parse(state?.user);
  const [imageSrc, setImageSrc] = useState('');
  const [imageUrl, setImageUrl] = useState(
    user?.image ??
      'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y'
  );

  const onFinish = (values) => {
    // eslint-disable-next-line no-undef
    const formData = new FormData();
    if (imageSrc) {
      formData?.append('image', imageSrc);
    }
    formData?.append('name', values?.name);
    formData?.append('job_title', values?.job_title);
    api
      ?.post('edit-profile', formData)
      .then(function (response) {
        api?.get('user').then(function (responseData) {
          dispatch({
            type: 'UPDATE_USER',
            payload: responseData?.data
          });
        });
        notification?.success({
          message: 'Success',
          description: response?.message
        });
        onCancel();
      })
      .catch(function () {});
  };

  function getBase64(img, callback) {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader?.addEventListener('load', () => callback(reader?.result));
    reader?.readAsDataURL(img);
  }

  const handleChange = (info) => {
    const isLt1M = info?.file?.size / 1024 / 1024 < 1;
    if (!isLt1M) {
      notification.error({
        message: 'Error',
        description: 'Image must be less than 1MB!'
      });
      return false;
    }
    getBase64(info?.file, (base64String) => {
      setImageUrl(base64String);
      setImageSrc(info?.file);
    });
    return true;
  };

  const deleteProfile = () => {
    api
      ?.post('delete-user-account')
      .then(function (response) {
        notification?.success({
          message: 'Success',
          description: response?.message
        });
        dispatch({ type: 'LOGOUT' });
        navigate(ROUTES?.LOGIN);
      })
      .catch(function () {});
  };

  return (
    <div id="profileModal">
      <AntModal
        visible={visible}
        onOk={form?.submit}
        onCancel={onCancel}
        footer={null}
        okText="Save"
        className="profile-modal"
        content={
          <Form
            form={form}
            name="basic"
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            requiredMark={false}
            initialValues={user}
          >
            <div className="bg-img">
              <img className="profile-bg" src={ProfileBg} alt="icon" />
              <div className="upload-bg">
                <Form.Item
                  name="avatar"
                  getValueFromEvent={({ file }) => file?.originFileObj}
                  valuePropName="file"
                  className="mb-0"
                >
                  <Upload
                    name="avatar"
                    className="avatar-uploader mt-8"
                    showUploadList={false}
                    onChange={handleChange}
                    beforeUpload={() => {
                      return false;
                    }}
                  >
                    <img
                      src={imageUrl}
                      alt="ProfileImage"
                      className="placeholder-img"
                    />
                  </Upload>
                </Form.Item>
                <div className="user-profile">
                  <div className="name-title">
                    {user?.name?.length > 30
                      ? `${user?.name?.substring(0, 30)}...`
                      : user?.name}
                  </div>
                  <div className="name-des">{user?.job_title}</div>
                </div>
              </div>
            </div>
            <div className="p-32 pb-0">
              <Form.Item>
                <AntInput
                  label="Full Name*"
                  name="name"
                  maxLength="50"
                  placeholder="Enter full name"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your full name!'
                    },
                    onlyAlphabets
                  ]}
                  className="mb-0"
                />
              </Form.Item>
              <Form.Item>
                <AntInput
                  label="Job title*"
                  name="job_title"
                  maxLength="100"
                  placeholder="Eg. Software engineer"
                  rules={[
                    {
                      required: true,
                      message: 'Please input job title!'
                    },
                    alphabetsDotSpace
                  ]}
                  className="mb-0"
                />
              </Form.Item>
              <Form.Item className="mb-0">
                <AntInput
                  label="Email ID"
                  name="email"
                  disabled
                  placeholder="xyz@gmail.com"
                  className="mb-0"
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: 'Please input email!'
                    }
                  ]}
                />
              </Form.Item>
              <Row
                align="middle"
                justify="space-between"
                className="mt-24 mb-32"
              >
                <Col>
                  <Popconfirm
                    title="Are you sure to delete your account?"
                    onConfirm={deleteProfile}
                    okText="Yes"
                    cancelText="No"
                  >
                    <AntButton className="text-warning border_less delete-my-Account">
                      <img src={DeleteIcon} alt="" />
                      &nbsp;Delete my account
                    </AntButton>
                  </Popconfirm>
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <AntButton
                        type="button"
                        onClick={onCancel}
                        className="border_less"
                      >
                        Cancel
                      </AntButton>
                    </Col>
                    <Col className="ml-16">
                      <AntButton type="primary" htmlType="submit">
                        Save
                      </AntButton>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Form>
        }
      />
    </div>
  );
};

export default ProfileModal;
