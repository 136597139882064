import React from 'react';
import { Navigate } from 'react-router-dom';
import { ROUTES } from '../common/constants';

const useAuth = () => {
  // eslint-disable-next-line no-undef
  const user = localStorage?.getItem('user');
  if (user) {
    return true;
  }
  return false;
};

const PublicRoutes = ({ children }) => {
  const auth = useAuth();
  return auth ? <Navigate to={ROUTES?.MAIN} /> : children;
};

export default PublicRoutes;
