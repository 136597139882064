import {
  DownCircleFilled,
  DownCircleOutlined,
  UpCircleFilled,
  UpCircleOutlined
} from '@ant-design/icons';
import {
  Badge,
  Button,
  Checkbox,
  Col,
  Collapse,
  Dropdown,
  Empty,
  Menu,
  Progress,
  Row,
  Slider,
  Spin,
  Tag,
  Tooltip,
  Typography,
  notification
} from 'antd';
import { isEmpty } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useOutletContext } from 'react-router-dom';
import { DeleteIcon, EarthIcon, EditIcon, MoreIcon } from '../../assets';
import { PROGRESS_TYPE_LIST, USERS_ROLE } from '../../common/constants';
import AntAvatar from '../../components/AntAvatar';
import AntPopOver from '../../components/AntPopOver';
import ConfirmModal from '../../components/Shared/modals/ConfirmModal';
import DeleteModal from '../../components/Shared/modals/DeleteModal';
import KeyResultModal from '../../components/Shared/modals/KeyResultModal';
import ObjectiveModal from '../../components/Shared/modals/ObjectiveModal';
import { UserDetailsContext } from '../../context/UserDetailsProvider';
import api from '../../services/api';
import { Content, KeyText } from './AntStyled';

const { Panel } = Collapse;
const { Text } = Typography;

const Home = () => {
  const [objectives, setObjectives] = useState([]);
  const [companyObjectives, setCompanyObjectives] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [isResultVisible, setIsResultVisible] = useState(false);
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [keyResultVisibleId, setKeyResultVisibleId] = useState([]);
  const [isDeleteKeyResult, setIsDeleteKeyResult] = useState(false);
  const [decodeArrDelete, setDecodeArrDelete] = useState([]);
  const [objectiveId, setObjectiveId] = useState();
  const [keyResultId, setKeyResultId] = useState();
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState();
  const { state } = useContext(UserDetailsContext);
  const user = JSON?.parse(state?.user);
  const [
    search,
    objectiveType,
    objectiveTypeId,
    status,
    objectiveCycle,
    isMyObjective,
    objectiveMemberId,
    newObjectiveAdded
  ] = useOutletContext();
  const [isObjectiveVisible, setIsObjectiveVisible] = useState(false);

  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [currentObject, setCurrentObject] = useState(false);

  /* Get all users */
  const getObjectives = async () => {
    setLoading(true);
    if (!isEmpty(user?.company)) {
      api
        ?.get('objectives', {
          params: {
            search,
            type: objectiveType,
            type_id: objectiveTypeId,
            status,
            cycle_id: objectiveCycle,
            Ismyobjective: isMyObjective,
            owner_id: objectiveMemberId
          }
        })
        .then(function (response) {
          setCurrentPage(response?.data?.current_page);
          setLastPage(response?.data?.last_page);
          if (response?.data?.data == null) {
            const update = [response?.data];
            update?.forEach((data, index) => {
              Object.entries(data)?.forEach((entry) => {
                const [key, value] = entry;
                value?.forEach((item, valueIndex) => {
                  // eslint-disable-next-line no-shadow
                  item?.key_results?.forEach((item, keyResultIndex) => {
                    update[index][key][valueIndex].key_results[
                      keyResultIndex
                    ] = {
                      ...item,
                      temp_val: item?.check_in?.current_progress
                    };
                  });
                });
              });
            });
            setCompanyObjectives(...update);
          } else {
            const update = [...response?.data?.data];
            const ids = {};
            update?.forEach((data, index) => {
              data?.key_results?.forEach((keys, indexValue) => {
                update[index].key_results[indexValue] = {
                  ...keys,
                  temp_val: keys?.check_in?.current_progress
                };
              });

              ids[data?.id] = 3;
            });
            setObjectives(update);
            setKeyResultVisibleId(ids);
          }
        })
        .catch(function () {})
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    setObjectives([]);
    getObjectives();
    if (isMyObjective === 'true') {
      setCompanyObjectives([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    search,
    objectiveType,
    status,
    objectiveCycle,
    isMyObjective,
    state?.user
  ]);

  useEffect(() => {
    if (newObjectiveAdded > 0) getObjectives();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newObjectiveAdded]);

  useEffect(() => {
    /* Get departments data */
    api
      ?.get('departments', {
        params: { is_from_setting_page: true }
      })
      .then(function (response) {
        const data = [];
        response?.data?.forEach((r) => {
          data?.push(r?.name ?? r?.parent_department?.name);
        });
        setDepartments(data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const keyResultChange = (val, objectiveIndex, keyResultIndex) => {
    if (objectives?.length > 0) {
      setObjectives((prevState) => {
        const update = [...prevState];
        const obj = update?.[objectiveIndex];
        const keyResult = obj?.key_results?.[keyResultIndex];
        update[objectiveIndex].key_results[keyResultIndex] = {
          ...keyResult,
          temp_val: val,
          isVisible: true
        };
        return update;
      });
    } else {
      const update = companyObjectives;
      Object.entries(update)?.forEach((entry) => {
        const [key, value] = entry;
        const obj = value?.[objectiveIndex];
        const keyResult = obj?.key_results?.[keyResultIndex];
        update[key][objectiveIndex].key_results[keyResultIndex] = {
          ...keyResult,
          temp_val: val,
          isVisible: true
        };
      });
      setCompanyObjectives({ ...update });
    }
  };

  const setIsPopUpVisible = (objectiveIndex, keyResultIndex) => {
    if (objectives?.length > 0) {
      setObjectives((prevState) => {
        const update = [...prevState];
        const obj = update?.[objectiveIndex];
        const keyResult = obj?.key_results?.[keyResultIndex];
        update[objectiveIndex].key_results[keyResultIndex] = {
          ...keyResult,
          isVisible: false
        };
        return update;
      });
    } else {
      const update = companyObjectives;
      Object.entries(update)?.forEach((entry) => {
        const [key, value] = entry;
        const obj = value?.[objectiveIndex];
        const keyResult = obj?.key_results?.[keyResultIndex];
        update[key][objectiveIndex].key_results[keyResultIndex] = {
          ...keyResult,
          isVisible: false
        };
      });
      setCompanyObjectives({ ...update });
    }
  };

  const ObjectiveMenu = (id, objectiveCreatedBy, objectiveOwnerId) => {
    const deleteButton = {
      label: 'Delete',
      key: `Delete/${id}`,
      icon: <img width="14px" src={DeleteIcon} alt="icon" />
    };
    return (
      <Menu
        onClick={handleObjectiveMenuClick}
        items={[
          {
            label: <Text>Edit</Text>,
            key: id,
            icon: <img width="14px" src={EditIcon} alt="icon" />
          },
          (objectiveCreatedBy === objectiveOwnerId &&
            objectiveOwnerId === user?.id) ||
          user?.role === USERS_ROLE?.OWNER
            ? deleteButton
            : false
        ]}
      />
    );
  };

  function handleObjectiveMenuClick(e) {
    e?.domEvent?.stopPropagation();
    const decodeArr = e?.key?.split('/');
    setDecodeArrDelete(decodeArr);
    setObjectiveId(decodeArr?.[0]);
    if (decodeArr?.[0] === 'Delete') {
      setIsDeleteVisible(true);
    } else {
      setIsObjectiveVisible(true);
    }
  }

  const keyResultMenu = (id, keyResultCreatedBy, keyResultOwnerId) => {
    const deleteButton = {
      label: 'Delete',
      key: `Delete/${id}`,
      icon: <img width="14px" src={DeleteIcon} alt="icon" />
    };
    return (
      <Menu
        onClick={handleKRMenuClick}
        items={[
          {
            label: <Text>Edit</Text>,
            key: id,
            icon: <img width="14px" src={EditIcon} alt="icon" />
          },
          keyResultCreatedBy === keyResultOwnerId ||
          user?.role === USERS_ROLE?.OWNER
            ? deleteButton
            : false
        ]}
      />
    );
  };

  function handleKRMenuClick(e) {
    e?.domEvent?.stopPropagation();
    const decodeArr = e?.key?.split('/');
    setDecodeArrDelete(decodeArr);
    setObjectiveId(decodeArr?.[0]);
    setKeyResultId(decodeArr?.[2]);
    if (decodeArr?.[0] === 'Delete') {
      setIsDeleteKeyResult(true);
      setIsDeleteVisible(true);
    } else {
      setIsResultVisible(true);
    }
  }

  const handleDelete = () => {
    const type =
      decodeArrDelete?.[2] === 'objective' ? 'objectives' : 'key-results';
    const numberOfArr = decodeArrDelete?.[2] === 'objective' ? 1 : 3;
    api
      ?.delete(`${type}/${decodeArrDelete?.[numberOfArr]}`)
      .then(function (response) {
        getObjectives();
        notification?.success({
          message: 'Success',
          description: response?.message
        });
        setDecodeArrDelete([]);
      });
  };

  const addKeyResult = (objId) => {
    setObjectiveId(objId);
    setKeyResultId('');
    setIsResultVisible(true);
  };

  const renderSwitch = (type) => {
    switch (type) {
      case 'ON_TRACK':
        return '#3BB665';
      case 'AT_RISK':
        return '#E64E2C';
      case 'BEHIND':
        return '#EABF27';
      default:
        return 'grey';
    }
  };

  const keyResultVisibleFunction = (id, length) => {
    const temp = { ...keyResultVisibleId };
    temp[id] = length;
    setKeyResultVisibleId(temp);
  };

  const getTagOrBadge = (item) => {
    if (item?.type === 'COMPANY') {
      return (
        <Tag className="company-tag big-tag mr-32">
          <img src={EarthIcon} alt="" />
          &nbsp; Company
        </Tag>
      );
    }
    if (item?.type === 'DEPARTMENT') {
      const departmentName =
        item?.department?.name ?? item?.department?.parent_department?.name;
      const isMarketingOrDeveloper =
        departmentName === 'Marketing' || departmentName === 'Developer';
      const badgeStatus = isMarketingOrDeveloper ? 'error' : 'success';

      return (
        <Badge
          status={badgeStatus}
          text={departmentName}
          className="mr-32 group-tag"
        />
      );
    }
    return <Tag className="mr-32 heading user-tag">{item?.owner?.name}</Tag>;
  };

  const getKeyResultValue = (keyResult) => {
    if (keyResult?.temp_val) {
      return keyResult?.temp_val;
    }
    if (keyResult?.check_in?.current_progress) {
      return keyResult?.check_in?.current_progress;
    }
    return keyResult?.start;
  };

  const getButtonOrEmptyString = (item) => {
    if (item?.key_results?.length <= keyResultVisibleId?.[item?.id]) {
      return '';
    }
    if (item?.key_results?.length > 3) {
      const numHiddenResults =
        item?.key_results?.length - keyResultVisibleId?.[item?.id];
      const buttonText = `+${numHiddenResults} more`;

      return (
        <Button
          type="link"
          onClick={() =>
            keyResultVisibleFunction(item?.id, item?.key_results?.length)
          }
        >
          {buttonText}
        </Button>
      );
    }
    return '';
  };

  const renderTag = ({ values }) => {
    if (values?.[0] === 'Company') {
      return (
        <Tag className="big-tag">
          <img src={EarthIcon} alt="" className="mb-3" />
          &nbsp; Company
        </Tag>
      );
    }
    if (departments?.includes(values?.[0])) {
      if (values?.[0] === 'Marketing' || values?.[0] === 'Developer') {
        return (
          <Badge status="error" text={values?.[0]} className="badge_success" />
        );
      }
      return (
        <Badge status="success" text={values?.[0]} className="badge_success" />
      );
    }
    return <Tag className="heading big-tag user-tag">{values?.[0]}</Tag>;
  };

  const handleFinish = () => {
    setLoading(true);
    const data = {
      current_progress: '100',
      status: 'ON_TRACK',
      key_result_id: currentObject?.id,
      objective_id: currentObject?.objective_id
    };
    api
      ?.post(`/check-in`, data)
      .then(function (response) {
        getObjectives();
        setLoading(false);
        setIsConfirmModal(false);
        notification?.success?.({
          message: 'Success',
          description: response?.message
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const objLists = (item, index) => {
    const handleCheck = (e, keyResult) => {
      if (e?.target?.checked === true) {
        setIsConfirmModal(true);
        setCurrentObject(keyResult);
      }
    };
    return (
      <Collapse
        className="objective-collapse"
        key={item?.id}
        expandIcon={({ isActive }) =>
          isActive ? (
            <UpCircleOutlined className="font-size-22" />
          ) : (
            <DownCircleOutlined className="font-size-22" />
          )
        }
      >
        <Panel
          header={
            <Row justify="space-between" align="middle" className="row-header">
              <Col className="objective-title">
                <RouterLink
                  strong="true"
                  to={`objective-details/${item?.id}`}
                  className="text-header"
                >
                  {item?.name?.charAt(0)?.toUpperCase() + item?.name?.slice(1)}
                </RouterLink>
                <Text type="secondary" className="text-sub">
                  {item?.cycle?.name}
                </Text>
              </Col>
              <Col className="center">
                {getTagOrBadge(item)}
                <Tooltip title={item?.owner?.name} className="mr-40 center">
                  <AntAvatar
                    imgSrc={item?.owner?.image}
                    userId={item?.owner?.id}
                    firstChar={item?.owner?.name?.charAt(0)}
                  />
                </Tooltip>
                <Progress
                  percent={item?.percentage ?? 0}
                  trailColor="#DFDFDF"
                  className="mr-32 progress_bar_per center"
                />
                {user?.role === USERS_ROLE?.ADMIN ||
                user?.role === USERS_ROLE?.MANAGER ||
                user?.role === USERS_ROLE?.OWNER ||
                (USERS_ROLE?.USER === user?.role &&
                  item?.type === 'INDIVIDUAL') ? (
                  <Dropdown
                    onClick={(event) => {
                      event?.stopPropagation();
                    }}
                    overlay={ObjectiveMenu(
                      `${item?.id}/objective`,
                      item?.created_by,
                      item?.owner?.id
                    )}
                    trigger={['click']}
                    arrow
                  >
                    <img src={MoreIcon} alt="" />
                  </Dropdown>
                ) : (
                  <div className="box-size-24" />
                )}
              </Col>
            </Row>
          }
          key={item?.id}
          className="mb-12 objective_list"
        >
          <div
            key={item?.id}
            direction="vertical"
            size="middle"
            className="tree_sub key-Wrapper"
          >
            <Row className="kr-item">
              <Button type="link" onClick={() => addKeyResult(item?.id)}>
                + Add key result
              </Button>
            </Row>
            {item?.key_results
              ?.slice(0, keyResultVisibleId?.[item?.id])
              ?.map((keyResult, jIndex) => {
                return (
                  <div key={keyResult?.id} className="kr-item">
                    <Row
                      justify="space-between"
                      align="middle"
                      className="w-100"
                    >
                      <Col>
                        <KeyText>{keyResult?.name}</KeyText>
                      </Col>
                      <Col className="center">
                        <Tooltip title={keyResult?.owner?.name}>
                          <AntAvatar
                            size={28}
                            className="mr-32 key_result_avatar"
                            imgSrc={keyResult?.owner?.image}
                            userId={keyResult?.owner?.id}
                            firstChar={keyResult?.owner?.name?.charAt(0)}
                          />
                        </Tooltip>
                        {keyResult?.progress_type !==
                        PROGRESS_TYPE_LIST?.CHECKBOX ? (
                          <>
                            <AntPopOver
                              className="checkin-popover"
                              isVisible={keyResult?.isVisible}
                              onSubmit={() => {
                                setIsPopUpVisible(index, jIndex);
                                getObjectives();
                              }}
                              onClose={() => {
                                setIsPopUpVisible(index, jIndex);
                                getObjectives();
                              }}
                              keyResultRow={keyResult}
                            >
                              <Slider
                                disabled={
                                  user?.id === keyResult?.owner?.id
                                    ? ''
                                    : 'disabled'
                                }
                                min={keyResult?.start ?? 0}
                                max={keyResult?.end ?? 100}
                                trackStyle={{
                                  backgroundColor: renderSwitch(
                                    keyResult?.check_in?.status
                                  )
                                }}
                                value={keyResult?.temp_val}
                                className="slider_progress"
                                onChange={(value) => {
                                  keyResultChange(value, index, jIndex);
                                }}
                              />
                            </AntPopOver>
                            <span className="keyresult_percent">
                              {getKeyResultValue(keyResult)}
                              {keyResult?.custom_unit_name
                                ? ` ${keyResult?.custom_unit_name}`
                                : '%'}
                            </span>
                            <Button
                              disabled={
                                user?.id === keyResult?.owner?.id
                                  ? ''
                                  : 'disabled'
                              }
                              size="default"
                              block={false}
                              width={50}
                              type="default"
                              className="mr-32 checkin_progress"
                              onClick={() => {
                                keyResultChange(
                                  keyResult?.check_in?.current_progress,
                                  index,
                                  jIndex
                                );
                              }}
                            >
                              Check In
                            </Button>
                          </>
                        ) : (
                          <div className="mr-32">
                            <Checkbox
                              onChange={(e) => handleCheck(e, keyResult)}
                              checked={
                                keyResult?.check_in?.current_progress === 100
                              }
                              disabled={
                                keyResult?.check_in?.current_progress === 100
                              }
                            />
                          </div>
                        )}

                        {USERS_ROLE?.OWNER === user?.role ||
                        keyResult?.owner?.id === user?.id ? (
                          <Dropdown
                            overlay={keyResultMenu(
                              `${item.id}/key-result/${keyResult?.id}`,
                              keyResult?.created_by,
                              keyResult?.owner?.id
                            )}
                            trigger={['click']}
                            arrow
                          >
                            <div className="key-result-dropdown">
                              <img src={MoreIcon} alt="" />
                            </div>
                          </Dropdown>
                        ) : (
                          <div className="box-size-24" />
                        )}
                      </Col>
                    </Row>
                  </div>
                );
              })}
            {getButtonOrEmptyString(item)}
          </div>
        </Panel>
      </Collapse>
    );
  };

  const onLoadMore = () => {
    setLoading(true);
    api
      ?.get('objectives', {
        params: {
          search,
          type: objectiveType,
          status,
          cycle_id: objectiveCycle,
          Ismyobjective: isMyObjective,
          page: currentPage + 1
        }
      })
      .then(function (response) {
        setCurrentPage(response?.data?.current_page);
        setLastPage(response?.data?.last_page);
        const newData = objectives?.concat(response?.data?.data);
        setObjectives(newData);
        setLoading(false);
      })
      .catch(function () {});
  };

  const loadMore =
    lastPage > currentPage && !loading ? (
      <div className="load_more_data">
        <Button onClick={onLoadMore}>load more</Button>
      </div>
    ) : null;

  return (
    <Spin direction="horizontal" spinning={loading}>
      <Content padding="24px 16px">
        <ObjectiveModal
          visible={isObjectiveVisible}
          objectiveId={objectiveId}
          isMyObjective={isMyObjective}
          isDeleteObjectiveModal={() => {
            getObjectives();
            setIsObjectiveVisible(false);
          }}
          onSub={() => {
            getObjectives();
            setIsObjectiveVisible(false);
          }}
          onCancel={() => {
            setIsObjectiveVisible(false);
          }}
        />
        <KeyResultModal
          visible={isResultVisible}
          objectiveId={objectiveId}
          ketResultId={keyResultId}
          onSub={() => {
            getObjectives();
            setIsResultVisible(false);
          }}
          onCancel={() => {
            setIsResultVisible(false);
          }}
        />
        <DeleteModal
          visible={isDeleteVisible}
          objectiveId={objectiveId}
          ketResultId={keyResultId}
          isDeleteKeyResult={isDeleteKeyResult}
          onSub={() => {
            setIsDeleteVisible(false);
            handleDelete();
          }}
          onCancel={() => {
            setIsDeleteVisible(false);
            setTimeout(() => {
              setIsDeleteKeyResult(false);
            }, 1000);
          }}
        />
        <ConfirmModal
          visible={isConfirmModal}
          confirmLoading={loading}
          onSub={() => {
            handleFinish();
          }}
          onCancel={() => {
            setIsConfirmModal(false);
          }}
        />
        <Collapse
          className="header-collapse"
          expandIcon={({ isActive }) =>
            isActive ? (
              <UpCircleFilled className="font-size-22" />
            ) : (
              <DownCircleFilled className="font-size-22" />
            )
          }
          defaultActiveKey={['1']}
          ghost
        >
          {isMyObjective === 'true' && objectives?.length > 0 ? (
            <div>{objectives?.map((item, i) => objLists(item, i))}</div>
          ) : (
            Object?.entries(companyObjectives)?.map((values) => {
              return (
                <Panel
                  header={
                    <Row align="middle">
                      <Col>{renderTag({ values })}</Col>
                    </Row>
                  }
                  key={values}
                >
                  {values?.[1]?.map(function (row, index) {
                    return objLists(row, index);
                  })}
                </Panel>
              );
            })
          )}
        </Collapse>
        {loadMore}

        {objectives?.length === 0 &&
          Object?.keys(companyObjectives)?.length === 0 && (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              description={<span>No objectives were found</span>}
            />
          )}
      </Content>
    </Spin>
  );
};

export default Home;
