import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Form, Row } from 'antd';
import React from 'react';
import { CloseIcon } from '../../../assets';
import AntModal from '../../AntModal';

const ConfirmModal = ({ visible, onSub, onCancel, confirmLoading = false }) => {
  const [form] = Form?.useForm();

  const onFinish = () => {
    onSub();
  };

  return (
    <>
      <AntModal
        width={420}
        title={
          <>
            <InfoCircleOutlined />
            <span className="delete-title-text">Confirm Result</span>
          </>
        }
        className="delete-modal"
        visible={visible}
        onOk={form?.submit}
        okText="Yes, Confirm"
        onCancel={onCancel}
        footer="default"
        closeIcon={<img src={CloseIcon} alt="close" />}
        destroyOnClose
        confirmLoading={confirmLoading}
        content={
          <Form
            form={form}
            name="confirm-key-object"
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            requiredMark={false}
            preserve={false}
          >
            <Row>
              <Col span={24}>
                <Form.Item>
                  <div className="delete-modal-text">
                    <>
                      <p>
                        <strong>
                          Are you sure you want to confirm this check-in?
                        </strong>
                      </p>
                      <p>
                        You won't be able to change the progress for this Key
                        Result.
                      </p>
                    </>
                  </div>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        }
      />
    </>
  );
};

export default ConfirmModal;
