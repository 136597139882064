import { Avatar, Col, Form, notification, Popconfirm, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeleteIcon } from '../../assets';
import AccessRole from '../../common/AccessRole';
import { ROUTES, USERS_ROLE } from '../../common/constants';
import { formValidatorRules, HasAccess } from '../../common/utils';
import AntButton from '../../components/AntButton';
import AntInput from '../../components/AntInput';
import DeleteCompanyModal from '../../components/Shared/modals/DeleteCompanyModal';
import { UserDetailsContext } from '../../context/UserDetailsProvider';
import api from '../../services/api';

const { alphabetsNumberSpace } = formValidatorRules;
function CompanySettings({ imageSrc }) {
  const { state, dispatch } = useContext(UserDetailsContext);
  const user = JSON?.parse?.(state?.user);
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const navigate = useNavigate();
  const [company, setCompany] = useState({});

  useEffect(() => {
    api?.get(`companies/${user?.company?.id}`).then((result) => {
      setCompany(result?.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (values) => {
    // eslint-disable-next-line no-undef
    const formData = new FormData();
    if (imageSrc) {
      formData?.append('logo', imageSrc);
    }
    formData?.append('id', user?.company?.id);
    formData?.append('name', values?.name);
    api?.post('companies', formData).then(function (response) {
      notification?.success({
        message: 'Success',
        description: response?.message
      });
      const userData = user;
      userData.company = response?.data;
      dispatch({
        type: 'UPDATE_USER',
        payload: userData
      });
    });
  };

  const confirmDeleteCompany = () => {
    setIsDeleteVisible(true);
  };

  const DeleteCompany = () => {
    api?.delete(`companies/${user?.company?.id}`).then(function (response) {
      notification?.success({
        message: 'Success',
        description: response?.message
      });
      setIsDeleteVisible(false);
      // eslint-disable-next-line no-undef
      localStorage?.clear();
      navigate(ROUTES?.LOGIN);
    });
  };

  const initialValues = { name: user?.company?.name };

  return (
    <Form
      name="basic"
      onFinish={onFinish}
      layout="vertical"
      requiredMark={false}
      initialValues={initialValues}
    >
      <Row align="middle" gutter={32}>
        <Col span={10}>
          <AntInput
            label="Company Name"
            name="name"
            disabled={HasAccess({
              roles: [USERS_ROLE?.ADMIN, USERS_ROLE?.MANAGER, USERS_ROLE?.USER]
            })}
            placeholder="Company Name"
            rules={[
              {
                required: true,
                message: 'Please input your company name.'
              },
              alphabetsNumberSpace
            ]}
          />
        </Col>
        <Col>
          <Form.Item label="Owned By" className="owner-name">
            <Avatar
              width={24}
              className="owner_name_setting"
              src={company?.owner?.image}
            >
              {company?.owner?.name?.charAt(0)}
            </Avatar>
            <span className="tag-text owner-title">{company?.owner?.name}</span>
          </Form.Item>
        </Col>
      </Row>
      <Row align="middle" justify="space-between" className="footer-card">
        <Col>
          <AccessRole allowedRoles={[USERS_ROLE?.OWNER]}>
            <Popconfirm
              title="Are you sure to delete company?"
              onConfirm={confirmDeleteCompany}
              okText="Yes"
              cancelText="No"
            >
              <Row className="delete_company text-warning" align="middle">
                <img src={DeleteIcon} alt="" />
                &nbsp; Delete Company
              </Row>
            </Popconfirm>
            <DeleteCompanyModal
              visible={isDeleteVisible}
              companyName={user?.company?.name}
              onSub={() => {
                setIsDeleteVisible(false);
                DeleteCompany();
              }}
              onCancel={() => {
                setIsDeleteVisible(false);
              }}
            />
          </AccessRole>
        </Col>
        <Col>
          <Row>
            <Col>
              <AntButton
                block={false}
                type="text"
                onClick={() => navigate(ROUTES?.MAIN)}
              >
                Cancel
              </AntButton>
            </Col>
            <AccessRole allowedRoles={[USERS_ROLE?.OWNER]}>
              <Col>
                <AntButton block={false} type="primary" htmlType="submit">
                  Save
                </AntButton>
              </Col>
            </AccessRole>
          </Row>
        </Col>
      </Row>
    </Form>
  );
}

export default CompanySettings;
