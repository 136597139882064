import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { ROUTES } from '../common/constants';
import { UserDetailsContext } from '../context/UserDetailsProvider';

const useAuth = () => {
  const { state } = useContext(UserDetailsContext);
  const user = JSON?.parse(state?.user);

  if (user) {
    return true;
  }
  return false;
};

function ProtectedRoutes({ children }) {
  const auth = useAuth();
  return auth ? children : <Navigate to={ROUTES?.LOGIN} />;
}

export default ProtectedRoutes;
