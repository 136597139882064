import { Card } from 'antd';
import React from 'react';
import styled from 'styled-components';
import Colors from '../design/Colors';

const CustomCard = styled(Card)`
  margin-top: 16px;
  width: ${({ width }) => width}px;
  border: ${({ bordered }) =>
    bordered === false ? 'none' : `1px solid ${Colors?.Stroke}`};
  .ant-card-body {
    padding: ${({ padding }) => padding || 0}px;
  }
  .ant-space-align-center {
    align-items: normal;
  }
  border-radius: 8px;
`;

const AntCard = ({
  children,
  width,
  props,
  bordered,
  padding,
  title,
  className,
  height
}) => {
  return (
    <CustomCard
      className={className}
      width={width}
      bordered={bordered}
      padding={padding}
      title={title}
      height={height}
      {...props}
    >
      {children}
    </CustomCard>
  );
};

export default AntCard;
