import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import './App.less';
import Error404 from './Error404';
import { ROUTES } from './common/constants';
import DashboardMaster from './components/Shared/Header';
import Home from './modules/Dashboard/Home';
import ObjectiveDetails from './modules/Dashboard/ObjectiveDetails';
import Setting from './modules/Settings/Setting';
import CreateCompany from './modules/auth/signup/CreateCompany';
import CreateDepartments from './modules/auth/signup/CreateDepartments';
import InviteMembers from './modules/auth/signup/InviteMembers';
import MasterOnboarding from './modules/auth/signup/MasterOnboarding';

function App() {
  const { pathname } = useLocation();
  if (pathname === `${ROUTES?.ONBOARDING}/${ROUTES?.CREATE_COMPANY}`) {
    // eslint-disable-next-line no-undef
    window?.history?.pushState(null, null, window?.location?.href);
  }

  return (
    <Routes>
      <Route element={<DashboardMaster />}>
        <Route index isSubHeader element={<Home />} />
        <Route
          path={`${ROUTES?.OBJECTIVE_DETAIL}/:id`}
          isSubHeader={false}
          element={<ObjectiveDetails />}
        />
        <Route
          path={ROUTES?.COMPANY_SETTING}
          isSubHeader={false}
          element={<Setting />}
        />
        <Route path="*" element={<Error404 />} />
      </Route>
      <Route path={ROUTES?.ONBOARDING} element={<MasterOnboarding />}>
        <Route path={ROUTES?.CREATE_COMPANY} element={<CreateCompany />} />
        <Route
          path={ROUTES?.CREATE_DEPARTMENT}
          element={<CreateDepartments />}
        />
        <Route path={ROUTES?.INVITE_MEMBERS} element={<InviteMembers />} />
      </Route>
    </Routes>
  );
}

export default App;
