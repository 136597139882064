import { Form, Input } from 'antd';
import React from 'react';
import styled from 'styled-components';

const FormTextArea = styled(Form?.Item)``;

const FormInp = styled(Input?.TextArea)`
  border-radius: 8px;
`;

const AntTextArea = ({ label, placeholder, name, ...rest }) => {
  return (
    <FormTextArea name={name} label={label}>
      <FormInp name={name} {...rest} placeholder={placeholder} />
    </FormTextArea>
  );
};

export default AntTextArea;
