import { Badge, Form, Select } from 'antd';
import React from 'react';
import styled from 'styled-components';

const CustomSelect = styled(Select)`
  margin-bottom: 1px;
  .ant-select-selector {
    border-radius: 8px;
  }
  width: '100%';
`;

const { Option } = Select;

const AntSelect = ({
  label,
  name,
  options,
  placeholder,
  rules,
  className,
  tooltip,
  extra,
  dropdownRender,
  loading,
  disabled,
  ...rest
}) => {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={rules}
      className={className}
      tooltip={tooltip}
      extra={extra}
    >
      <CustomSelect
        size="large"
        name={name}
        placeholder={placeholder}
        loading={loading}
        disabled={disabled}
        dropdownRender={dropdownRender}
        {...rest}
      >
        {Array?.isArray(options)
          ? options?.map((option) => {
              return (
                <Option key={option?.name} value={option?.value}>
                  <Badge status={option?.icon} /> {option?.name}
                </Option>
              );
            })
          : null}
      </CustomSelect>
    </Form.Item>
  );
};

export default AntSelect;
