import { Col, Form, notification, Row, Typography } from 'antd';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BackgroundPattern, Logo } from '../../../assets';
import { ROUTES } from '../../../common/constants';
import AntButton from '../../../components/AntButton';
import AntCard from '../../../components/AntCard';
import AntInput from '../../../components/AntInput';
import Strings from '../../../design/Strings';
import api from '../../../services/api';
import { Content, SubText } from '../AntStyled';

const ForgotPassword = () => {
  const [form] = Form?.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = (values) => {
    setLoading(true);
    api
      ?.post('forgot-password', values)
      .then(function (response) {
        notification?.success({
          message: 'Success',
          description: response?.message
        });
        form?.resetFields();
        navigate(ROUTES?.EMAIL_SENT, {
          state: {
            email: values?.email
          }
        });
        setLoading(false);
      })
      .catch(function () {
        setLoading(false);
      });
  };

  return (
    <Content className="sign-up-steps">
      <div className="logo-wrapper">
        <Link to={ROUTES?.MAIN}>
          <img className="logo" src={Logo} alt="" />
        </Link>
      </div>
      <Row>
        <Col flex="auto" className="form-col">
          <Content>
            <div className="wrapper">
              <Typography.Title className="title heading mb-0">
                {Strings?.auth?.forgotPassword}
              </Typography.Title>
              <SubText className="sub-title">
                {Strings?.auth?.PasswordDescription}
              </SubText>
              <AntCard bordered padding="16px">
                <Form
                  form={form}
                  name="basic"
                  onFinish={onFinish}
                  autoComplete="off"
                  layout="vertical"
                  requiredMark={false}
                >
                  <AntInput
                    label="Email"
                    name="email"
                    placeholder="user@example.com"
                    rules={[
                      {
                        required: true,
                        type: 'email',
                        message: 'Please input your email.'
                      }
                    ]}
                  />

                  <AntButton
                    type="primary"
                    htmlType="submit"
                    block
                    size="large"
                    loading={loading}
                  >
                    Send Link
                  </AntButton>
                </Form>
              </AntCard>
            </div>
          </Content>
          <img className="background-pattern" src={BackgroundPattern} alt="" />
        </Col>
      </Row>
    </Content>
  );
};

export default ForgotPassword;
