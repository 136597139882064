import { Layout, Typography } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';
import Colors from '../../design/Colors';

const { Content: AntContent } = Layout;
const { Text } = Typography;
export const EditIcon = styled.div`
  position: absolute;
  height: 24px;
  width: 24px;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border-radius: 8px;
  border: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  &:hover {
    border-color: white;
  }
  .edit-icon {
    width: 24px;
  }
`;

export const Content = styled(AntContent)`
  .img-col {
    background-color: ${Colors?.BackgroundLight};
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 492px;
    @media (max-width: 844px) {
      display: none;
    }
  }
  .sidebar-img {
    max-width: 100%;
  }
  .logo {
    position: absolute;
    max-width: 190px;
    z-index: 1000;
    margin: 24px;
  }
  .background-pattern {
    position: fixed;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 0;
    z-index: -1;
  }
  .logo-wrapper {
    @media (max-width: 844px) {
      background-color: ${Colors?.BackgroundLight};
      height: 76px;
    }
  }
  .form-col {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: flex-start;
    height: 100vh;
    overflow: auto;
    padding: 16px;
    @media (max-width: 844px) {
      height: Calc(100vh - 76px);
    }
  }
  .form-col-scroll {
    display: grid;
    justify-content: center;
    height: 100vh;
    overflow: auto;
    padding: 16px;
    @media (max-width: 844px) {
      height: Calc(100vh - 76px);
    }
  }
  .wrapper {
    min-width: 348px;
    max-width: 350px;
    margin: auto;
    @media (max-width: 377px) {
      min-width: unset;
    }
    .ant-space {
      display: flex;
    }
  }
  .wrapper-big {
    min-width: 552px;
    @media (max-width: 600px) {
      min-width: unset;
    }
    .ant-space {
      display: flex;
    }
  }
  .title {
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
  }

  .forgot-password {
    align-self: right;
    display: flex;
    justify-content: right;
    margin-top: 8px;
    margin-bottom: 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: ${Colors?.Text};
  }
  .ant-divider-horizontal.ant-divider-with-text {
    border-top-color: ${Colors?.Stroke};
    margin: 12px 0;
  }
  .icon-btn.ant-btn {
    color: ${Colors?.Text};
  }
  .text-btn.ant-btn {
    color: ${Colors?.Primary};
  }
  .company-name {
    font-size: 16px;
    margin-left: 12px;
    color: ${Colors?.Text};
  }
  .signup-desc {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${Colors?.Text};
  }
  .text {
    color: ${Colors?.Text};
  }
  .space {
    height: 200px;
  }
  .company-logo {
    position: relative;
    :hover {
      ${EditIcon} {
        display: block;
      }
    }
    img {
      object-fit: cover;
    }
  }
  .enter-text {
    margin-top: -12px;
    color: ${Colors?.Text};
    font-size: 12px;
    line-height: 18px;
  }
`;
export const BodyStyles = createGlobalStyle`
 body {
	overflow: hidden;
 }
`;
export const SubText = styled(Text)`
  white-space: pre-line;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${Colors?.Text};
  max-width: 348px;
  display: block;
  .sub-text {
    color: ${Colors?.Accent};
  }
`;
