import { Form, notification, Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { CloseIcon } from '../../../assets';
import { formValidatorRules } from '../../../common/utils';
import { UserDetailsContext } from '../../../context/UserDetailsProvider';
import api from '../../../services/api';
import AntAvatar from '../../AntAvatar';
import AntInput from '../../AntInput';
import AntModal from '../../AntModal';

const { onlyAlphabets } = formValidatorRules;
const AddDepartment = ({ visible, onCancel, departmentId, onSubmit }) => {
  const [form] = Form?.useForm();
  const [users, setUsers] = useState([]);
  const { state } = useContext(UserDetailsContext);
  const user = JSON?.parse(state?.user);

  const onFinish = (values) => {
    const names = [];
    names[0] = values?.name;
    const members = values?.owner_id;
    if (!departmentId) {
      api?.post('departments', { names, members }).then(function (response) {
        notification?.success({
          message: 'Success',
          description: response?.message
        });
        onSubmit(names?.[0]);
      });
    } else {
      api
        ?.put(`departments/${departmentId}`, { name: values?.name })
        .then(function (response) {
          notification?.success({
            message: 'Success',
            description: response?.message
          });
          onSubmit(names?.[0]);
        });
    }
  };

  useEffect(() => {
    if (!departmentId) {
      form?.setFieldsValue({ name: '' });
      return;
    }
    api?.get(`departments/${departmentId}`).then((response) => {
      form?.setFieldsValue({
        name: response?.data?.name
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentId]);

  useEffect(() => {
    /* Get all users */
    api?.get('users').then(function (response) {
      const getUsers = [];
      response?.data?.data?.forEach((r) => {
        getUsers?.push({
          name: r?.name,
          value: r?.id,
          email: r?.email,
          invited_name: r?.invited_by?.email,
          is_invited: r?.is_invited,
          image: r?.image
        });
      });
      setUsers(getUsers);
    });
  }, []);

  return (
    <>
      <AntModal
        title="Create Department"
        visible={visible}
        onOk={form?.submit}
        okText="Create"
        onCancel={onCancel}
        destroyOnClose
        footer="default"
        closeIcon={<img src={CloseIcon} alt="close" />}
        content={
          <Form
            form={form}
            name="department_form"
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            requiredMark={false}
            preserve={false}
          >
            <AntInput
              label="Department name"
              name="name"
              placeholder="Enter Department Name"
              maxLength="50"
              rules={[
                {
                  required: true,
                  message: 'Please enter department!'
                },
                onlyAlphabets
              ]}
            />
            <Form.Item
              label="Add members"
              name="owner_id"
              className="department-member"
            >
              <Select
                mode="multiple"
                size="large"
                placeholder="Add members"
                optionLabelProp="label"
              >
                {users?.map((option) => {
                  return (
                    <Select.Option
                      disabled={user?.id === option?.value}
                      key={option?.name}
                      value={option?.value}
                      label={option?.name}
                    >
                      <div className="department-member-add">
                        <AntAvatar
                          imgSrc={option?.image}
                          userId={option?.value}
                          firstChar={
                            option?.name
                              ? option?.name?.charAt(0)
                              : option?.email?.charAt(0)
                          }
                        />

                        <div className="ant-list-item-meta-content">
                          <h4 className="ant-list-item-meta-title">
                            {option?.is_invited === 1 ? (
                              <div className="invited_label">Invited</div>
                            ) : (
                              option?.name
                            )}
                          </h4>
                          <div className="ant-list-item-meta-description">
                            {option?.email}
                          </div>
                        </div>
                      </div>
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Form>
        }
      />
    </>
  );
};

export default AddDepartment;
