import { OAuthProvider } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_GOOGLE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_GOOGLE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_GOOGLE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_GOOGLE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_GOOGLE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_GOOGLE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_GOOGLE_MEASUREMENT_ID
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();

export const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });

export const microSoft = new OAuthProvider('microsoft.com');
microSoft.setCustomParameters({
  prompt: 'consent',
  tenant: 'f8cdef31-a31e-4b4a-93e4-5f571e91255a'
});
